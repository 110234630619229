import { IListItem } from '@/types';
import { TFunction } from 'i18next';

export enum ActionQuotaMenuEnum {
  edit = 'edit',
  delete = 'delete'
}

const isDefaultQuota = (quotaId: number) => quotaId === 1;

export const actionMenu = (quotaId: number, t: TFunction): IListItem[] => {
  const list: IListItem[] = [
    {
      label: t('filials.diskSpace.edit'),
      value: ActionQuotaMenuEnum.edit
    }
  ];

  if (!isDefaultQuota(quotaId)) {
    list.push({
      label: t('filials.diskSpace.delete'),
      value: ActionQuotaMenuEnum.delete,
      color: 'brandRed.0'
    });
  }

  return list;
};
