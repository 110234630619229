import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import {
  IAddTableQuota,
  IEditTableQuota,
  IFilialQuota,
  IQuota,
  IQuotaQuery,
  IQuotasData,
  ISetQuotaByRolePayload,
  ITableQuotasData
} from './types';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { IFullResponse } from '@/types/api';

type IQuotaProvideType = 'Quotas' | 'FilialQuota';
const tagTypes: IQuotaProvideType[] = ['Quotas', 'FilialQuota'];
export const quotasApi = createApi({
  reducerPath: 'api/quotas',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getQuotas: build.query<IQuotasData, IQuotaQuery>({
      query: (payload) => ({
        url: '/v2/userquotas/list',
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<ITableQuotasData>) => {
        const { Data } = response.Response;
        const defaultItem = Data.Items.find((quota) => quota?.IsDefault);
        return {
          TotalCount: Data.TotalCount,
          DefaultItemId: defaultItem?.Id,
          Quotas: Data.Items.map(
            (quota): IQuota => ({
              id: quota.Id,
              name: quota.Name,
              size: quota.Size,
              users: quota.UserCount
            })
          )
        };
      },
      providesTags: ['Quotas']
    }),
    getFilialQuota: build.query<IFilialQuota, string | number>({
      query: (id) => ({
        params: {
          id
        },
        url: 'v2/customer/quota',
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<IFilialQuota>) => ({
        ...response.Response.Data
      }),
      providesTags: ['FilialQuota']
    }),
    updateFilialQuota: build.mutation<IFilialQuota, Omit<IFilialQuota, 'UsedSize'>>({
      query: (payload) => ({
        body: payload,
        url: 'v2/customer/quota',
        method: 'POST'
      }),
      transformResponse: (response: IFullResponse<IFilialQuota>) => ({
        ...response.Response.Data
      }),
      invalidatesTags: ['Quotas', 'FilialQuota']
    }),
    createQuota: build.mutation<IQuota, IAddTableQuota>({
      query: (payload) => ({
        url: '/v2/userquotas',
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IEditTableQuota>) => {
        const { Data } = response.Response;
        return {
          id: Data.Id,
          name: Data.Name,
          size: Data.Size,
          users: null
        };
      },
      invalidatesTags: ['Quotas']
    }),
    updateQuota: build.mutation<IQuota, IEditTableQuota>({
      query: (payload) => ({
        url: '/v2/userquotas',
        body: payload,
        method: 'put'
      }),
      transformResponse: (response: IFullResponse<IEditTableQuota>) => {
        const { Data } = response.Response;
        return {
          id: Data.Id,
          name: Data.Name,
          size: Data.Size,
          users: null
        };
      },
      invalidatesTags: ['Quotas']
    }),
    setQuotaByRole: build.mutation<IQuota, ISetQuotaByRolePayload>({
      query: (payload) => ({
        url: '/v2/userquotas/setbyrole',
        body: payload,
        method: 'post'
      }),
      invalidatesTags: ['Quotas']
    }),
    deleteQuota: build.mutation<IQuota, number>({
      query: (quotaId) => ({
        url: `/v2/userquotas?id=${quotaId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Quotas']
    })
  })
});

export const {
  useGetQuotasQuery,
  useLazyGetFilialQuotaQuery,
  useGetFilialQuotaQuery,
  useUpdateFilialQuotaMutation,
  useCreateQuotaMutation,
  useUpdateQuotaMutation,
  useDeleteQuotaMutation,
  useSetQuotaByRoleMutation
} = quotasApi;
