import { FC } from 'react';
import { Avatar, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { RolesPlaceholder } from '@assets/icons';
import OverflowTip from '@components/OverflowTip';
interface IItemTitleProps {
  item: IRowData;
}

export const ItemTitle: FC<IItemTitleProps> = ({ item }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.title}>
      <Avatar sx={{ zIndex: 1 }} color="blue" size={40} radius={40}>
        <RolesPlaceholder size={24} />
      </Avatar>
      <Box className={classes.content}>
        <Box maw="200px">
          <OverflowTip size="sm" fw={600} color="#101828">
            {item?.Name}
          </OverflowTip>
        </Box>
        <Text color="dimmed" size="xs">
          ID: {item?.Id}
        </Text>
      </Box>
    </Box>
  );
};
