import { Box, Burger, LoadingOverlay } from '@mantine/core';
import { ErrorBoundary } from '@components/error-boundary';
import { SimpleNavbar } from '@components/simple-navbar';
import { useStyles } from './styles';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/redux/redux';
import { Outlet } from 'react-router-dom';
import { isSuperAdmin } from '@/lib/utils/access';
import { NavMenu } from './components/nav-menu';
import { NotFound } from '@/components/not-found';
import { Layout } from '@/containers/layout';
import { useCommonSettingsQuery } from '@/entities/admin-app/commonSettings/api';
import { useActions } from '@hooks/redux/action';
const CommonSettingsPage: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { Settings, User } = useAppSelector((state) => state.userInfo);
  const isLimitedAccess = !isSuperAdmin(User);
  const { data, isLoading } = useCommonSettingsQuery();
  const { setCommonSettings } = useActions();

  useEffect(() => {
    if (data) {
      setCommonSettings(data);
    }
  }, [data]);

  return (
    <ErrorBoundary componentTitle="Settings page">
      {isLimitedAccess ? (
        <NotFound />
      ) : (
        <Layout
          leftNavbarContent={
            <SimpleNavbar
              widthNav={290}
              style={{ flexShrink: 0, borderRight: 'none' }}
              className={classes.simpleNavbar}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            >
              <NavMenu Settings={Settings} setIsOpen={setIsOpen} />
            </SimpleNavbar>
          }
        >
          <Box className={classes.container} ref={containerRef}>
            <Box className={classes.headerMenu}>
              <Burger
                opened={isOpen}
                onClick={() => setIsOpen(true)}
                className={classes.headerMenuBurger}
                size="sm"
              />
            </Box>
            <Outlet />
          </Box>

          <Box className={classes.section}>{children}</Box>
          <LoadingOverlay visible={isLoading} overlayProps={{ blur: 4 }} />
        </Layout>
      )}
    </ErrorBoundary>
  );
};

export default CommonSettingsPage;
