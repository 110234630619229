export enum DriveInfoPanelsEnum {
  quotas = 'quotas',
  filialQuota = 'filialQuota',
  externalLinks = 'externalLinks'
}

export enum EmailInfoPanelsEnum {
  byRoles = 'byRoles',
  byUsers = 'byUsers'
}

export enum CalendarInfoPanelsEnum {
  byRoles = 'byRoles',
  byUsers = 'byUsers'
}

export enum DrawInfoPanelsEnum {
  byRoles = 'byRoles',
  byUsers = 'byUsers'
}
export enum ContactsInfoPanelsEnum {
  byRoles = 'byRoles',
  byUsers = 'byUsers'
}
