import { Stack } from '@mantine/core';
import { Modal } from '@/components/modal';
import { CardContainer } from '../../cardContainer';
import { IconBuildingSkyscraper } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useGetFilialsQuery } from '@/entities/admin-app/filials/api';
import { CreateFilialForm } from '@/containers/pages/filials/components/create-item/createForm';

interface IFilialSectionProps {
  filialId?: number;
}

export const FilialSection: FC<IFilialSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: filialsData } = useGetFilialsQuery({
    Count: 10,
    Offset: 0,
    Query: '',
    SkipCustomers: []
  });

  const handleCreateFilial = () => {
    setIsModalOpen(true);
  };

  const filialMenu: IListItemProps = {
    withCheck: false,
    items: [
      { name: t('filials.list'), link: ROUTES.filials.fullPath },
      {
        name: t('roles.addFilial'),
        openModal: handleCreateFilial
      },
      {
        name: t('filials.configureLdap'),
        link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.auth.ldap}`
      }
    ]
  };

  return (
    <>
      <CardContainer
        sectionName={t('navigation.units')}
        counter={filialsData && filialsData.TotalCount}
        Icon={IconBuildingSkyscraper}
      >
        <Stack gap="7px" sx={{ width: '100%' }}>
          <ListItem items={filialMenu.items} withCheck={filialMenu.withCheck} />
        </Stack>
      </CardContainer>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('filials.create')}
        zIndex={1002}
      >
        <CreateFilialForm onClose={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};
