import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  root: {
    padding: '5rem 0'
  },

  inner: {
    position: 'relative'
  },

  label: {
    position: 'absolute',
    inset: 0,
    opacity: '0.65',
    color: '#e9ecef',
    textAlign: 'center',
    fontSize: '40vw',
    fontWeight: 900,
    lineHeight: 1
  },

  content: {
    paddingTop: '10rem',
    position: 'relative',
    zIndex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      paddingTop: '120px'
    }
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    lineHeight: 1.2
  }
}));
