import { FC } from 'react';
import { showNotification } from '@mantine/notifications';
import { Dropzone } from '@mantine/dropzone';
import { Box, Flex, rem, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { IconFolderUp } from '@tabler/icons-react';

interface ImageEditorProps {
  setLogoFile: (value: string) => void;
  allowedMimeType?: string[]; // Optional: Array of allowed file types (e.g., ['image/png', 'image/jpeg'])
  maxDimensions: { width: number; height: number }; // Optional: Maximum allowed dimensions
}

export const ImageUploader: FC<ImageEditorProps> = ({
  setLogoFile,
  allowedMimeType = ['image/png'],
  maxDimensions
}) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const onBeforeFileLoad = (files: any) => {
    if (files[0].file.size > 5242880) {
      showNotification({
        title: t('avatar.warning'),
        message: '',
        autoClose: true,
        color: 'red'
      });
      return false; // Prevent drag&drop from happening
    }
  };

  const onDrop = async (files: any) => {
    if (files.length > 0) {
      const file = files[0];
      const fileType = file?.type;
      if (!allowedMimeType.includes(fileType)) {
        showNotification({
          title: t('commonSettings.incorrectImageExtension'),
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        // Validate image dimensions
        const img = new Image();
        img.onload = () => {
          if (
            fileType === 'image/png' &&
            (img.width !== maxDimensions.width || img.height !== maxDimensions.height)
          ) {
            showNotification({
              title: t('commonSettings.incorrectImageDimensions', {
                width: maxDimensions.width,
                height: maxDimensions.height
              }),
              message: '',
              autoClose: true,
              color: 'red'
            });
            return;
          }

          const reader = new FileReader();
          reader.onload = (e) => {
            setLogoFile(e?.target?.result as string);
          };
          reader.readAsDataURL(file);
        };
        img.src = URL.createObjectURL(file); // Read image dimensions asynchronously
      }
    }
  };

  return (
    <Box>
      <Dropzone
        className={classes.dropZone}
        onDrop={onDrop}
        multiple={false}
        // openRef={openRef}
        onReject={onBeforeFileLoad}
        maxSize={5 * 1024 ** 2}
        accept={allowedMimeType}
      >
        <Flex gap="md" justify="center" align="center" direction="column" h={rem(200)} w="300px">
          <Dropzone.Accept>
            <IconFolderUp size="4rem" stroke={1.5} color={theme.colors[theme.primaryColor][6]} />
          </Dropzone.Accept>
          <Dropzone.Idle>
            <IconFolderUp size="4rem" stroke={1.5} />
          </Dropzone.Idle>
          <Text size="m" inline>
            {t('commonSettings.choosePhoto')}
          </Text>
        </Flex>
      </Dropzone>
    </Box>
  );
};
