import { Switch, SwitchProps } from '@mantine/core';
import { forwardRef } from 'react';
import { useStyles } from './styles';

export const Toggle = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const { classes } = useStyles();

  return (
    <Switch
      {...props}
      size="xs"
      fz="xs"
      classNames={{
        body: classes.body,
        track: classes.track,
        thumb: classes.thumb,
        trackLabel: classes.trackLabel,
        label: classes.label,
        ...props.classNames
      }}
      ref={ref}
    />
  );
});

Toggle.displayName = 'Toggle';
