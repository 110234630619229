import React, { FC } from 'react';
import { ActionIcon, Flex } from '@mantine/core';

import { IconX } from '@tabler/icons';
import { themeColors } from '@/theme/colors';

import { useStyles } from './styles';
import OverflowTip from '@/components/OverflowTip';

interface IBadges {
  label: string;
  onRemove: () => void;
}

export const Badges: FC<IBadges> = ({ label, onRemove }) => {
  const { classes } = useStyles();

  return (
    <Flex className={classes.badge}>
      <OverflowTip>{label}</OverflowTip>
      <ActionIcon ml={5} size={16} variant="transparent" onClick={onRemove}>
        <IconX size={16} color={themeColors.brandGray[0]} />
      </ActionIcon>
    </Flex>
  );
};
