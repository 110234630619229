import { FC } from 'react';
import { ProfileHeader } from '../../sections/profile-header';
import { GeneralInformation } from '../../sections/general-information';
import UserCredentials from '../../sections/user-credentials';

const PersonalData: FC = () => {
  return (
    <>
      <ProfileHeader />
      <UserCredentials />
      <GeneralInformation />
    </>
  );
};

export default PersonalData;
