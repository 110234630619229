import { IApiTimeZones, TimeZoneItem } from '@/entities/admin-app/user';

export const transformTimeZones = (data?: IApiTimeZones[]) => {
  let timeZones: TimeZoneItem[] = [];

  data?.forEach((timeZone) => {
    timeZones = [
      ...timeZones,
      {
        value: String(timeZone?.Id),
        // label: `${timeZone.Name} ${timeZone.UtcOffset}`,
        label: `(UTC ${timeZone.UtcOffset}) ${timeZone.Name} `,
        utc: timeZone.UtcOffset
        // group: timeZone.UtcOffset
      }
    ];
  });

  return timeZones;
};
