import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';
import { Box, Button, Group, Text } from '@mantine/core';
import iconError from '@assets/icon-mail-link-error.svg';

export const InvalidLinkContent = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Box className={classes.responseContent}>
      <img src={iconError} alt="icon" className={classes.icon} />
      <Text className={classes.desc} ta="center">
        {t('passwordReset.linkIsInvalid')}
      </Text>
      <Group justify="center" gap="xs" w="100%">
        <Button className={classes.button} size="lg" component="a" href="/">
          {t('backMain')}
        </Button>
      </Group>
    </Box>
  );
};
