import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  boxContainer: {
    borderRadius: '8px',
    padding: '16px 16px 16px 24px',
    marginBottom: '16px'
  },

  divider: {
    color: '#A2B0C359',
    margin: '0 16px'
  },

  dateBox: {
    display: 'inline-block',
    lineHeight: '40px'
  }
}));
