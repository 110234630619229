import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((_, __, utils) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [utils.largerThan('xs')]: {
      flexDirection: 'row'
    }
  },

  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50%'
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '50%'
  }
}));
