import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.backgroundGray[0],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@supports(-webkit-touch-callout: none)': {
      minHeight: '-webkit-fill-available'
    }
  },
  responseContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    marginTop: '15px',
    width: '100px',
    height: '100px'
  },
  desc: {
    lineHeight: '22px',
    color: theme.colors.gray[6],
    margin: '24px 0 32px'
  },
  form: {
    position: 'relative',
    maxWidth: 450,
    width: '100%',
    padding: 40,
    borderRadius: theme.spacing.xs,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%'
    }
  },

  title: {
    [utils.dark]: {
      color: theme.white
    },
    [utils.light]: {
      color: theme.black
    },
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  },

  logo: {
    [utils.dark]: {
      color: theme.white
    },
    [utils.light]: {
      color: theme.black
    },
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  input: {
    // width: `calc(100% - 36px)`,

    '& .mantine-PasswordInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  },
  button: {
    width: '100%'
  },
  controls: {
    '& .mantine-InputWrapper-error': {
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '14px',
      position: 'relative'
    }
  }
}));
