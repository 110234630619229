import { IAddress } from '../directories/types';
import { ILinkedEmail } from '@/entities/admin-app/email';

export interface IQueryUserUpdate {
  Name: string;
  FirstName: string;
  Surname: string;
  MiddleName: string;
  City: string;
  Email: string;
  BirthDate: Date | string;
  Department: string;
  PhoneNumber: string;
  Position: string;
  Roles: string[] | number[];
}

export interface IQueryUserChangeStatuses {
  Ids: number[];
  status: number;
}

export interface IQueryUserChangeRoles {
  UserIds: number[];
  RoleIds: number[];
}

export interface IPosition {
  Id: number;
  Position: string;
  ParentUserId: number;
  ParentUserName: string;
}

interface IRole {
  IsRemovable: any;
  Name: string;
  Id: number;
  Count?: number;
  Type?: number;
}

export enum IUserTypeEnum {
  Contact = 0,
  LDAP = 20,
  SSO = 21
}

export interface IGeneralInformation {
  Avatar: string | null;
  FirstName: string | null;
  Surname: string | null;
  MiddleName: string | null;
  Email: string | null;
  Login: string | null;
  MobilePhone: string | null;
  Status: number | null;
  Positions: IPosition[] | null;
  LdapIdentifier: string | null;
  Roles: IRole[] | null;
  Customer?: { Id?: number | null; Name?: string; Count?: number };
  UserKind: number | null;
  InternetAddress: string | null;
  UserType: IUserTypeEnum;
  ResetPasswordUrl?: string;
  IsConfirmed?: boolean;
}

export interface IAdditionalInformation {
  FullAddress: IAddress | null;
  OfficeAddress?: IAddress | null;
  PhoneNumber: string | null;
  BirthDate: string | null;
  Gender: number;
  PostIndex: string | null;
  Country: string | null;
  DateOfCreate: string | Date | null;
  LastVisit: string | Date | null;
  InternetAddress: string | null;
}

export interface ICompany {
  Name: string;
  DepartmentId: number;
  Position: string;
  OfficeAddress: IAddress;
  Supervisor: ISupervisor;
}

export interface INote {
  Note: string;
}

export interface ISubscriptions {
  OwnerOrAdminPortalNotification: boolean;
  AccessDocumentNotification: boolean;
  AccessFolderNotification: boolean;
  DocumentMensionNotification: boolean;
}

export interface IGroup {
  Id: number;
  Name: string;
}

export interface IActiveConnection {
  SystemName: string;
  Ip: string;
  Date: string;
  Id: number;
  IsOwner: boolean;
}

export interface IDataStorageQuota {
  QuotaName: string;
  FillProcentage: number;
  QuotaSize: number;
  UsingQuotaSize: number;
  Id: number;
}
export type TimeZoneItem = {
  value: string;
  label: string;
  utc: string;
};

export interface IApiTimeZones {
  Id: string;
  Name: string;
  UtcOffset: string;
  TzIdName?: string;
  UtcOffsetMinutes?: number;
}

export interface ISettingsTimeZones {
  Id: string | number | null;
  SecondEnabled: boolean;
  SecondId: number | null;
}
export interface ISettingsLanguage {
  Id: string | number | null;
  Name: string;
}

export interface ILanguageSettings {
  Language: string;
}

export interface IUserInfoCommon {
  UserId: number;
  Password: string | null;
  AvatarId: number | null;
  LinkedEmails: ILinkedEmail[];
  Status: number | null;
  GeneralInformation: IGeneralInformation;
  AdditionalInformation: IAdditionalInformation;
  Note?: INote;
  Subscriptions?: ISubscriptions;
  Groups?: IGroup[];
  ActiveConnections: IActiveConnection[];
  DataStorageQuota: IDataStorageQuota;
  Company?: ICompany;
}

export interface IQueryUserChangePassword {
  NewPassword: string;
  ConfirmPassword: string;
  UserId: number;
}

export interface IQueryUserResetPassword {
  Email: string;
  AdditionalEmail?: string;
}

export interface ISupervisor {
  Id: number;
  Name: string;
}

export interface ICloseseSsionsPayload {
  Ids?: number[];
  UserId?: number | null;
}

export interface IUserDeleteRole {
  UserId: number;
  RoleId: number;
}

export interface IUserInfo {
  Avatar: string | null;
  AvatarId: number | null;
  Id: number;
  Status: number | null;
  Credentials: Partial<IUserCredentials>;
  Common: IUserCommon;
  Customer?: IUserCustomer;
  Positions: IUserPosition[] | null;
  Roles: IRole[] | null;
  LdapIdentifier: string | null;
  Connections: IActiveConnection[];
  LinkedEmail: ILinkedEmail[];
  Quota: IUserQuota | null;
  StorageData: IUserStorage;
  Note?: INote;
  Subscriptions?: ISubscriptions;
  Groups?: IGroup[];
  isSaas?: boolean;
  TimeZone: ISettingsTimeZones | null;
  // Language: ISettingsLanguage | null;
  Language: string | null;
}

export interface IUserStorage {
  Percentage: number;
  QuotaSize: number;
  UsingQuotaSize: number;
}

export interface IUserCredentials {
  Login: string | null;
  Email: string | null;
  PasswordResetUrl?: string;
  IsConfirmed?: boolean;
  isSaas?: boolean;
  AdditionalEmail?: string | null;
}

export interface ILoginCredentials {
  Login: string | null;
  PasswordResetUrl?: string;
}

export interface IEmailCredentials {
  isSaaS?: boolean;
  Email: string | null;
  IsConfirmed?: boolean;
}
export interface IAdditionalEmailCredentials {
  AdditionalEmail?: string | null;
  Email?: string | null;
}

export interface IUserCommon {
  FirstName: string | null;
  SurName: string | null;
  MiddleName: string | null;
  Gender: number;
  BirthDate: string | null;
  Address: string | null;
  Phone: string | null;
  AdditionalPhone: string | null;
  LastEntryDate: string | Date | null;
  DateOfCreate: string | Date | null;
}

export interface IUserQuota {
  Name: string;
  Id: number | null;
  Count?: number | null;
}

export interface IUserCustomer {
  Name?: string | null;
  Id: number | null;
  Count?: number | null;
}

export interface IUserPosition {
  Position: {
    Id: number;
    Name: string;
    Count: number;
  };
  Director: {
    Id: number;
    Name: string;
    Count: number;
  } | null;
}

export interface UpdateInfoData {
  Type: number;
  Data: Partial<IUserInfo> & { Id: number };
}

export interface TariffDiskCounter {
  CurrentAmount: number;
  PossibleAmount: number;
}

export interface TariffData {
  Discount: number;
  DiskSpace: number;
  DiskSpacePrice: number;
  Name: string;
  Period: number;
  Timestamp: string;
  Type: number;
}

export interface TariffUserData {
  CurrentAmount: number;
  PossibleAmount: number;
}

export interface UserTariff {
  Date: string;
  DiskCounter: TariffDiskCounter;
  Name: string;
  Status: number;
  Tariff: TariffData;
  UserCounter: TariffUserData;
}
