import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { universalNotification } from '@/lib/utils/notification';
import { useChangeStatusesMutation } from '@/entities/admin-app/user/api';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { UserStatusEnum } from '@/types/enums/user';
import { useAppSelector } from '../redux/redux';
import { IRowData } from '@/components/simple-table/types';
import { useActions } from '../redux/action';

export const useChangeStatuses = (
  setRowData: Dispatch<SetStateAction<IRowData[]>>,
  editUser?: boolean
) => {
  const [num, setNum] = useState(0);
  const [fetchChangeStatus, resultChangeStatuses] = useChangeStatusesMutation();
  const { filters } = useAppSelector((state) => state.users);
  const [fetchGetUsers] = useLazyGetUsersQuery();
  const { setUsers } = useActions();

  const changeStatuses = useCallback(
    async (selection: number[], userStatus: UserStatusEnum) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];
      setNum(numbersSelection.length);
      try {
        const res = await fetchChangeStatus({
          Ids: numbersSelection,
          status: userStatus
        });
        if (!Object.keys(res).includes('error')) {
          const { data } = await fetchGetUsers({
            Count: filters.Offset >= 20 ? filters.Offset + 20 : 20,
            Offset: 0,
            SortOrder: filters.SortOrder,
            SortField: filters.SortField,
            Filters: filters.Filters,
            Query: filters.Query
          });

          if (setRowData && data) {
            setUsers(data);
            setRowData(data.Users);
          }
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [filters]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeStatuses;
    const message =
      editUser || num <= 1
        ? 'Статус был успешно обновлен'
        : 'Статусы пользователей успешно обновлены';

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: error,
      successTitle: message
    });
  }, [resultChangeStatuses]);

  return { changeStatuses };
};
