import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme, _, utils) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  root: {
    '& .mantine-Drawer-body': {
      padding: '0',
      height: `calc(100vh - 85px)`,
      paddingBottom: 'env(safe-area-inset-bottom)',
      '@supports(-webkit-touch-callout: none)': {
        height: '-webkit-fit-content',
        paddingBottom: 'env(safe-area-inset-bottom)'
      }
    },
    '& .mantine-Drawer-header': {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '125%',
      color: '#101828',
      backgroundColor: '#fff',
      borderBottom: `1px solid ${theme.colors.backgroundGray[3]}`
    }
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.colors.backgroundGray[0],
    height: '63px',
    padding: `0px ${theme.spacing.md}`,
    borderTop: `1px solid ${theme.colors.backgroundGray[3]}`,
    position: 'absolute',
    bottom: '0'
  },

  createButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: theme.spacing.xs,
    span: {
      height: 'auto',
      marginRight: '8px',
      div: {
        margin: '0'
      }
    },
    [utils.smallerThan('lg')]: {
      padding: '15px',
      span: {
        margin: '0'
      }
    }
  },

  createButtonText: {
    marginLeft: '16px',
    [utils.smallerThan('lg')]: {
      display: 'none'
    }
  },
  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },
  addButton: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,

    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  }
}));
