import React, { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';

import { CalendarIcon } from '@assets/icons';
import { FilterTemplate } from '@components/filters/filter-template';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export interface IDateRangeFilter {
  activeValue?: [Date, Date];
  type: IFiltersEnumType;
  handleFilterReset: (value: any) => void;
  locale?: string;
  handleChange: (value: { Type: number; value: Date[] }) => void;
  hideCollapse?: boolean;
  maxDate?: Date;
}
export const DateRangeFilter = ({
  type,
  locale = 'ru',
  handleChange,
  handleFilterReset,
  activeValue,
  hideCollapse,
  maxDate
}: IDateRangeFilter) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [value, setValue] = useState<[Date | null, Date | null]>(activeValue || [null, null]);

  const handleChangeDate = (value: [Date | null, Date | null]) => {
    setValue(value);
    if (value.every((item: Date | null) => item !== null)) {
      handleChange({ Type: type, value: value as Date[] });
    } else if (value.every((item) => item === null)) {
      handleFilterReset(type);
    }
  };

  const resetDate = (value: number) => {
    setValue([null, null]);
    handleFilterReset(value);
  };

  return (
    <FilterTemplate
      label={t(`filters.${filtersLabelsKeys[type]}`)}
      handleFilterReset={resetDate}
      isActive={activeValue?.some((item) => item !== null)}
      type={type}
      hideCollapse={hideCollapse}
    >
      <DatePickerInput
        popoverProps={{
          zIndex: 1110,
          withinPortal: false
        }}
        className={classes.container}
        type="range"
        clearable
        placeholder={t('users.chooseRange')}
        value={value}
        onChange={handleChangeDate}
        leftSection={<CalendarIcon />}
        locale={locale}
        maxDate={maxDate}
        allowSingleDateInRange
        styles={{
          day: {
            '&[data-today]': {
              backgroundColor: 'rgba(206, 234, 255, 0.65)'
            }
          }
        }}
      />
    </FilterTemplate>
  );
};
