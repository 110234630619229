import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentItem, ILicenseActivations } from '@/entities/admin-app/activations/types';

const initialState: ILicenseActivations = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: false,
  first: false,
  size: 0,
  numberOfElements: 0,
  empty: false
};

export const licenseActivations = createSlice({
  name: 'licenseActivations',
  initialState,
  reducers: {
    setLicenseActivations(state, action: PayloadAction<ContentItem[]>) {
      state.content = action.payload;
    }
  }
});

export const licenseActivationsActions = licenseActivations.actions;
export const licenseActivationsReducer = licenseActivations.reducer;
