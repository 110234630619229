import { useLazyVerifyEmailServersQuery } from '@/entities/admin-app/email/api';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useState } from 'react';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import {
  ISelectedUser,
  ISortedListByEmailSettings,
  IUserTableData
} from '@/entities/admin-app/users';
import { getUsersIdBySelect } from '@/containers/pages/users/utils';

interface IUseVerifyUsersEmail {
  selectedUsers: number[];
  users: IUserTableData[];
  isConfirmModal?: boolean;
}
export const useVerifyUsersEmail = ({
  selectedUsers,
  users,
  isConfirmModal
}: IUseVerifyUsersEmail) => {
  const [getVerifiedList, { data: CustomerEmailData, isSuccess, isLoading, isError }] =
    useLazyVerifyEmailServersQuery();
  const [userList, setUserList] = useState<ISelectedUser[]>([]);
  const [sortedList, setSortedList] = useState<ISortedListByEmailSettings[]>([]);
  const [verifiedIds, setVerifiedIds] = useState<number[]>([]);
  const getCustomerEmailData = useCallback(async (list: number[]) => {
    try {
      const res = await getVerifiedList(list);
      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err: any) {
      console.warn('Ошибка', err);
    }
  }, []);

  useEffect(() => {
    const data: ISelectedUser[] = users
      .filter((user) => selectedUsers?.includes(user.Id))
      .map((filtered) => ({
        isChecked: true,
        isDisabled: false,
        id: filtered.Id,
        firstName: filtered?.FirstName || '',
        surName: filtered?.Surname || '',
        email: filtered?.Email,
        customerId: filtered?.Customer?.Id,
        customerName: filtered?.Customer?.Name,
        isConfirmed: filtered?.IsConfirmed
      }));
    setUserList(data);

    const uniqueCustomerIds = Array.from(new Set(data.map((user) => user.customerId))).filter(
      (customerId) => customerId !== undefined
    ) as number[];

    if (uniqueCustomerIds?.length) {
      verifyCustomers(uniqueCustomerIds);
    }
  }, [selectedUsers]);

  const verifyCustomers = async (filials: number[]) => {
    await getCustomerEmailData(filials);
  };

  const isUserChecked = (user: ISelectedUser, isConfirmModal?: boolean) => {
    return isConfirmModal ? user.email && !user?.isConfirmed : user.email;
  };

  useEffect(() => {
    if (CustomerEmailData?.Customers) {
      const sortedUsers = CustomerEmailData.Customers.map((customer) => {
        const usersForCustomer = userList
          .filter((user) => user?.customerId === customer?.CustomerId)
          .map((user) => ({
            ...user,
            isChecked: isUserChecked(user, isConfirmModal) ? customer.HasSettings : false,
            isDisabled: isUserChecked(user, isConfirmModal) ? !customer.HasSettings : true
          }));

        return {
          CustomerId: customer.CustomerId,
          CustomerName: usersForCustomer[0].customerName ? usersForCustomer[0].customerName : '',
          HasSettings: customer.HasSettings,
          Users: usersForCustomer
        };
      });

      setSortedList(sortedUsers);
      setVerifiedIds(getUsersIdBySelect(sortedUsers));
    } else {
      setVerifiedIds([]);
    }
  }, [CustomerEmailData]);

  const handleClickUser = (selectedUser: ISelectedUser) => {
    const newData = sortedList.map((customer) => {
      if (customer.CustomerId === selectedUser.customerId && customer.HasSettings) {
        const newUsers = customer.Users.map((user) => {
          if (user.id === selectedUser.id && !selectedUser.isDisabled) {
            return { ...user, isChecked: !selectedUser.isChecked };
          }
          return user;
        });

        return { ...customer, Users: newUsers };
      }
      return customer;
    });
    setSortedList(newData);
    setVerifiedIds(getUsersIdBySelect(newData));
  };

  return {
    isError,
    isSuccess,
    isLoading,
    sortedList,
    handleClickUser,
    verifiedIds
  };
};
