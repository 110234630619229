import React, { useMemo } from 'react';
import { Flex } from '@mantine/core';
import ReactApexChart from 'react-apexcharts';
import { useAppSelector } from '@hooks/redux/redux';
import moment from 'moment';
import { formatDate } from '@/lib/utils/date';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';

interface IActionsUserActivity {
  Date: string;
  Visitors: number;
  Auths: number;
  Documents: number;
  Files: number;
}

const ActivityCharts = () => {
  const { t, i18n } = useTranslation();
  const actionsUserActivity = useAppSelector(
    (state) => state.statistics.userActivity.Items as unknown as IActionsUserActivity[]
  );
  const { classes } = useStyles();

  // Prepare the data for the chart
  const series = useMemo(
    () => [
      {
        name: t('statistics.visitors'),
        data: actionsUserActivity.map((item) => item.Visitors)
      },
      {
        name: t('statistics.authorizations'),
        data: actionsUserActivity.map((item) => item.Auths)
      },
      {
        name: t('statistics.documents'),
        data: actionsUserActivity.map((item) => item.Documents)
      },
      {
        name: t('statistics.files'),
        data: actionsUserActivity.map((item) => item.Files)
      }
    ],
    [actionsUserActivity]
  );

  const options = {
    chart: {
      type: 'bar',
      locales: [
        {
          name: i18n?.language,
          options: {
            toolbar: {
              exportToSVG: `${t('save') + ' SVG'}`,
              exportToPNG: `${t('save') + ' PNG'}`,
              exportToCSV: `${t('save') + ' CSV'}`,
              menu: t('menu'),
              selection: t('selection'),
              selectionZoom: `${t('selection') + ' Zoom'}`,
              zoomIn: t('zoomIn'),
              zoomOut: t('zoomOut'),
              pan: t('panning'),
              reset: `${t('reset') + ' Zoom'}`
            }
          }
        }
      ],
      defaultLocale: i18n?.language
    },
    xaxis: {
      categories: actionsUserActivity.map((item) => formatDate(moment.utc(item.Date)))
    }
  };

  return (
    <Flex direction="column" className={classes.charts}>
      <ReactApexChart
        options={options as any}
        series={series}
        type="area"
        width="100%"
        height={300}
      />
    </Flex>
  );
};

export default ActivityCharts;
