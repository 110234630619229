import { FC } from 'react';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { SidebarMenu } from '@components/SidebarMenu';
import { getFilialEditRoutes } from '@/containers/pages/filials/utils';
import { useTranslation } from 'react-i18next';
import { useGetNavMenuItems } from '@/containers/pages/filials/edit/components/nav-menu/useGetNavMenuItems';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { filialModulesNavItems, filialCommonNavItems } = useGetNavMenuItems();
  const linksBlocks = [
    {
      label: t('common'),
      pages: filialCommonNavItems.filter(
        (item) => !isSaaS(Settings) || !['mailing-setup'].includes(item.value)
      )
    },
    {
      label: t('modules'),
      pages: filialModulesNavItems
      // isSaaS(Settings)
      //   ? filialModulesNavItems
      //   : filialModulesNavItems.filter(
      //       (module) => module.value !== ROUTES.filials.edit.pages.modules.projects
      //     )
    }
  ];

  return (
    <SidebarMenu setIsOpen={setIsOpen} linksBlocks={linksBlocks} routes={getFilialEditRoutes()} />
  );
};
