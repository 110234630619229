import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { ILicense, ILicenseCommon } from '@/entities/admin-app/activations/types';
const tagTypes = ['LicenseList'];
export const licenseActivationsApi = createApi({
  reducerPath: 'api/licenseActivations',
  baseQuery: customBaseQuery(`${BASE_URL}`),
  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getLicenceList: build.query<ILicense[], void>({
      query: () => ({
        url: '/v1/Licenses'
      }),
      providesTags: ['LicenseList']
    }),
    activateLicence: build.mutation<ILicense, ILicenseCommon>({
      query: (payload: ILicenseCommon) => ({
        url: '/v1/Licenses/Activate',
        method: 'post',
        body: { ...payload }
      }),
      invalidatesTags: ['LicenseList']
    }),
    deactivateLicence: build.mutation<void, number>({
      query(id: number) {
        return {
          url: `/v1/Licenses/Deactivate?Id=${id}`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['LicenseList']
    })
  })
});

export const { useActivateLicenceMutation, useDeactivateLicenceMutation, useGetLicenceListQuery } =
  licenseActivationsApi;
