import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${theme.spacing.xs} 0px `,
    width: '100%'
  },

  title: {
    color: theme.colors.brandGray[0],
    fontSize: '12px'
  },

  description: {
    fontSize: '14px',
    marginTop: '5px'
  },
  group: {
    display: 'flex',
    flexDirection: 'row'
  },
  rounded: {
    fontSize: '14px',
    lineHeight: '125%',
    border: 'none',
    borderRadius: '34px',
    backgroundColor: '#F2F4F7',
    padding: '8px 12px'
  }
}));
