import { FC, useEffect } from 'react';
import { Button, Flex, Text, NumberInput } from '@mantine/core';

import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';

import { FilialInfoPanelsEnum } from '@/types/enums/filials';

import { useStyles } from '../../../../../styles';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export const SessionDuration: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);

  const saveSettings = useSaveSettings();

  const schema = z.object({
    duration: z.number({ invalid_type_error: t('filialSecurity.errors.sessionTime') }).positive({
      message: t('filialSecurity.errors.positive')
    })
  });

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      duration: 1440
    },
    validate: (values): FormErrors => getValidateErrors(schema, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (!filial?.Settings) {
      return;
    }
    const formData = {
      duration: filial.Settings.SessionDuration
    };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = () => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.SessionDuration, {
      SessionDuration: form.values.duration
    });
  };

  return (
    <Flex
      className={classes.section}
      align="flex-start"
      direction="column"
      data-section={FilialInfoPanelsEnum.sessionDuration}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex align="flex-start" direction="column">
          <Text className={classes.title}>{t('filialSecurity.sessionTitle')}</Text>

          <NumberInput
            className={classes.textFieldInput}
            hideControls
            {...form.getInputProps(`duration`)}
          />

          <Text className={classes.description} mt="sm" mb="md">
            {t('filialSecurity.sessionInfo')}
          </Text>

          <Button disabled={isDisabled} size="sm" radius={8} mr="sm" type="submit">
            {t('save')}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
