import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@supports(-webkit-touch-callout: none)': {
      minHeight: '-webkit-fill-available'
    }
  },

  responseContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  desc: {
    lineHeight: '22px',
    color: theme.colors.gray[6],
    margin: '24px 0 32px'
  },

  responsiveInput: {
    [utils.smallerThan('xs')]: {
      width: '100%'
    }
  },

  icon: {
    marginTop: '15px',
    width: '100px',
    height: '100px'
  },

  title: {
    [utils.dark]: {
      color: theme.white
    },
    [utils.light]: {
      color: theme.black
    },
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  }
}));
