import { forwardRef } from 'react';

export const ErrorIcon = forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} {...props} style={{ display: 'flex' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16">
      <path
        stroke="#DE0E0E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 6.5v3.125m-7.753 2.813c-.721 1.25.181 2.812 1.624 2.812h12.258c1.442 0 2.344-1.562 1.624-2.812L10.624 1.815c-.722-1.25-2.527-1.25-3.248 0L1.247 12.438zM9 12.125h.006v.007H9v-.007z"
      ></path>
    </svg>
  </div>
));

ErrorIcon.displayName = 'ErrorIcon';
