import { createStyles } from '@mantine/emotion';
export interface ISearchStylesParams {
  fullSize?: boolean;
}

export const useStyles = createStyles((theme, { fullSize }: ISearchStylesParams) => ({
  search: {
    width: fullSize ? '100%' : 'auto',

    '& input': {
      borderRadius: '8px',
      border: 'none',
      fontWeight: 400
    },

    '& .mantine-Input-input[data-with-icon]': {
      paddingLeft: '56px'
    },

    '& .mantine-Input-icon': {
      marginLeft: theme.spacing.sm
    }
  },

  clearIcon: {
    cursor: 'pointer'
  }
}));
