export interface IUserLink {
  id: string;
  link: string;
  name: string;
  author: string;
}

export const USER_LINKS_LIST: IUserLink[] = [
  {
    id: '8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType3',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType=1',
    name: 'Новая ссылка',
    author: 'Анна Плотникова'
  },
  {
    id: '8770814e-4d08-459b-a87a-d39a3eaab4ff&emplTyp4',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType2',
    name: 'Новая ссылка1',
    author: 'Мартынов Иван'
  },
  {
    id: '8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType7123',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType3',
    name: 'Новая ссылка2',
    author: 'Алексей Муравьев'
  },
  {
    id: '8870814e-4d08-459b-a87a-d39a3eaab4ff&emplType3',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType4',
    name: 'Новая ссылка3',
    author: 'Мартынов Иван'
  },
  {
    id: '814e-4d08-459b-a87a-d39a3eaab4ff&emplType3',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType5',
    name: 'Новая ссылка4',
    author: 'Анна Плотникова'
  },
  {
    id: '8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType3213121',
    link: 'L://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType77',
    name: 'Новая ссылка5',
    author: 'Тест тестовский'
  }
];
