export const preserveCursorPosition = (
  ref: React.RefObject<HTMLInputElement>,
  togglePasswordVisibility: () => void
) => {
  if (ref.current) {
    const cursorPosition = ref.current.selectionStart;
    togglePasswordVisibility();

    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
        ref.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  }
};
