import { FC, useEffect } from 'react';
import { Button, Flex, Radio, Text, TextInput, ActionIcon } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';

import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { tranformAdrressesToForm, tranformAdrresses } from '../../utils';
import { Address } from '../../types';
import { useStyles } from '../../../../../styles';
import { validateSchemeIpSecurity } from '../../validate';

export const IPSecurityAdm: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { filial } = useAppSelector((state) => state.filials);
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      addresses: [] as Address[],
      enabled: false
    },

    validate: zodResolver(validateSchemeIpSecurity(t))
  });

  const isDisabledSubmit =
    Object.keys(form.errors).length !== 0 ||
    !form.isDirty() ||
    (!form.values.addresses.length && form.values.enabled);

  const saveSettings = useSaveSettings();

  useEffect(() => {
    const { AdminIp } = filial?.Settings || {};

    if (!AdminIp) {
      return;
    }

    const formData = {
      addresses: tranformAdrressesToForm(AdminIp.IpAdresses || []),
      enabled: AdminIp.Enabled
    };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = () => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.AdminIp, {
      AdminIp: {
        Enabled: form.values.enabled,
        IpAdresses: tranformAdrresses(form.values.addresses)
      }
    });
  };

  return (
    <Flex
      className={classes.section}
      align="flex-start"
      direction="column"
      data-section={FilialInfoPanelsEnum.ipSecurityAdm}
    >
      <Text className={classes.title}> {t('filialSecurity.ipSecurityAdministration')}</Text>
      <Radio
        className={classes.radio}
        size="xs"
        checked={!form.values.enabled}
        onChange={() => form.setValues({ enabled: false, addresses: [] })}
        label={t('disable')}
      />
      <Radio
        className={classes.radio}
        size="xs"
        checked={form.values.enabled}
        onChange={() => form.setValues({ enabled: true })}
        label={t('enable')}
      />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        {form.values.enabled && (
          <Flex align="flex-start" direction="column">
            <Text className={classes.description} my="sm">
              {t('filialSecurity.ipSecurityWarningDescription')}
            </Text>

            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => {
                form.insertListItem('addresses', { address: '' });
              }}
            >
              {t('filialSecurity.addAllowedIpAddress')}
            </Button>

            {form.values.addresses.map((el, index) => (
              <Flex key={index} align="flex-start" mt="sm">
                <TextInput
                  style={{ width: '220px' }}
                  className={classes.textFieldInput}
                  id={el.address}
                  {...form.getInputProps(`addresses.${index}.address`)}
                  // error={form.getInputProps(fieldName).error}
                />
                <ActionIcon
                  className="action-icon"
                  onClick={() => form.removeListItem('addresses', index)}
                  ml="sm"
                  mt={5}
                >
                  <IconTrash
                    size="1.3rem"
                    color="#667085"
                    stroke={1.5}
                    style={{ pointerEvents: 'none' }}
                  />
                </ActionIcon>
              </Flex>
            ))}
          </Flex>
        )}

        <Text className={classes.description} mt="sm" mb="md">
          {t('filialSecurity.ipSecurityAdminDescription')}
        </Text>
        <Button disabled={isDisabledSubmit} size="sm" radius={8} type="submit" mr="sm">
          {t('save')}
        </Button>
      </form>
    </Flex>
  );
};
