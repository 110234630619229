import { generatePath, useNavigate } from 'react-router-dom';
import { Menu, UnstyledButton, Text } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons';

import { ROUTES } from '../../../../../../../routes/constants';

type Props = {
  id: string;
};

export const MenuButton = ({ id }: Props) => {
  const navigate = useNavigate();

  const handleChange = () => navigate(generatePath(ROUTES.users.links.link.fullPath, { id }));

  return (
    <Menu>
      <Menu.Target>
        <UnstyledButton>
          <IconDotsVertical color="#667085" />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <Text fz="sm">Скопировать</Text>
        </Menu.Item>
        <Menu.Item onClick={handleChange}>
          <Text fz="sm">Редактировать</Text>
        </Menu.Item>
        <Menu.Item>
          <Text fz="sm" color="#C01048">
            Удалить ссылку
          </Text>
        </Menu.Item>
        <Menu.Item>
          <Text fz="sm" color="#C01048">
            Удалить вместе с пользователями
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
