import { Box } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { PanelsEnum } from '@/types/enums/user';
import { useStyles } from './styles';

interface ISectionTemplateProps {
  title?: string;
  children: ReactNode;
  dataSection?: PanelsEnum;
}

export const CommonSectionTemplate: FC<ISectionTemplateProps> = ({
  title,
  children,
  dataSection
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} data-section={dataSection}>
      {title && <Box className={classes.title}>{title}</Box>}

      <Box className={classes.form}>{children}</Box>
    </Box>
  );
};
