import { IColumnData, IRowData } from '../../components/simple-table/types';
import { IUserTableData } from '../../entities/admin-app/users/types';
import Papa from 'papaparse';

export const blobToBase64 = async (blob: Blob) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const base64ToBlob = async (file: string): Promise<Blob> => {
  const blob = await (await fetch(file)).blob();

  return blob;
};

export const downloadCsv = (
  userData: IUserTableData[] | IRowData[],
  columnsData: IColumnData[]
) => {
  const csv = Papa.unparse({
    fields: columnsData.filter((column) => !column.hideColumn).map((column) => column.dataIndex),
    data: userData.map((user: Record<string, any>) => {
      const userRow: Record<string, any> = {};
      columnsData
        .filter((column) => !column.hideColumn)
        .forEach((column) => {
          const renderValue = column.render && column.render(user) ? column.render(user) : '';
          const currentValue = renderValue ? renderValue : user[column.dataIndex];
          userRow[column.dataIndex] = currentValue;
        });
      return userRow;
    })
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'users.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
