import { IColumnData, IRowData } from '@components/simple-table/types';
import { Tr } from '@components/simple-table/tr';
import React from 'react';

interface ITableRowProps {
  rows: IRowData[];
  columns: IColumnData[];
}

export const TableRowList = ({ columns, rows }: ITableRowProps) => {
  return (
    <>
      {rows.map((row, index) => {
        return (
          <Tr key={`${row?.Id ? row?.Id : row?.CustomerId}${index}`} columns={columns} row={row} />
        );
      })}
    </>
  );
};
