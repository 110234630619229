import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';

import { IFullResponse, IQueryTable, IQueryUsersTable } from '@/types/api';
import { BASE_URL } from '../constants';
import {
  DefaultFilter,
  DefaultFiltersResponse,
  IFilterItemsRequest,
  IFilterItemsResponse,
  IRemoveResponse,
  IRemoveStatusResponse,
  IRemoveUserDto,
  IUsersData,
  IUsersList,
  IUserTableData
} from './types';
import { IListItem } from '@/types';
import { IUsersInitialFilters } from '@/types/enums/users';

const tagTypes = ['Users', 'Filters', 'UsersList', 'DefaultFilters'];

export const usersApi = createApi({
  reducerPath: 'api/users',
  baseQuery: customBaseQuery(`${BASE_URL}/v2/Profile/`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getUsers: build.query<IUsersData, IQueryUsersTable>({
      query: (payload?: IQueryUsersTable) => ({
        url: `Users`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IUsersData>) => response.Response.Data,
      providesTags: ['Users']
    }),
    getUsersList: build.query<IUsersList, IQueryTable<IUsersInitialFilters>>({
      query: (payload?: IQueryTable<IUsersInitialFilters>) => ({
        url: `Users`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IUsersData>) => ({
        TotalCount: response.Response.Data.TotalCount,
        Users: response.Response.Data.Users.map(
          (user: IUserTableData): IListItem => ({
            label: `${user.FirstName ?? ''} ${user.Surname ?? ''} ${user.MiddleName ?? ''}`.trim(),
            value: user.Id,
            checked: false
          })
        )
      }),
      providesTags: ['UsersList']
    }),
    getFilterListItems: build.query<IFilterItemsResponse, IFilterItemsRequest>({
      query: (payload: IFilterItemsRequest) => ({
        url: `Filters`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IFilterItemsResponse>) => {
        return response.Response.Data;
      }
    }),
    getDefaultUserFilters: build.query<DefaultFilter[], number>({
      query: (count: number) => ({
        url: `defaultuserfilters?count=${count}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<DefaultFiltersResponse>) =>
        response.Response.Data.DefaultFilters,
      providesTags: ['DefaultFilters']
    }),
    removeUsers: build.mutation<IRemoveResponse, IRemoveUserDto[]>({
      query: (body) => ({
        url: `safedeleteusers`,
        method: 'post',
        body: { Users: body }
      }),
      transformResponse: (response: IFullResponse<IRemoveResponse>) => {
        return response.Response.Data;
      }
    }),
    checkRemoveStatus: build.mutation<IRemoveStatusResponse['Results'], IRemoveResponse>({
      query: (body) => ({
        url: `safedeleteusersstatus`,
        method: 'post',
        body
      }),
      transformResponse: (response: IFullResponse<IRemoveStatusResponse>) => {
        return response.Response.Data.Results;
      }
    })
  })
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useRemoveUsersMutation,
  useCheckRemoveStatusMutation,
  useGetFilterListItemsQuery,
  useGetDefaultUserFiltersQuery
} = usersApi;
