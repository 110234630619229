import { useCallback } from 'react';
import {
  commonSettingsApi,
  useUpdateProductNotificationMutation
} from '@/entities/admin-app/commonSettings/api';
import { IProductNotificationData } from '@/entities/admin-app/commonSettings';
import { clearApiCacheByTags } from '@/entities/helpers';
import { universalNotification } from '@/lib/utils/notification';

export const useSaveProductNotification = () => {
  const [updateProductNotification] = useUpdateProductNotificationMutation();
  return useCallback(async (values: IProductNotificationData) => {
    try {
      const res = await updateProductNotification(values);
      if ('error' in res) {
        universalNotification({
          error: res.error,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          error: null,
          isError: false,
          isSuccess: true,
          successTitle: `Настройки успешно сохранены!`
        });
        clearApiCacheByTags(commonSettingsApi, ['ProductNotification']);
      }
    } catch (err: any) {
      console.warn('Ошибка сохранения настроек', err);
    }
  }, []);
};
