import { ITransformedRoute } from '@/types';
import { ROUTES } from '@/containers/routes/constants';

export const getCommonSettingsRoutes = (): ITransformedRoute[] => {
  const { fullPath, pages } = ROUTES.commonSettings;
  return Object.values(pages).map((value) => {
    const path = `${fullPath}/${value ? `${value}` : ''}`;
    return { path, chunk: value };
  });
};
