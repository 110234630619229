import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white'
  },

  modalContent: {
    textAlign: 'start'
  },
  modalControls: {
    background: theme.colors.white,
    width: '100%'
  }
}));
