import { useCallback } from 'react';

import { universalNotification } from '@/lib/utils/notification';
import { useLazyBulkForgotQuery } from '@/entities/admin-app/auth/api';

export const useResetPassword = () => {
  const [fetchResetPassword] = useLazyBulkForgotQuery();

  const resetPassword = useCallback(async (selection: number[]) => {
    const numbersSelection = selection?.map((item) => Number(item)) ?? [];

    try {
      const { isSuccess, isError, error } = await fetchResetPassword(numbersSelection);

      const _errorStatus = (error as any)?.data;

      universalNotification({
        isSuccess: isSuccess,
        isError: isError,
        error: _errorStatus,
        successTitle: 'Пароли успешно сброшены'
      });
    } catch (err: any) {
      console.warn('fetchResetPassword ERROR', err);
    }
  }, []);

  return { resetPassword };
};
