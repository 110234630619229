import { MantineColorsTuple } from '@mantine/core';

export const themeColors: Record<string, MantineColorsTuple> = {
  main: [
    '#e2f8ff',
    '#ceeaff',
    '#9fd1fb',
    '#6db8f5',
    '#43a2f1',
    '#2894ef',
    '#118eef',
    '#007ad6',
    '#006dc1',
    '#005dac'
  ],
  mainBlue: [
    '#ebf1fb',
    '#84a9e8',
    '#779fe5',
    '#6996e3',
    '#5b8ce0',
    '#4e82dd',
    '#4079db',
    '#326fd8',
    '#2566d6',
    '#175cd3'
  ],
  backgroundGray: [
    '#f9fafb',
    '#f2f3f4',
    '#ebeced',
    '#e4e4e5',
    '#ddddde',
    '#d5d6d7',
    '#f2f4f7',
    '#c7c8c8',
    '#c0c1c1',
    '#b9b9ba'
  ],
  indigo: [
    '#4e5ba6',
    '#5865ab',
    '#636eb0',
    '#6d78b6',
    '#7882bb',
    '#828bc0',
    '#8c95c5',
    '#979fcb',
    '#a1a8d0',
    '#e0e2ef'
  ],
  brandOrange: [
    '#f79009',
    '#f79717',
    '#f89d26',
    '#f8a434',
    '#f9aa43',
    '#f9b151',
    '#fab760',
    '#fabe6e',
    '#fbc47d',
    '#feebd4'
  ],
  brandGray: [
    '#667085',
    '#EAECF0', // button, border, bg
    '#eff0f4', // disabled button
    '#81899b',
    '#8a92a2',
    '#939aa9',
    '#9ca2b0',
    '#a5abb7',
    '#aeb3be',
    '#b7bcc6'
  ],
  brandRed: [
    '#C01048', // text
    '#C23939', // button
    '#c72c5e',
    '#cb3a68',
    '#cf4873',
    '#d3567e',
    '#d66489',
    '#da7293',
    '#de809e',
    '#e18fa9'
  ]
};
