import { Button, LoadingOverlay, Select, SimpleGrid } from '@mantine/core';
import { FC, useContext, useEffect } from 'react';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { EditUserInfoContext } from '../../../../index';
import { useAppSelector } from '@/hooks/redux/redux';
import { isAdmin } from '@/lib/utils/access';
import { FormErrors, useForm } from '@mantine/form';
import { IUserCustomer } from '@/entities/admin-app/user';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateSchemeCustomer } from '@/containers/pages/user/schemes/validate';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';

const UserFilial: FC = () => {
  const { t } = useTranslation();
  const { userInfoCommon, isLimitedAccess, currentId } = useContext(EditUserInfoContext);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { Customer } = userInfoCommon;
  const { saveUpdate, isLoading } = useUpdateInfo();

  const userFilialForm = useForm<IUserCustomer>({
    initialValues: {
      Id: null,
      Name: ''
    },
    validateInputOnChange: true,
    validate: (values: IUserCustomer): FormErrors =>
      getValidateErrors(validateSchemeCustomer(t), values)
  });

  const errors = userFilialForm.errors;
  const isDirtyForm = userFilialForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    Id: Customer?.Id || null,
    Name: Customer?.Name || ''
  };

  useEffect(() => {
    userFilialForm.setValues(fromData);
    userFilialForm.resetDirty(fromData);
  }, [Customer]);

  const handleSubmit = async (values: IUserCustomer) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Customer,
      Data: { [ProfileSectionEnum.Customer]: values, Id: currentId }
    });
    // userFilialForm.setValues(fromData);
    // userFilialForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={userFilialForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.unit')}>
        <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
          {isLimitedAccess ? (
            <Select
              label={t('profile.unit')}
              required={true}
              data={[
                {
                  value: userFilialForm.values.Id?.toString() || '',
                  label: userFilialForm.values.Name || ''
                }
              ]}
              value={userFilialForm.values.Id?.toString()}
              disabled={true}
            />
          ) : (
            <SelectWithPagination
              label={t('profile.unit')}
              list={[]}
              placeholder={t('profile.choose')}
              fieldName="Id"
              form={userFilialForm}
              defaultValue={userFilialForm?.values?.Id}
              value={userFilialForm?.values?.Id}
            />
          )}
          {userFilialForm.getInputProps(`Customer.Id`).error}
        </SimpleGrid>
        {isAdmin(currentUser) && (
          <Button
            type="submit"
            size="sm"
            radius={8}
            disabled={isDisabled}
            mt="md"
            style={{ padding: '0 20px' }}
            data-cy="saveFilialBtn"
          >
            {t('profile.save')}
          </Button>
        )}
        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};

export default UserFilial;
