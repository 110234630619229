import {
  Button,
  Text,
  Flex,
  List,
  Box,
  LoadingOverlay,
  Group,
  UnstyledButton
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';
import { IconAlertTriangle, IconCheck } from '@tabler/icons';
import { useLazyDomainSettingsQuery } from '@/entities/admin-app/email/api';
import { useEffect } from 'react';
import {
  IDomain,
  IRecordData,
  IRecordDetails,
  RecordName,
  RecordType
} from '@/entities/admin-app/email';
import { Controls } from '@/components/modal/components/controls';

interface IPreviewDomainProps {
  handleClose: () => void;
  domain: IDomain | IRecordDetails | undefined;
}

export const DomainSettings = ({ handleClose, domain }: IPreviewDomainProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [getDomainSettings, { data, isSuccess, isFetching, isLoading, isError }] =
    useLazyDomainSettingsQuery();

  useEffect(() => {
    handleGetDomainSettings();
  }, [domain]);

  const handleGetDomainSettings = () => {
    if (domain && domain.Id) {
      getDomainSettings({ id: domain.Id });
    }
  };

  const numbersToType = (value: number): string => {
    return value === RecordType.MX ? 'MX' : 'TXT';
  };

  const numbersToRecordName = (value: number) => {
    switch (value) {
      case RecordName.MX:
        return t('domains.mxRecord');
      case RecordName.SPF:
        return t('domains.spfRecord');
      case RecordName.DKIM:
        return t('domains.dkimRecord');
    }
  };

  return (
    <>
      <Box p="24px">
        <Box className={classes.scrollContainer}>
          {(isFetching || isLoading) && !isError && (
            <Box h={data ? '' : '350px'}>
              <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} />
            </Box>
          )}
          {data?.Records.length && data?.Records.some((record) => !record.IsSussess) && (
            <Text>{t('domains.settingsError')}</Text>
          )}
          {isSuccess && data?.Records.length
            ? [...data.Records]
                .sort((a, b) => a.Record - b.Record)
                .map((record: IRecordData, index) => (
                  <Box key={index}>
                    <Flex py="xs">
                      {record.IsSussess ? (
                        <IconCheck color="green" stroke={4} />
                      ) : (
                        <IconAlertTriangle color="red" stroke={2} />
                      )}
                      <Text pl="xs">{numbersToRecordName(record.Record)}</Text>
                    </Flex>
                    <List size="sm" pl="xl" pr="xs">
                      <List.Item>
                        {t('type')}:&nbsp;<b>{numbersToType(record.Type)}</b>
                      </List.Item>
                      {record.Record !== RecordName.MX && (
                        <List.Item>
                          {t('name')}:&nbsp;<b>{record.Name}</b>
                        </List.Item>
                      )}
                      <List.Item>
                        {t('domains.textMeaning')}:&nbsp;
                        <b className={classes.linkValue}>{record.Value}</b>
                      </List.Item>

                      {record.Record === RecordName.MX && (
                        <List.Item>
                          {t('domains.priority')}:&nbsp;<b>{record.Priority}</b>
                        </List.Item>
                      )}
                    </List>
                  </Box>
                ))
            : null}
        </Box>
      </Box>
      <Controls>
        <Group grow>
          <Button onClick={() => handleGetDomainSettings()}>{t('verify')}</Button>
          <UnstyledButton className={classes.cancelBtn} onClick={handleClose}>
            {t('cancel')}
          </UnstyledButton>
        </Group>
      </Controls>
    </>
  );
};
