import React, { ReactNode, useEffect } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { useLazyCheckUserExistFieldQuery } from '@/entities/admin-app/auth/api';
import { ActionIcon, Flex, Loader, TextInput } from '@mantine/core';
import { getNameFromDottedString } from '../../utils';
import { IconX } from '@tabler/icons';
import { useStyles } from '@components/fields/text-field-input/styles';
import { ValidateFieldEnum } from '@/entities/admin-app/auth';
import { EmailActivationControl } from '@components/email-activation/emailActivationControl';
import { useDebounce } from '@/hooks/useDebounce';
import { useTranslation } from 'react-i18next';

interface IValidateTextInput {
  fieldName: string;
  form: UseFormReturnType<any>;
  className?: string;
  label?: string;
  disabled?: boolean;
  clearable?: boolean;
  rightSection?: ReactNode;
  isConfirmed?: boolean;
  isRequired?: boolean;
  userId?: number;
  withAsterisk?: boolean;
  customerId?: number | null;
  placeholder?: string;
  isSaasEmail?: boolean;
  currentId?: number;
  dataAttribute?: string;
}

export const ValidateTextInput = ({
  form,
  fieldName,
  className,
  disabled,
  label,
  clearable,
  rightSection,
  isRequired,
  isConfirmed,
  userId,
  withAsterisk,
  customerId,
  placeholder,
  isSaasEmail,
  currentId,
  dataAttribute
}: IValidateTextInput) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [checkFieldExist, { isFetching: isFetchingValidate }] = useLazyCheckUserExistFieldQuery();
  const fieldNameEnum = getNameFromDottedString(fieldName);
  const currentInputValue = form.getInputProps(fieldName).value;

  const debounce = useDebounce(currentInputValue, 300);

  useEffect(() => {
    handleValidateField(debounce);
  }, [debounce]);

  const handleValidateField = async (field: string | undefined) => {
    if (field?.length && !form.getInputProps(fieldName).error && form.isDirty(fieldName)) {
      const payload = isSaasEmail
        ? {
            existField: ValidateFieldEnum.LoginAndEmail,
            userId: currentId,
            field
          }
        : {
            existField: fieldNameEnum,
            field
          };
      const response = await checkFieldExist(payload);
      if (response?.data?.ExistsUser) {
        form.setFieldError(
          fieldName,
          `${t('profile.such')} ${
            fieldNameEnum === ValidateFieldEnum.Email
              ? t('profile.mail')
              : fieldNameEnum === ValidateFieldEnum.Phone
              ? t('profile.phone')
              : t('profile.loginExist')
          } ${t('profile.exists')}`
        );
      }
    }
  };

  const showAlertIcon: boolean =
    !isConfirmed &&
    fieldNameEnum === ValidateFieldEnum.Email &&
    !form.isDirty(fieldName) &&
    currentInputValue?.length;

  const clearValue = () => {
    form.setFieldValue(fieldName, '');
  };

  const rightSectionRender = () => {
    return (
      <Flex>
        {isFetchingValidate ? (
          <Loader size="xs" m="auto 0" />
        ) : rightSection ? (
          rightSection
        ) : currentInputValue && clearable ? (
          <ActionIcon variant="transparent" onClick={clearValue}>
            <IconX color="#667085" />
          </ActionIcon>
        ) : null}
        {showAlertIcon && customerId ? (
          <EmailActivationControl Email={currentInputValue} Id={userId} customerId={customerId} />
        ) : null}
      </Flex>
    );
  };

  return (
    <TextInput
      className={cx(classes.textFieldInput, className)}
      label={label}
      disabled={disabled}
      withAsterisk={withAsterisk}
      required={isRequired}
      rightSection={rightSectionRender()}
      rightSectionWidth={60}
      error={form.getInputProps(fieldName).error}
      value={form.getInputProps(fieldName).value || placeholder || ''}
      onChange={form.getInputProps(fieldName).onChange}
      onBlur={(event) => handleValidateField(event.target.value)}
      data-name={fieldName}
      data-cy={dataAttribute}
    />
  );
};
