import React, { Dispatch, FC, SetStateAction, useCallback, useState, useEffect } from 'react';
import { Drawer } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { Panels } from './panels';
import { PanelsEnum } from '@/types/enums/user';
import { useAppSelector } from '@hooks/redux/redux';
import { useActions } from '@hooks/redux/action';

interface IUserInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const UserInfoMain: FC<IUserInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const { setSelectedUserId } = useActions();
  const { selectedUserId } = useAppSelector((state) => state.users);

  const [activePanel, setActivePanel] = useState<PanelsEnum>(PanelsEnum.generalInformation);

  const handleClose = useCallback(() => {
    setSelectedUserId(0);
    setActivePanel(PanelsEnum.generalInformation);
  }, []);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <Drawer
      zIndex={1100}
      opened={Boolean(selectedUserId)}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {selectedUserId ? (
        <Panels
          activePanel={activePanel}
          setActivePanel={setActivePanel}
          selectedUserId={selectedUserId}
          setRowData={setRowData}
        />
      ) : null}
    </Drawer>
  );
};
