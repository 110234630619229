import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux/redux';

const ProfilePageRoute = () => {
  const currentUser = useAppSelector((state) => state.userInfo.User);

  return (
    <>
      <Outlet />
      <Navigate to={'/profile/' + currentUser?.Id} />
    </>
  );
};

export default ProfilePageRoute;
