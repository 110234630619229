import { Button, Flex, Loader, Stack, Text, UnstyledButton } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconCertificate } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useGetUserTariffQuery } from '@/entities/admin-app/user/api';
import { ExpiredPayment } from '../../expiredPayment';
import { GB, formatAmount } from '../../../utils';
import { ValidPayment } from '../../validPayment';
import { FC } from 'react';
import { useStyles } from './styles';
import { useAppSelector } from '@/hooks/redux/redux';
import { ModuleUrl } from '@/entities/admin-app/auth';
import { Link } from 'react-router-dom';

interface IPaymentSectionProps {
  filialId?: number;
}

export const PaymentSection: FC<IPaymentSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data, isLoading } = useGetUserTariffQuery(filialId ?? 0);
  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings?.ModuleUrls
  ) as ModuleUrl[];

  const paymentModule = Array.isArray(modules)
    ? modules.find((module) => module.Name === 'Payments')
    : undefined;
  const OplataLink = paymentModule?.Url;

  const isPayedTariff = () => {
    return data && data?.Tariff?.Type !== -1;
  };

  const isExpiredPayment = () => {
    //change later expired = status === 0
    const tariffDate = data?.Date ? new Date(data?.Date) : new Date();
    return tariffDate <= new Date();
  };

  const getUserCount = () => {
    if (!data?.UserCounter?.PossibleAmount) return 0;
    return `${data?.UserCounter?.CurrentAmount} / ${data?.UserCounter?.PossibleAmount}`;
  };

  const getUsedSpace = (amount: number) => {
    const currentAmount = amount / GB;
    return formatAmount(currentAmount);
  };

  const getAvailableSpace = () => {
    if (!data?.DiskCounter?.PossibleAmount) return 0;

    const currentAmountFormatted = getUsedSpace(data.DiskCounter.CurrentAmount);

    const possibleAmount = data.DiskCounter.PossibleAmount / GB;
    const possibleAmountFormatted = formatAmount(possibleAmount);

    return `${currentAmountFormatted} ${t('statistics.from')} ${possibleAmountFormatted}`;
  };

  const calculateDaysRemaining = (expiringDate?: string) => {
    if (!expiringDate) return 0;
    const currentDate = new Date();
    const futureDate = new Date(expiringDate);
    const timeDifference = futureDate.getTime() - currentDate.getTime();

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysRemaining;
  };

  const remainingDays = calculateDaysRemaining(data?.Date);

  return (
    <CardContainer sectionName={t('filials.payments')} Icon={IconCertificate}>
      {isLoading ? (
        <Flex w="100%" align="center" justify="center">
          <Loader size="sm" />
        </Flex>
      ) : (
        <>
          {isExpiredPayment() ? (
            <ExpiredPayment usedSpace={data?.DiskCounter.CurrentAmount} />
          ) : (
            <>
              <ValidPayment name={data?.Name} remainingDays={remainingDays} />
              <Stack gap="4px">
                <Flex justify="space-between">
                  <Text fz="sm" c="#848E98">
                    {t('filials.diskSpace.validity')}
                  </Text>
                  <Text fz="sm" fw="500">
                    {isPayedTariff()
                      ? `${
                          data?.Date
                            ? t('filials.diskSpace.to') +
                              ' ' +
                              new Date(data?.Date).toLocaleDateString('ru-RU')
                            : ''
                        }`
                      : t('filials.diskSpace.unlimited')}
                  </Text>
                </Flex>
                <Flex justify="space-between">
                  <Text fz="sm" c="#848E98">
                    {t('filials.diskSpace.usersNumber')}
                  </Text>
                  <Text fz="sm" fw="500">
                    {isPayedTariff() ? getUserCount() : data?.UserCounter?.CurrentAmount || 1}
                  </Text>
                </Flex>
                <Flex justify="space-between">
                  <Text fz="sm" c="#848E98">
                    {t('filials.diskSpace.availableSpace')}
                  </Text>
                  <Text fz="sm" fw="500">
                    {getAvailableSpace()}
                  </Text>
                </Flex>
              </Stack>
            </>
          )}

          {remainingDays > 30 ? (
            <UnstyledButton
              w="100%"
              mt="16px"
              fw="500"
              className={classes.changeBtn}
              component={Link}
              target="_blank"
              to={OplataLink ?? '#'}
            >
              {t('change')}
            </UnstyledButton>
          ) : (
            <Button
              w="100%"
              mt="16px"
              fw="500"
              component={Link}
              target="_blank"
              to={OplataLink ?? '#'}
              radius="4px"
            >
              {t('filials.diskSpace.activateTariff')}
            </Button>
          )}
        </>
      )}
    </CardContainer>
  );
};
