export const GB = 1024;

export const formatAmount = (amount: number) => {
  const formattedAmount = Number.isInteger(amount) ? amount : amount.toFixed(2);
  return +formattedAmount < 1 ? `${formattedAmount} Мб` : `${formattedAmount} Гб`;
};

export const capitalizeFirstLetter = (name?: string) => {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }
};
