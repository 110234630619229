import { ProfileSuccessMessageEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { t } from 'i18next';

export const getProfileSuccessMessage = (updateType: ProfileUpdateTypeEnum) => {
  if (updateType in ProfileSuccessMessageEnum) {
    return t(`profile.success.${ProfileSuccessMessageEnum[updateType]}`);
  } else {
    return t('saved');
  }
};
