import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
// import { useActions } from '../../../../hooks/redux/action';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/lib/utils/date';

export const useGetColumns = () => {
  // const { setSelectedGroupsIds } = useActions();
  const { t } = useTranslation();

  const columnsData: IColumnData[] = [
    {
      width: 'auto',
      dataIndex: 'Date',
      title: t('statistics.date'),
      hasSort: true,
      render: (item: IRowData) => (item?.Date ? formatDate(item?.Date) : t('statistics.total'))
    },
    {
      width: 'auto',
      dataIndex: 'Visitors',
      title: t('statistics.visitors_'),
      hasSort: true,
      render: (item: IRowData) => item?.Visitors
    },
    {
      width: 'auto',
      dataIndex: 'Auths',
      title: t('statistics.authorizations_'),
      hasSort: true,
      render: (item: IRowData) => item?.Auths
    },
    {
      width: 'auto',
      dataIndex: 'Documents',
      title: t('statistics.documents_create'),
      hasSort: true,
      render: (item: IRowData) => item?.Documents
    },
    {
      width: 'auto',
      dataIndex: 'Files',
      title: t('statistics.files_create'),
      hasSort: true,
      render: (item: IRowData) => item?.Files
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
