import React, { createContext, SyntheticEvent, useRef } from 'react';
import { Badge, Box, Loader, Menu, ComboboxItem, Text } from '@mantine/core';
import useLoadMore from '@/containers/pages/filials/components/select/hook/useLoadMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconChevronDown } from '@tabler/icons';
import { useStyles } from './styles';
import { UsersActionTableEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';
import { IActionItem } from '../types';

interface LoadMoreResult {
  list: ComboboxItem[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  total: number;
  loadMore: () => void;
}
interface IFilialsControl {
  item: IActionItem;
  handleClickListItem?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  handleClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
  disabled?: boolean;
}
export const FilialsControl = ({
  item,
  handleClickListItem,
  handleClick,
  disabled
}: IFilialsControl) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { list, total, loadMore }: LoadMoreResult = useLoadMore();

  const ref = useRef<HTMLInputElement>(null);

  return (
    <Menu
      width="auto"
      zIndex={400}
      position="bottom-start"
      classNames={{ dropdown: classes.dropdown }}
    >
      <Menu.Target>
        <Badge
          data-type={UsersActionTableEnum.filial}
          onClick={handleClick}
          variant="light"
          size="md"
          rightSection={<IconChevronDown size="12px" />}
          className={`${classes.badge} ${disabled ? classes.disabled : ''}`}
          data-cy={`${item.value}`}
        >
          <Text fz="inherit">{t('users.actionList.changeDepartment')}</Text>
        </Badge>
      </Menu.Target>
      <Menu.Dropdown>
        <Box sx={{ width: '100%' }} ref={ref}>
          <div id="scrollableDiv" style={{ overflow: 'auto' }}>
            <InfiniteScroll
              style={{ height: 'auto', maxHeight: 250, minHeight: 32 }}
              height={250}
              scrollableTarget="scrollableDiv"
              dataLength={list?.length}
              next={loadMore}
              hasMore={total > list.length}
              loader={
                <Box className={classes.infiniteScrollMoreData}>
                  <Loader size="sm" />
                </Box>
              }
              className={classes.infiniteScroll}
            >
              {list.map((item, index) => (
                <Menu.Item data-value={item.value} key={index} onClick={handleClickListItem}>
                  <Text truncate className={classes.label}>
                    {item.label}
                  </Text>
                </Menu.Item>
              ))}
            </InfiniteScroll>
          </div>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};

interface ControlContextType {
  list: ComboboxItem[];
  total: number;
  loadMore: () => void;
}
export const ControlContext = createContext<ControlContextType>({
  list: [],
  total: 0,
  loadMore: () => {
    // Default implementation does nothing
  }
});
