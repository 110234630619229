import { z } from 'zod';
import { limitsLengthRolesFields } from '@/containers/pages/roles/utils';
import { TFunction } from 'i18next';

export const validateCreateRolesSchema = (t: TFunction) =>
  z.object({
    Name: z
      .string()
      .max(limitsLengthRolesFields.Name, {
        message: t('validation.maxLengthSymbols', { count: limitsLengthRolesFields.Name })
      })
      .refine((data) => /\S/.test(data), {
        message: t('validation.enterRolesName')
      }),
    Customer: z.object({
      Id: z.number({ invalid_type_error: t('validation.required') })
    })
  });
