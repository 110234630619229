import { useCallback } from 'react';
import { IFilialCommonForm, IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { useUpdateFilialMutation } from '@/entities/admin-app/filials/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { usersApi } from '@/entities/admin-app/users/api';
import { useTranslation } from 'react-i18next';
import { universalNotification } from '@/lib/utils/notification';

export const useSaveFilial = () => {
  const { t } = useTranslation();
  const [updateFilial] = useUpdateFilialMutation();

  return useCallback(async (filial: IFilialsInfoCommon | null, values: IFilialCommonForm) => {
    try {
      const res = await updateFilial({
        ...values,
        Title: values.Title.trim(),
        Id: filial?.Id
      });

      if ('error' in res) {
        universalNotification({
          error: `${(res as any)?.error}`,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          successTitle: t('filials.successSave'),
          isSuccess: true,
          isError: false,
          error: null
        });
        clearApiCacheByTags(usersApi, ['Users']);
      }
    } catch (err: any) {
      console.warn(t('filials.errors.save'), err);
    }
  }, []);
};
