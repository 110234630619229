import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    maxHeight: 280,
    paddingInline: 24
  },
  list: {
    listStyleType: 'none',
    padding: 0
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    paddingTop: 10,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: theme.colors.backgroundGray['6']
  }
}));
