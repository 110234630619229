import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  divider: {
    margin: theme.spacing.sm,
    borderTopColor: theme.colors.brandGray[1]
  },

  checkbox: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: theme.fontSizes.sm,
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    borderRadius: '8px',
    width: '100%',
    cursor: 'pointer',

    '& input, label': {
      cursor: 'pointer'
    },

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[7],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[2],
        color: theme.black
      }
    }
  },

  checkboxLastItem: {
    marginBottom: theme.spacing.xl
  },

  buttonChange: {
    width: '100%',
    position: 'absolute',
    bottom: '0px'
  },

  radio: {
    '& .mantine-Radio-label': {
      fontSize: '14px'
    }
  }
}));
