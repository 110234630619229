import { Box } from '@mantine/core';
import { Dispatch, FC, SetStateAction } from 'react';
import { IListItem } from '@/types';
import { Groups } from './groups';
import { useStyles } from './styles';

interface ISetParentGroupProps {
  setSelectedGroup: (value: IListItem) => void;
  selectedGroup: IListItem;
  setActiveModal: Dispatch<SetStateAction<string>>;
}

export const SetParentGroup: FC<ISetParentGroupProps> = ({
  setSelectedGroup,
  selectedGroup,
  setActiveModal
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Groups
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        setActiveModal={setActiveModal}
      />
    </Box>
  );
};
