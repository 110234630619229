import { Box, Button, Container, Flex, Space, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const ErrorBoundaryPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Container fluid h="100vh" className={classes.root}>
      <Box className={classes.inner}>
        <div className={classes.label}>500</div>
        <Flex className={classes.content}>
          <Title className={classes.title}>{t('errors.oups')}</Title>
          <Title className={classes.title}>{t('errors.somethingWentWrong')}</Title>
          <Space h="md" />

          <Button onClick={() => window.location.replace('/')} size="md">
            {t('refresh')}
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};
export default ErrorBoundaryPage;
