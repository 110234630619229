import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { IFilterItem, IFullResponse, /*IQueryTable,*/ IQueryRolesTable } from '@/types/api';
import { BASE_URL } from '../constants';
import {
  DefaultFilter,
  DefaultFiltersResponse,
  IFilterItemsRequest,
  IFilterItemsResponse,
  IRolesData,
  IRolesTableData
} from './types';

const tagTypes = ['Roles', 'Add Roles', 'Filters', 'RolesList', 'DefaultFilters', 'RoleInfo'];

export const rolesApi = createApi({
  reducerPath: 'api/roles',
  baseQuery: customBaseQuery(`${BASE_URL}/v2/roles/`),
  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    // [REQ]-[POST]-(api/v2/roles/list)
    getRoles: build.query<IRolesData, IQueryRolesTable>({
      query: (payload?: IQueryRolesTable) => ({
        url: `list`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IRolesData>) => response.Response.Data,
      providesTags: ['Roles']
    }),

    // [REQ]-[GET]-(api/v2/roles/filters/default?count=10)
    getDefaultRolesFilters: build.query<DefaultFilter[], number>({
      query: (count: number) => ({
        url: `filters/default?count=${count}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<DefaultFiltersResponse>) =>
        response.Response.Data.DefaultFilters,
      providesTags: ['DefaultFilters']
    }),

    // [REQ]-[POST]-(api/v2/roles/filter)
    getFilters: build.query<IFilterItem[], IFilterItemsRequest>({
      query: (payload: IFilterItemsRequest) => ({
        url: ``,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IFilterItemsResponse>) => {
        return response.Response.Data.FilterItems;
      }
    }),

    getFilterListItems: build.query<IFilterItemsResponse, IFilterItemsRequest>({
      query: (payload: IFilterItemsRequest) => ({
        url: `filter`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IFilterItemsResponse>) => {
        return response.Response.Data;
      }
    }),

    // [REQ]-[DELETE]-(api/v2/roles?id=108)
    deleteRoles: build.mutation<void, number>({
      query: (id: number) => ({
        url: `?id=${id}`,
        method: 'delete'
      }),
      invalidatesTags: ['Roles']
    }),

    // [REQ]-[POST]-(api/v2/roles)
    addRoles: build.mutation<IRolesTableData, FormData>({
      query: (payload) => ({
        url: `/`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IRolesTableData>) => response.Response.Data,
      invalidatesTags: ['Roles']
    }),

    // [REQ]-[DELETE]-(api/v2/roles?id=2)
    getRolesInfoCommon: build.query<IRolesTableData, number>({
      query: (id: number) => ({
        url: `?id=${id}`
      }),
      transformResponse: (response: IFullResponse<IRolesTableData>) => response.Response.Data,
      providesTags: ['RoleInfo']
    }),

    editRoles: build.mutation<IRolesTableData, FormData>({
      query(payload) {
        return {
          url: '/',
          method: 'PUT',
          body: { ...payload }
        };
      },

      transformResponse: (response: IFullResponse<IRolesTableData>) => response.Response.Data,
      invalidatesTags: ['Roles', 'RoleInfo']
    })
  })
});

export const {
  useGetRolesQuery,
  useAddRolesMutation,
  useEditRolesMutation,
  useLazyGetRolesQuery,
  useDeleteRolesMutation,
  useGetFiltersQuery,
  useGetDefaultRolesFiltersQuery,
  useGetFilterListItemsQuery,
  useGetRolesInfoCommonQuery
} = rolesApi;
