import { MutableRefObject, useEffect } from 'react';
import { IRowData } from '@components/simple-table/types';
import { useActions } from '@hooks/redux/action';
import { IStatisticsInitialFilters } from '@/types/enums/statistics';

interface IUseHandleAuthActions {
  scrollRef: MutableRefObject<any>;
  search: string;
  rowData?: IRowData[];
  authFilters: IStatisticsInitialFilters;
}

export const useHandleAuthActions = ({
  scrollRef,
  search,
  rowData,
  authFilters
}: IUseHandleAuthActions) => {
  const { setAuthActionsFilters } = useActions();

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
    }

    setAuthActionsFilters({
      ...authFilters,
      Query: search,
      Offset: 0
    });
  }, [search]);

  useEffect(() => {
    if (rowData?.length) {
      setAuthActionsFilters({
        ...authFilters
      });
    }
  }, [rowData]);

  useEffect(() => {
    scrollRef?.current?.el?.scrollTo(0, 0);
  }, [authFilters]);
};
