import { FC, useContext, useEffect } from 'react';
import { EditUserInfoContext } from '../../../index';
import { Button, LoadingOverlay } from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { IAdditionalEmailCredentials } from '@/entities/admin-app/user';
import { validateAdditionalEmailScheme } from '@/containers/pages/user/schemes/validate';
import { ProfileUpdateTypeEnum, ProfileSectionEnum } from '@/types/enums/users';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { useTranslation } from 'react-i18next';
import { TextFieldInput } from '@components/fields/text-field-input';

const AdditionalEmailCredentials: FC = () => {
  const { t } = useTranslation();
  const { currentId, userInfoCommon } = useContext(EditUserInfoContext);
  const { Credentials } = userInfoCommon;

  const { saveUpdate, isLoading } = useUpdateInfo();

  const additionalEmailCredentialsForm = useForm<IAdditionalEmailCredentials>({
    initialValues: {
      AdditionalEmail: '',
      Email: ''
    },
    validateInputOnChange: true,
    validate: (values: IAdditionalEmailCredentials): FormErrors =>
      getValidateErrors(validateAdditionalEmailScheme(t), values)
  });

  const errors = additionalEmailCredentialsForm.errors;
  const isDirtyForm = additionalEmailCredentialsForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    AdditionalEmail: Credentials?.AdditionalEmail || '',
    Email: Credentials?.Email || ''
  };

  useEffect(() => {
    additionalEmailCredentialsForm.setValues(fromData);
    additionalEmailCredentialsForm.resetDirty(fromData);
  }, [Credentials.AdditionalEmail, Credentials.Email]);

  const handleSubmit = async (values: IAdditionalEmailCredentials) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.AdditionalEmail,
      Data: {
        [ProfileSectionEnum.Credentials]: { AdditionalEmail: values.AdditionalEmail },
        Id: currentId
      }
    });
    additionalEmailCredentialsForm.setValues(fromData);
    additionalEmailCredentialsForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={additionalEmailCredentialsForm.onSubmit(handleSubmit)}>
      <TextFieldInput
        form={additionalEmailCredentialsForm}
        fieldName="AdditionalEmail"
        label={t('profile.backupEmail')}
      />

      <Button
        type="submit"
        size="sm"
        radius={8}
        disabled={isDisabled}
        mt="md"
        style={{ padding: '0 20px' }}
      >
        {t('profile.save')}
      </Button>
      <LoadingOverlay visible={isLoading} />
    </form>
  );
};

export default AdditionalEmailCredentials;
