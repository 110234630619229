import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../../../../../components/modal';
import { ROUTES } from '../../../../../routes/constants';
import { LinkForm } from './components/form';

export const UserLink = () => {
  const navigate = useNavigate();

  const { id = '' } = useParams();

  const handleClose = () => navigate('..');
  const handleBackClick = () => navigate(ROUTES.users.links.fullPath);

  const title = id ? 'Редактирование ссылки' : 'Создание ссылки';

  return (
    <Modal opened title={title} onClose={handleClose} onBackClick={handleBackClick}>
      <LinkForm id={id} />
    </Modal>
  );
};
