import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  content: {
    marginTop: theme.spacing.md,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  }
}));
