import { Box, LoadingOverlay } from '@mantine/core';
import { RemoveScroll, ScrollArea } from '@mantine/core';
import React, { useState } from 'react';
import { ILicense, ILicenseCommon } from '@/entities/admin-app/activations';
import { useActivateLicense } from '@/containers/pages/activations/hooks/useActivateLicense';
import { useDeactivateLicense } from '@/containers/pages/activations/hooks/useDeactivateLicense';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@assets/icons';
import { Search } from '@components/search/search-input';
import { useStyles } from './styles';
import { LicensesList } from '@/containers/pages/activations/components/licenses/licensesList';
import { useGetLicenceListQuery } from '@/entities/admin-app/activations/api';
export const Licenses = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { data } = useGetLicenceListQuery();

  console.log('data ===> ', data);
  // const [currentPage, setCurrentPage] = useState(0);
  // const [currentActivatedPage, setActivatedCurrentPage] = useState(0);
  // const [value, setValue] = useState<string>('Все');
  const [search, setSearch] = useState('');
  const activateLicense = useActivateLicense();
  const deactivateLicense = useDeactivateLicense();
  // useEffect(() => {
  //   if (query === '') {
  //     dispatch(getDevicesThunk({ query: '', currentPage }));
  //   }
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, currentPage]);
  //
  // useEffect(() => {
  //   dispatch(getActivativatedDevicesThunk(currentActivatedPage));
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, currentActivatedPage]);
  // useEffect(() => {
  //   let getData: any = () => {};
  //   if (query) {
  //     getData = setTimeout(() => {
  //       dispatch(searchDevicesByHostNameThunk({ query, currentPage: 0 }));
  //     }, 500);
  //   }
  //
  //   return () => clearTimeout(getData);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, query, currentPage]);
  //
  // useEffect(() => {
  //   if (deactivatedListState === 'pending') {
  //     toggleLoading(true);
  //   }
  //   if (deactivatedListState === 'fulfilled') {
  //     toggleLoading(false);
  //   }
  //   if (deactivatedListState === 'rejected') {
  //     toggleLoading(false);
  //   }
  // }, [deactivatedListState]);
  // useEffect(() => {
  //   if (deactivateDeviceState === 'pending' || activateDeviceState === 'pending') {
  //     toggleLoading(true);
  //   }
  //   if (deactivateDeviceState === 'fulfilled' || activateDeviceState === 'fulfilled') {
  //     toggleLoading(false);
  //     dispatch(getActivativatedDevicesThunk(currentActivatedPage));
  //     dispatch(getDevicesThunk({ query: '', currentPage }));
  //   }
  //   if (deactivateDeviceState === 'rejected' || activateDeviceState === 'rejected') {
  //     toggleLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deactivateDeviceState, activateDeviceState]);

  // const activateDevice = (device: ApiDevices.Device) => {
  //   try {
  //     dispatch(activateDeviceThunk(device));
  //   } catch (error) {
  //     console.log('---------err', error);
  //   }
  // };
  //
  // const deactivateDevice = (device: ApiDevices.Device) => {
  //   dispatch(deactivateDevicesThunk(device));
  // };
  //
  // const deleteDevice = (device: ApiDevices.Device) => {
  //   dispatch(deleteDeviceThunk(device.id));
  // };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.currentTarget;
  //   if (value?.length > 255) {
  //     return;
  //   }
  //   if (value === '') {
  //     dispatch(getDevicesThunk({ query: '', currentPage }));
  //   }
  //   setCurrentQuery(value);
  // };

  // const linkClick = async (hash: string) => {
  //   try {
  //     // Navigator clipboard api needs https
  //     if (navigator.clipboard && window.isSecureContext) {
  //       await navigator.clipboard.writeText(hash);
  //     } else {
  //       // Using the 'out of viewport hidden text area' trick for http/local
  //       const textArea = document.createElement('textarea');
  //       textArea.value = hash;
  //       textArea.style.position = 'absolute';
  //       textArea.style.left = '-999999px';
  //
  //       document.body.prepend(textArea);
  //       textArea.select();
  //
  //       try {
  //         document.execCommand('copy');
  //       } catch (error) {
  //         console.error(error);
  //       } finally {
  //         textArea.remove();
  //       }
  //     }
  //     console.log('Copied!');
  //     notifications.show({
  //       title: 'Скопированно в буфер обмена!',
  //       message: '',
  //       color: 'green'
  //     });
  //   } catch (err) {
  //     console.log('Failed to copy!', err);
  //   }
  // };

  const handleClickLink = (link: string) => {
    console.log('link ---> ', link);
  };

  const handleActivateLicense = async (license: ILicense) => {
    const data: ILicenseCommon = {
      installationGuid: license?.InstallationGuid,
      machineHostname: license?.MachineHostname,
      machineID: license?.MachineId,
      machineIP: license?.MachineIp,
      operatingSystem: license?.OperatingSystem,
      productType: license?.ProductType,
      productVersion: license?.ProductVersion,
      subscriptionId: license?.SubscriptionId
    };
    await activateLicense(data);
    console.log('activateDevice', license);
  };
  const handleDeactivateLicense = async (license: ILicense) => {
    await deactivateLicense(license.Id);
    console.log('deactivateDevice', license);
  };
  const deleteDevice = (id: any) => {
    console.log('deleteDevice', id);
  };
  const loading = false;

  return (
    <Box className={classes.container}>
      <Box className={classes.searchWrapper}>
        <Search
          setSearch={setSearch}
          data-cy="licenseSearch"
          icon={<SearchIcon />}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('license.searchByPC')}
        />
      </Box>

      <ScrollArea className={RemoveScroll.classNames.zeroRight}>
        <LoadingOverlay visible={loading} />

        {/*{(value === 'Неактивные' || value === 'Все') && (*/}
        <LicensesList
          label={t('license.deactivated')}
          data={data || []}
          pageMeta={{}}
          linkClick={handleClickLink}
          setCurrentPage={() => console.log('tralala')}
          actionButton={handleActivateLicense}
          deleteDevice={deleteDevice}
        />
        {/*)}*/}

        {/*{query === '' && (value === 'Активные' || value === 'Все') && (*/}
        <LicensesList
          label={t('license.activated')}
          data={data || []}
          pageMeta={{}}
          linkClick={handleClickLink}
          setCurrentPage={() => console.log('tralala')}
          actionButton={handleDeactivateLicense}
          deleteDevice={deleteDevice}
          isActiveList={true}
        />
        {/*)}*/}
      </ScrollArea>
    </Box>
  );
};
