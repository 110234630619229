import { FC, ReactNode, useState } from 'react';
import { Box, UnstyledButton, Group, Text, Collapse, Badge, useDirection } from '@mantine/core';
import { IconBackspace, IconChevronUp } from '@tabler/icons';

import { IComponentDefaultProps } from '@/types';
import { IFiltersEnumType } from '@/types/enums';

import { themeColors } from '@/theme/colors';

import { useStyles } from './styles';
import { getFilterAttributeByKey } from '@/containers/pages/users/utils';

interface ISectionTemplateProps extends IComponentDefaultProps {
  hideCollapse?: boolean;
  label: string | ReactNode;
  handleFilterReset: (value: number) => void;
  activeIds?: number | null;
  isActive?: boolean;
  type: IFiltersEnumType;
}

export const FilterTemplate: FC<ISectionTemplateProps> = ({
  label,
  hideCollapse,
  activeIds,
  handleFilterReset,
  type,
  children,
  isActive
}) => {
  const { dir } = useDirection();

  const { classes } = useStyles();
  const [opened, setOpened] = useState<boolean>(false);

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleFilterReset(type);
  };
  return (
    <Box className={classes.root}>
      {hideCollapse ? (
        children
      ) : (
        <>
          <UnstyledButton
            onClick={() => setOpened(!opened)}
            className={classes.controlButton}
            data-cy={getFilterAttributeByKey(type)}
          >
            <Group className={classes.group}>
              <Box className={classes.badgeContainer}>
                <Text fz="sm" className={classes.leftContentText}>
                  {label}
                </Text>
                {activeIds && activeIds > 0 ? (
                  <Badge className={classes.badge} variant="filled">
                    {activeIds}
                  </Badge>
                ) : isActive ? (
                  <Badge className={classes.withoutNumber} variant="filled" />
                ) : null}
              </Box>

              <Box className={classes.controlsContainer}>
                {activeIds || isActive ? (
                  <IconBackspace
                    size={20}
                    color={themeColors.brandGray[0]}
                    onClick={(e) => handleReset(e)}
                  />
                ) : null}

                <IconChevronUp
                  className={classes.chevron}
                  size={24}
                  stroke={1.5}
                  style={{
                    transform: !opened ? `rotate(${dir === 'rtl' ? -180 : 180}deg)` : 'none'
                  }}
                />
              </Box>
            </Group>
          </UnstyledButton>

          <Collapse sx={{ width: '100%', marginTop: '8px' }} in={opened}>
            {children}
          </Collapse>
        </>
      )}
    </Box>
  );
};
