import { FC, useEffect } from 'react';
import { useForm, zodResolver } from '@mantine/form';
import { ActionIcon, Button, Flex, Radio, Text, TextInput } from '@mantine/core';

import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { useStyles } from '../../../../../styles';
import { tranformAdrressesToForm, tranformAdrresses } from '../../utils';
import { Address } from '../../types';
import { validateSchemeIpSecurity } from '../../validate';

export const IPSecurityUsers: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { filial } = useAppSelector((state) => state.filials);

  const saveSettings = useSaveSettings();
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      addresses: [] as Address[],
      enabled: false
    },

    validate: zodResolver(validateSchemeIpSecurity(t))
  });

  const isDisabledSubmit =
    Object.keys(form.errors).length !== 0 ||
    !form.isDirty() ||
    (!form.values.addresses.length && form.values.enabled);

  useEffect(() => {
    const { UserIp } = filial?.Settings || {};

    if (!UserIp) {
      return;
    }

    const formData = {
      addresses: tranformAdrressesToForm(UserIp.IpAdresses || []),
      enabled: UserIp.Enabled
    };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = () => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.UserIp, {
      UserIp: {
        Enabled: form.values.enabled,
        IpAdresses: tranformAdrresses(form.values.addresses)
      }
    });
  };

  return (
    <Flex
      className={classes.section}
      align="flex-start"
      direction="column"
      data-section={FilialInfoPanelsEnum.ipSecurityUsers}
    >
      <Text className={classes.title}> {t('filialSecurity.ipSecurityForUsers')}</Text>

      <Radio
        className={classes.radio}
        size="xs"
        checked={!form.values.enabled}
        onChange={() => {
          form.setValues({ enabled: false, addresses: [] });
        }}
        label={t('disable')}
      />
      <Radio
        className={classes.radio}
        size="xs"
        checked={form.values.enabled}
        onChange={() => form.setValues({ enabled: true })}
        label={t('enable')}
      />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {form.values.enabled && (
          <Flex align="flex-start" direction="column">
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => {
                form.insertListItem('addresses', { address: '' });
              }}
            >
              {t('filialSecurity.addAllowedIpAddress')}
            </Button>

            {form.values.addresses.map((item, index) => (
              <Flex key={index} align="flex-start" mt="sm">
                <TextInput
                  style={{ width: '220px' }}
                  className={classes.textFieldInput}
                  id={item.address}
                  {...form.getInputProps(`addresses.${index}.address`)}
                />
                <ActionIcon
                  className="action-icon"
                  onClick={() => form.removeListItem('addresses', index)}
                  ml="sm"
                  mt={5}
                >
                  <IconTrash
                    size="1.3rem"
                    color="#667085"
                    stroke={1.5}
                    style={{ pointerEvents: 'none' }}
                  />
                </ActionIcon>
              </Flex>
            ))}
          </Flex>
        )}

        <Text className={classes.description} mt="sm" mb="md">
          {t('filialSecurity.ipSecurityUsersDescription')}
        </Text>
        <Button disabled={isDisabledSubmit} size="sm" radius={8} mr="sm" type="submit">
          {t('save')}
        </Button>
      </form>
    </Flex>
  );
};
