import { NotificationNavIcon, RocketIcon, SettingsIcon } from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import { useTranslation } from 'react-i18next';
import { isSaaS } from '@/lib/utils/access';
import { isFeature } from '@utils/feature';
import { IGlobalSettings } from '@/entities/admin-app/auth';

interface IMenuProps {
  Settings?: IGlobalSettings;
}
export const useGetNavMenuItems = ({ Settings }: IMenuProps) => {
  const { t } = useTranslation();

  const commonSettingsMenuItems: INavbarMenu[] = [
    {
      label: t('filials.integrations'),
      icon: SettingsIcon,
      value: ROUTES.commonSettings.pages.integrations
    }

    // {
    //   label: 'Кастомизация',
    //   icon: CustomisationIcon,
    //   value: ROUTES.commonSettings.pages.customisation
    // }
  ];

  if (isSaaS(Settings)) {
    commonSettingsMenuItems.push({
      label: t('commonSettings.notifications'),
      icon: NotificationNavIcon,
      value: ROUTES.commonSettings.pages.notifications
    });
  }
  if (isFeature()) {
    commonSettingsMenuItems.push({
      label: t('filials.branding'),
      icon: RocketIcon,
      value: ROUTES.commonSettings.pages.branding
    });
  }
  return commonSettingsMenuItems;
};
