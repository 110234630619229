import { FC, useContext, useEffect } from 'react';
import { EditUserInfoContext } from '../../../index';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { LinkedListEmails } from '@/containers/pages/user/components/sections/linked-emails';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { ILinkedEmail } from '@/entities/admin-app/email';

const MailAccount: FC = () => {
  const { t } = useTranslation();
  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);
  const { LinkedEmail, Customer } = userInfoCommon;

  const mailForm = useForm<ILinkedEmail[]>({
    initialValues: []
  });

  useEffect(() => {
    mailForm.setValues(LinkedEmail);
    mailForm.resetDirty(LinkedEmail);
  }, [LinkedEmail]);

  return (
    <SectionTemplate title={t('profile.mailModuleAccount')}>
      <LinkedListEmails
        listLinkedEmails={LinkedEmail}
        form={mailForm}
        sectionFormKey="LinkedEmail"
        currentId={currentId}
        filialId={Customer?.Id}
        editUser
      />
    </SectionTemplate>
  );
};

export default MailAccount;
