import { FC, ReactNode, SyntheticEvent } from 'react';
import {
  FloatingPosition,
  Box,
  Button,
  Checkbox,
  Divider,
  Menu,
  Radio,
  Text,
  Stack,
  BoxProps
} from '@mantine/core';
import { useStyles } from './styles';
import { IComponentDefaultProps, IListItem } from '@/types';
import { ScrollContainer } from '../scroll-container';

export enum MenuItemTypeEnum {
  item = 'item',
  checkbox = 'checkbox',
  radio = 'radio'
}

interface IDropdownMenu extends IComponentDefaultProps {
  width?: string | number;
  height?: string | number;
  list: IListItem[];
  marginLeft?: number;
  marginRight?: number;
  // eslint-disable-next-line
  // @ts-ignore
  classNames?: BoxProps['classNames'];
  handleClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  position?: FloatingPosition;
  menuItemType?: MenuItemTypeEnum;
  handleChangeCheckbox?: (event: SyntheticEvent<HTMLInputElement>) => void;
  scrollHeight?: number | string;
}

export const DropdownMenu: FC<IDropdownMenu> = ({
  width = 200,
  height,
  scrollHeight,
  list,
  marginLeft = 0,
  marginRight = 0,
  classNames,
  handleClick,
  position,
  menuItemType = MenuItemTypeEnum.item,
  handleChangeCheckbox,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const widthMenu =
    typeof width === 'string' ? width : `calc(${width}px - (${marginLeft}px + ${marginRight}px))`;

  const getChildrenItem = (item: IListItem, index: number): ReactNode => {
    const key = `${menuItemType}-${index}`;
    switch (menuItemType) {
      case MenuItemTypeEnum.item:
        return (
          <Box key={key}>
            <Menu.Item
              leftSection={item.icon}
              data-value={item.value}
              key={index}
              onClick={handleClick}
            >
              {item.label}
            </Menu.Item>
          </Box>
        );
      case MenuItemTypeEnum.checkbox:
        return (
          <Checkbox
            key={key}
            data-checkbox={item.value}
            className={cx(classes.checkbox, {
              [classes.checkboxLastItem]: index === list.length - 1
            })}
            onChange={handleChangeCheckbox}
            readOnly
            label={<Text>{item.label}</Text>}
          />
        );
      case MenuItemTypeEnum.radio:
        return (
          <Menu.Item data-value={item.value} key={key} onClick={handleClick}>
            <Radio
              size="xs"
              defaultChecked={item.checked}
              label={item.label}
              className={classes.radio}
            />
          </Menu.Item>
        );
      default:
        break;
    }
  };

  return (
    <Menu classNames={classNames} shadow="md" width={widthMenu} zIndex={400} position={position}>
      <Menu.Target>{props?.children}</Menu.Target>

      <Stack></Stack>

      <Menu.Dropdown sx={{ height: height ?? 'auto' }}>
        <ScrollContainer style={{ height: scrollHeight ?? 'auto' }}>
          {list.map((item: IListItem, index: number) => {
            if (item.value === 'divider') {
              return <Divider key={index} className={item.className} />;
            } else {
              return getChildrenItem(item, index);
            }
          })}
          {menuItemType === MenuItemTypeEnum.checkbox && (
            <Button className={classes.buttonChange} size="xs" onClick={handleClick}>
              Изменить
            </Button>
          )}
        </ScrollContainer>
      </Menu.Dropdown>
    </Menu>
  );
};
