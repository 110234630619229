import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Radio,
  Text,
  TextInput,
  PasswordInput,
  SimpleGrid,
  Checkbox,
  NumberInput,
  Textarea,
  Divider,
  Tooltip
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';

import { getValidateErrors } from '@/lib/utils/validation';

import { useStyles } from '../../../styles';
import { IconInfoCircle, IconRefresh } from '@tabler/icons';
import { ldapSettingsSync } from '@/containers/pages/filials/hooks/LdapSettingsSync';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useGetLdapSchemes } from '@/containers/pages/filials/edit/components/tabs/ldap/hooks/useGetLdapSchemes';
import { ILDAPForm } from '@/containers/pages/filials/edit/types';
import { initialLDapFormValues } from '@/containers/pages/filials/edit/consts';
const LDAPPage: FC = () => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);
  const { validateScheme } = useGetLdapSchemes();
  const saveSettings = useSaveSettings();
  const { SyncSettingsLdap, isLdapSync } = ldapSettingsSync();

  const form = useForm<ILDAPForm>({
    initialValues: initialLDapFormValues,
    validateInputOnChange: true,
    validate: (values: ILDAPForm): FormErrors => getValidateErrors(validateScheme, values)
  });

  useEffect(() => {
    const { Ldap } = filial?.Settings || {};
    if (!Ldap) {
      return;
    }

    const Mapping = Ldap?.Mapping || {};
    const GroupMapping = Ldap?.GroupMapping || {};

    const formData: ILDAPForm = {
      useLDAP: `${Ldap.Enabled}`,
      server: Ldap.Server || '',
      login: Ldap.Login || '',
      password: Ldap.Password || '',
      Port: Ldap?.Port || 0,
      Domain: Ldap?.Domain || '',
      DN: Ldap?.DN || '',
      UserFilter: Ldap?.UserFilter || '',
      UseSsl: Ldap?.UseSsl || false,
      mapping: {
        principal_name: Mapping?.PrincipalName || '',
        login: Mapping?.Login || '',
        first_name: Mapping?.FirstName || '',
        second_name: Mapping?.SecondName || '',
        middle_name: Mapping?.MiddleName || '',
        email: Mapping?.Email || '',
        phone: Mapping?.Phone || '',
        birthdate: Mapping?.BirthDate || '',
        position: Mapping?.Position || '',
        organization: Mapping?.Organization || '',
        city: Mapping?.City || '',
        teamNumber: Mapping?.TeamNumber || '',
        linkEmail: Mapping?.LinkEmail || '',
        isDeleted: Mapping?.IsDeleted || ''
      },
      GroupEnabled: `${Ldap.GroupEnabled}`,
      GroupDn: Ldap.GroupDn || '',
      GroupFilter: Ldap.GroupFilter || '',
      GroupMapping: {
        Description: GroupMapping?.Description || '',
        Name: GroupMapping.Name || '',
        Uid: GroupMapping.Uid || '',
        Member: GroupMapping.Member || ''
      }
    };

    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = (formValues: ILDAPForm) => {
    if (!filial) {
      return;
    }

    const enabled = formValues.useLDAP === 'true';
    const GroupExport = formValues.GroupEnabled === 'true';

    saveSettings(filial.Id, SettingsEnum.Ldap, {
      Ldap: {
        Enabled: enabled,
        Server: enabled ? formValues.server : null,
        Login: enabled ? formValues.login : null,
        Password: enabled ? formValues.password : null,
        Port: enabled ? formValues.Port : null,
        Domain: enabled ? formValues.Domain : null,
        UseSsl: enabled ? formValues.UseSsl : false,
        DN: enabled ? formValues.DN : null,
        UserFilter: enabled ? formValues.UserFilter : null,
        Mapping: {
          PrincipalName: enabled ? formValues.mapping?.principal_name : null,
          Login: enabled ? formValues.mapping?.login : null,
          FirstName: enabled ? formValues.mapping?.first_name : null,
          SecondName: enabled ? formValues.mapping?.second_name : null,
          MiddleName: enabled ? formValues.mapping?.middle_name : null,
          Email: enabled ? formValues.mapping?.email : null,
          Phone: enabled ? formValues.mapping?.phone : null,
          BirthDate: enabled ? formValues.mapping?.birthdate : null,
          Position: enabled ? formValues.mapping?.position : null,
          Organization: enabled ? formValues.mapping?.organization : null,
          City: enabled ? formValues.mapping?.city : null,
          LinkEmail: enabled ? formValues.mapping?.linkEmail : null,
          TeamNumber: enabled ? formValues.mapping?.teamNumber : null,
          IsDeleted: enabled ? formValues.mapping?.isDeleted : null
        },
        GroupEnabled: enabled && GroupExport ? Boolean(GroupExport) : false,
        GroupDn: enabled && GroupExport ? formValues.GroupDn : null,
        GroupFilter: enabled && GroupExport ? formValues.GroupFilter : null,
        GroupMapping: {
          Uid: enabled && GroupExport ? formValues.GroupMapping?.Uid : null,
          Name: enabled && GroupExport ? formValues.GroupMapping?.Name : null,
          Description: enabled && GroupExport ? formValues.GroupMapping?.Description : null,
          Member: enabled && GroupExport ? formValues.GroupMapping?.Member : null
        }
      }
    });
  };

  const handleRefresh = async () => {
    if (filial) {
      await SyncSettingsLdap(filial?.Id);
    }
  };

  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex className={classes.section} align="flex-start" direction="column">
          <Text className={classes.title}>{t('filials.authorization.method')}</Text>

          <Radio.Group {...form.getInputProps('useLDAP')}>
            <Radio
              className={classes.radio}
              size="xs"
              label={t('filials.authorization.local')}
              value="false"
            />
            <Radio className={classes.radio} size="xs" label="LDAP" value="true" />
          </Radio.Group>

          {form.getTransformedValues().useLDAP === 'true' && (
            <>
              <Grid columns={isMobile ? 1 : 2} style={{ alignSelf: 'stretch' }} mt="md">
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('servers.server')}
                    withAsterisk
                    {...form.getInputProps('server')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('domains.domain')}
                    withAsterisk
                    {...form.getInputProps('Domain')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('filials.authorization.dn')}
                    withAsterisk
                    {...form.getInputProps('DN')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <NumberInput
                    className={classes.textFieldInput}
                    placeholder={t('servers.port')}
                    label={t('servers.port')}
                    autoComplete="off"
                    withAsterisk
                    hideControls
                    {...form.getInputProps('Port')}
                  />
                </Grid.Col>
                <SimpleGrid mt="md" cols={1} w="100%">
                  <Grid.Col span={1}>
                    <Textarea
                      className={classes.textFieldInput}
                      withAsterisk
                      {...form.getInputProps('UserFilter')}
                      label={t('filials.authorization.filter')}
                    />
                  </Grid.Col>
                </SimpleGrid>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('profile.login')}
                    withAsterisk
                    {...form.getInputProps('login')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PasswordInput
                    className={classes.textFieldInput}
                    label={t('login.password')}
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                </Grid.Col>
              </Grid>
              <SimpleGrid mt="md" cols={1}>
                <Checkbox
                  sx={{
                    '& input': {
                      cursor: 'pointer'
                    }
                  }}
                  size="sm"
                  {...form.getInputProps('UseSsl', { type: 'checkbox' })}
                  label={t('filials.authorization.ssl')}
                />
              </SimpleGrid>
              <Text className={classes.title} mb={10} mt={32}>
                {t('filials.authorization.attributes')}
              </Text>
              <Text className={classes.description} mb={16} mt={0}>
                {t('filials.authorization.description')}
              </Text>
              <Grid columns={2}>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('filials.authorization.identifier')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.principal_name')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('profile.login')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.login')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('name')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.first_name')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('profile.surName')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.second_name')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('profile.middleName')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.middle_name')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}> {t('filials.authorization.mail')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.email')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}> {t('profile.phone')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.phone')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.birthdate')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.birthdate')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('profile.position')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.position')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.organization')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.organization')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.address')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.city')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.blocked')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.isDeleted')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Flex className={classes.labelWithTooltip}>
                    {t('filials.authorization.teamNumber')}
                    <Tooltip
                      w="200px"
                      multiline={true}
                      label={t('filials.authorization.teamNumberDescription')}
                    >
                      <Box h={16}>
                        <IconInfoCircle size={16} stroke="2" color={theme.colors.brandGray[0]} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.teamNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={1} pt={0}>
                  <Flex className={classes.labelWithTooltip}>
                    {t('filials.authorization.linkEmail')}
                    <Tooltip
                      label={t('filials.authorization.linkEmailDescription')}
                      w="200px"
                      multiline={true}
                    >
                      <Box h={16}>
                        <IconInfoCircle size={16} stroke="2" color={theme.colors.brandGray[0]} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.linkEmail')}
                  />
                </Grid.Col>
              </Grid>

              <Divider
                style={{ width: '100%', marginTop: '32px', marginBottom: '16px', color: '#D0D5DD' }}
              />

              <Text className={classes.title}>{t('filials.authorization.exportGroup')}</Text>
              <Text className={classes.description} mb={16} mt={0}>
                {t('filials.authorization.exportModel')}
              </Text>

              <Radio.Group {...form.getInputProps('GroupEnabled')}>
                <Radio className={classes.radio} size="xs" label={t('disable')} value="false" />
                <Radio className={classes.radio} size="xs" label={t('enable')} value="true" />
              </Radio.Group>
              {form.getTransformedValues().GroupEnabled === 'true' && (
                <>
                  <Grid columns={isMobile ? 1 : 2} style={{ alignSelf: 'stretch' }} mt="md">
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        label={t('filials.authorization.dnGroup')}
                        withAsterisk
                        {...form.getInputProps('GroupDn')}
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        label={t('filials.authorization.filterGroup')}
                        withAsterisk
                        {...form.getInputProps('GroupFilter')}
                      />
                    </Grid.Col>
                  </Grid>
                  <Text className={classes.title} mb={10} mt={32}>
                    {t('filials.authorization.groupAttributes')}
                  </Text>
                  <Text className={classes.description} mb={16} mt={0}>
                    {t('filials.authorization.groupDescription')}
                  </Text>
                  <Grid columns={2} className={classes.groupGrid} mt="md">
                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('filials.authorization.id')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Uid')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('profile.firstName')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Name')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('filials.authorization.userId')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Member')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}> {t('filials.authorization.details')}</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Description')}
                      />
                    </Grid.Col>
                  </Grid>
                </>
              )}
            </>
          )}
          <Flex w="100%">
            <Button
              type="submit"
              size="sm"
              radius={8}
              disabled={!form.isValid() || !form.isDirty()}
              mt="md"
              mr="md"
              style={{ padding: '0 20px' }}
            >
              {t('save')}
            </Button>

            {filial?.Settings?.Ldap.Enabled ? (
              <Button
                size="sm"
                radius={8}
                color="green"
                mt="md"
                rightSection={
                  <IconRefresh size={24} className={isLdapSync ? classes.rotate : ''} />
                }
                onClick={() => handleRefresh()}
                disabled={isLdapSync}
              >
                {t('sync')}
              </Button>
            ) : null}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default LDAPPage;
