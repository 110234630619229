export enum RoleTypesEnum {
  User = 0,
  Admin = 1,
  SuperUser = 2,
  Guest = 3,
  All = 4,
  Support = 5
}

export enum UserStatusEnum {
  Ban = -2,
  Deleted = -1,
  Disabled = 0,
  Active = 1,
  Invited = 2
}

export enum PanelsEnum {
  generalInformation = 'general-information',
  additionalInformation = 'additional-information',
  fullName = 'full-name',
  authorization = 'authorization',
  linkedEmails = 'linked-emails',
  groups = 'groups',
  notes = 'notes',
  subscriptions = 'subscriptions',
  activeConnections = 'active-connections',
  dataStorageQuota = 'data-storage-quota',
  company = 'company',
  positions = 'positions',
  roles = 'roles',
  ldap = 'ldap',

  credentials = 'credentials',
  personalData = 'personal-data',
  filialData = 'filial-data',
  mailAccount = 'mail-account',
  disk = 'disk',
  status = 'status',
  login = 'login',
  email = 'email'
}
