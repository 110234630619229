import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Button, Loader, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { IListItem } from '@/types';
import { useGetRolesQuery } from '@/entities/admin-app/roles/api';
import { IRole } from '@/entities/admin-app/directories';
import { Search } from '../../../search/search-input';
import { ScrollContainer } from '../../../scroll-container';
import { GroupItem } from '../group-item';
import { useAppSelector } from '@/hooks/redux/redux';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { IFiltersEnumType } from '@/types/enums';
import { useDebounce } from '@hooks/useDebounce';
import { NoDataText } from '@/components/not-found/components/no-data-text';

import { useTranslation } from 'react-i18next';

interface Group {
  label: string;
  value: number;
  checked: boolean;
  counter: number;
  isRemovable: boolean;
}
interface ISelectGroupProps {
  setGroupId: (id: number) => void;
  setGroup?: (group: Group) => void;
  handleClose: () => void;
  filial?: IFilialsInfoCommon | null;
  SkipIds?: number[];
}

const setActiveGroup = (list: IListItem[], value: number) => {
  return list.map((item) => ({
    ...item,
    checked: item.value === value
  }));
};

export const SelectGroup: FC<ISelectGroupProps> = ({
  setGroupId,
  setGroup,
  handleClose,
  filial,
  SkipIds
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [search, setSearch] = useState<string>('');
  const debounced = useDebounce(search, 500);
  const [offsetObj, setOffset] = useState({ count: 20, offset: 0 });
  const listRef = useRef<IListItem[]>([]);

  const { filters: rolesFilter } = useAppSelector((state) => state.roles);

  const { data, isSuccess } = useGetRolesQuery({
    Count: offsetObj.count,
    Offset: offsetObj.offset,
    SortOrder: rolesFilter.SortOrder,
    SortField: rolesFilter.SortField,
    Filters: {},
    Query: debounced,
    SkipIds: SkipIds?.length ? SkipIds : [],
    FilterConditions: filial
      ? [
          {
            Type: IFiltersEnumType.FilialFilter,
            NumValues: [filial?.Id]
          }
        ]
      : []
  });

  const [listData, setListData] = useState<IListItem[]>([]);

  const selectionGroups = useMemo(() => {
    const checkedGroups = listData.filter((item) => item.checked);

    return Boolean(checkedGroups.length);
  }, [listData]);

  const handleClickGroupItem = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const value = Number(event.currentTarget.dataset.value);

    listRef.current = setActiveGroup(listRef.current, value);

    setListData((prevState) => setActiveGroup(prevState, value));
  }, []);

  const addGroups = useCallback(() => {
    if (listData.length) {
      const findCheckedGroup = listData.find((item) => item.checked);

      if (findCheckedGroup) {
        setGroupId(Number(findCheckedGroup.value));
        setGroup?.(findCheckedGroup as Group);
      }
    }
  }, [listData]);

  useEffect(() => {
    if (data?.Items.length && isSuccess) {
      const convertData = data?.Items.map((item: IRole) => ({
        label: item.Name,
        value: item.Id,
        checked: false,
        counter: item.Counter,
        isRemovable: item.IsRemovable
      }));
      const convertTotalData =
        offsetObj.offset > 0 ? [...listRef.current, ...convertData] : convertData;
      listRef.current = convertTotalData;
      setListData(convertTotalData);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      const filterSearch = listRef.current.filter((item) =>
        item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      setListData(filterSearch);
    } else {
      setListData(listRef.current);
    }
    setOffset({ count: 20, offset: 0 });
  }, [search]);

  const handleLoadMore = () => {
    const offset =
      data?.TotalCount && offsetObj.offset + 20 > data.TotalCount
        ? data.TotalCount
        : offsetObj.offset + 20;
    setOffset({ count: 20, offset: offset });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('selectGroup.searchRole')}
        />

        <ScrollContainer sx={{ height: '300px', width: '100%' }}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={listData?.length}
            next={handleLoadMore}
            height={300}
            hasMore={data ? data?.TotalCount > listData?.length : false}
            endMessage={!listData?.length && <NoDataText />}
            loader={
              <>
                {Boolean(listData?.length) && (
                  <Box className={classes.infiniteScrollMoreData}>
                    <Loader size="sm" />
                  </Box>
                )}
              </>
            }
            className={classes.infiniteScroll}
          >
            <>
              {listData?.map((item: IListItem) => {
                return (
                  <GroupItem
                    key={item.value}
                    checked={item.checked}
                    label={item.label}
                    handleChange={handleClickGroupItem}
                    value={Number(item.value)}
                    counter={item.counter}
                    withRadioButtonbox
                  />
                );
              })}
            </>
          </InfiniteScroll>
        </ScrollContainer>
      </Box>

      <Box className={classes.footer}>
        <Button size="sm" radius={8} w="49%" disabled={!selectionGroups} onClick={addGroups}>
          {t('add')}
        </Button>

        <UnstyledButton ml={8} className={classes.cancelBtn} w="49%" onClick={handleClose}>
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </Box>
  );
};
