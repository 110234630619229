import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { emailServerConnectionType } from '@/containers/pages/filials/edit/utiils';

export const useGetMailServerSchemes = (showKey: boolean) => {
  const { t } = useTranslation();
  const portSchema = z
    .number({
      required_error: t('filials.authorization.addPort'),
      invalid_type_error: t('filials.authorization.numberPort')
    })
    .positive({ message: t('errors.positive') })
    .lt(65536, { message: t('errors.maxValue') });

  const basicSchema = z.object({
    Name: z
      .string()
      .max(120, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 120 }) })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('errors.enterName')
      }),
    Domains: z
      .string({ invalid_type_error: t('validation.addDomains') })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('validation.required')
      }),
    PopServer: z
      .string()
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('errors.enterPop')
      }),
    PopPort: portSchema,
    PopConnectionType: z.nativeEnum(emailServerConnectionType),
    ImapServer: z
      .string()
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('errors.enterImap')
      }),
    ImapPort: portSchema,
    ImapConnectionType: z.nativeEnum(emailServerConnectionType),
    SmtpServer: z
      .string()
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('errors.enterSmtp')
      }),
    SmtpPort: portSchema,
    SmtpConnectionType: z.nativeEnum(emailServerConnectionType),
    Json: z.string().nullable(),
    CustomerId: z.number().nullable().optional()
  });

  const noSSHSchema = basicSchema.extend({
    useSsH: z.literal(false),

    Integration: z
      .object({
        Ssh: z.object({})
      })
      .nullable()
  });

  const SSHSchema = basicSchema.extend({
    useSsH: z.literal(true),
    Integration: z.object({
      Ssh: z.object({
        Server: z
          .string()
          .refine((value) => value.trim().length > 0, {
            message: t('validation.emptyInput')
          })
          .refine((value) => value.length > 0, {
            message: t('servers.addSshServer')
          }),
        User: z
          .string()
          .refine((value) => value.trim().length > 0, {
            message: t('validation.emptyInput')
          })
          .refine((value) => value.length > 0, {
            message: t('servers.addLogin')
          }),
        Port: portSchema,
        PrivateKeyBase64: z.string().refine(
          (val) => {
            return showKey ? val.length > 1 : true;
          },
          { message: t('servers.addKey') }
        )
      })
    })
  });

  const validateScheme = z.union([noSSHSchema, SSHSchema]);

  return { validateScheme };
};
