import { FC, useCallback } from 'react';
import { ActionIcon, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IconArrowLeft } from '@tabler/icons';
import { PanelsEnum } from '@/types/enums/roles';
import { useActions } from '@/hooks/redux/action';

interface IHeaderPanelProps {
  title: string;
  activePanel?: PanelsEnum;
  rightIcon?: boolean;
}

export const HeaderPanel: FC<IHeaderPanelProps> = ({ title }) => {
  const { classes } = useStyles();
  const { setSelectedPanel } = useActions();

  const goBack = useCallback(() => {
    setSelectedPanel(PanelsEnum.generalInformation);
  }, []);

  return (
    <Box className={classes.headerPanel}>
      <Box className={classes.container}>
        <ActionIcon onClick={goBack} size="md">
          <IconArrowLeft color="#667085" />
        </ActionIcon>
        <Text className={classes.title}>{title}</Text>
      </Box>
    </Box>
  );
};
