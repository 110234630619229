import { Drawer } from '@mantine/core';
import { FC } from 'react';
import { useStyles } from './styles';
import { useEffect } from 'react';

interface IFiltersModalProps {
  list: any;
  open: boolean;
  setOpen: () => void;
}

export const UsersFiltersModal: FC<IFiltersModalProps> = ({ open, setOpen, list }) => {
  useEffect(() => {
    return () => {
      setOpen();
    };
  }, []);

  const { classes } = useStyles();

  return (
    <Drawer
      zIndex={1100}
      opened={open}
      onClose={setOpen}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
      overlayProps={{ opacity: 0 }}
    >
      {list}
    </Drawer>
  );
};
