import { useCallback } from 'react';
import { IFilialSettings } from '@/entities/admin-app/filials';
import {
  useLazyGetFilialsInfoCommonQuery,
  useUpdateFilialSettingsMutation
} from '@/entities/admin-app/filials/api';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useActions } from '@hooks/redux/action';
import { useTranslation } from 'react-i18next';
import { universalNotification } from '@/lib/utils/notification';

export const useSaveSettings = () => {
  const { t } = useTranslation();
  const [updateSettings] = useUpdateFilialSettingsMutation();
  const { setFilial } = useActions();
  const [getUpdatedValue, { isSuccess, data: updatedData }] = useLazyGetFilialsInfoCommonQuery();
  return useCallback(async (id: number, type: SettingsEnum, values: Partial<IFilialSettings>) => {
    try {
      const res = await updateSettings({
        id,
        type,
        ...values
      });
      if ('error' in res) {
        universalNotification({
          error: `${(res as any)?.error}`,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          successTitle: t('filials.successSave'),
          isSuccess: true,
          isError: false,
          error: null
        });
        getUpdatedValue({ id, extended: true });
        if (id && isSuccess && updatedData) {
          setFilial(updatedData);
        }
      }
    } catch (err: any) {
      console.warn(t('filials.errors.save'), err);
    }
  }, []);
};
