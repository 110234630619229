export function extractErrorCode(errorResponse: any): number | null {
  if (errorResponse?.data && errorResponse?.data?.Code !== undefined) {
    return errorResponse?.data?.Code;
  } else if (errorResponse?.data && errorResponse?.data?.Response) {
    const response = errorResponse?.data?.Response || {};
    const data = response?.Data || {};
    return data?.Code || null;
  }
  return null;
}
