import React, { FC, useContext } from 'react';
import { RolesInfoMainContext } from '..';
import { DrawerHeader, PhotoInfo, BaseInfo } from '../../components';
import { UserRoles } from '../../components/users-role';

export const HomePanel: FC = () => {
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);

  return (
    <>
      <DrawerHeader />
      <PhotoInfo rolesInfoCommon={rolesInfoCommon} />
      <BaseInfo />
      <UserRoles />
    </>
  );
};
