export const redirectIfAuth = () => {
  const requestParams = new URL(document.location.href).searchParams;
  const redirect_uri = requestParams.get('redirect_uri');
  if (redirect_uri) {
    const redirectUrl =
      '/auth.html?response_type=' +
      requestParams.get('response_type') +
      '&client_id=' +
      requestParams.get('client_id') +
      '&scope=' +
      requestParams.get('scope') +
      '&state=' +
      requestParams.get('state') +
      '&redirect_uri=' +
      requestParams.get('redirect_uri');
    //add params for auth page if require

    window.location.href = redirectUrl;
    return;
  }
};
