import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryTable } from '../../../types/api';
import { IRolesInitialFilters, PanelsEnum } from '../../../types/enums/roles';
import { IRolesTableData, IRolesData } from './types';

interface IUsers {
  items: IRolesTableData[];
  total: number;
  filters: IQueryTable<IRolesInitialFilters>;
  selectedRolesId: number;
  selected: number[];
  selectedPanel: PanelsEnum;
}

const initialState: IUsers = {
  items: [],
  total: 0,
  filters: {
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: '',
    FilterConditions: []
  },
  selectedRolesId: 0, // for show UserInfoMain when selectedRolesId !== 0
  selected: [],
  selectedPanel: PanelsEnum.generalInformation
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<IRolesData>) {
      state.items = action.payload.Items;
      state.total = action.payload.TotalCount;
    },
    setRolesTableFilters(state, action: PayloadAction<IQueryTable<IRolesInitialFilters>>) {
      state.filters = action.payload;
    },
    setSelectedRolesId(state, action: PayloadAction<number>) {
      state.selectedRolesId = action.payload;
    },
    setSelectedRolesIds(state, action: PayloadAction<number>) {
      state.selected.includes(action.payload)
        ? (state.selected = state.selected.filter((state) => state !== action.payload))
        : (state.selected = [...state.selected, action.payload]);
    },
    setSelectedAllRoles(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    setSelectedPanel(state, action: PayloadAction<PanelsEnum>) {
      state.selectedPanel = action.payload;
    },
    clearRolesSelected(state) {
      state.selected = [];
    }
  }
});

export const rolesActions = rolesSlice.actions;
export const rolesReducer = rolesSlice.reducer;
