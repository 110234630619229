import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  select: {
    '& .mantine-Select-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  }
}));
