import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  itemName: {
    cursor: 'pointer',
    fontSize: '14px',
    color: '#848E98',
    '&:hover': {
      color: '#339AF0',
      textDecoration: 'underline'
    }
  }
}));
