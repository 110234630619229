import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${theme.spacing.sm} ${theme.spacing.sm} 0px ${theme.spacing.md}`,
    borderRight: '1px solid #F2F4F7',
    [utils.largerThan('xs')]: {
      width: '40%'
    }
  },
  usersButton: {
    border: 'none'
  },

  usersButtonLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },

  control: {
    width: '100%',
    marginTop: theme.spacing.sm,
    fontSize: '12px',
    fontWeight: 600
  },

  search: {
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.md,
    '& input': {
      border: '1px solid #EAECF0'
    }
  },

  selectAll: {
    marginBottom: theme.spacing.sm,
    cursor: 'pointer'
  },

  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emptyListText: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 400
  }
}));
