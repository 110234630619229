import { useCallback } from 'react';
import { universalNotification } from '@/lib/utils/notification';
import { filialsApi, useDeleteFilialsMutation } from '@/entities/admin-app/filials/api';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { clearApiCacheByTags } from '@/entities/helpers';
import { rolesApi } from '@/entities/admin-app/roles/api';
import { useTranslation } from 'react-i18next';

export const useDeleteFilial = () => {
  const { t } = useTranslation();
  const { setFilialsTableFilters } = useActions();
  const { filters } = useAppSelector((state) => state.filials);

  const [deleteFilialMutation] = useDeleteFilialsMutation();

  const deleteFilial = useCallback(
    async (id: number) => {
      try {
        const res = await deleteFilialMutation(id);
        if (!(res as any).error) {
          setFilialsTableFilters({
            ...filters,
            Offset: 0
          });
          universalNotification({
            successTitle: t('filials.successDelete'),
            isSuccess: true,
            isError: false,
            error: null
          });
          clearApiCacheByTags(filialsApi, ['Filials']);
          clearApiCacheByTags(rolesApi, ['Roles']);
        } else {
          universalNotification({
            error: (res as any)?.error,
            isError: true,
            isSuccess: false
          });
        }
      } catch (err: any) {
        console.warn(t('filials.errors.delete'), err);
      }
    },
    [filters]
  );

  return { deleteFilial };
};
