import React, { useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  SimpleGrid,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { EditIcon, GlobeIcon } from '@assets/icons';
import { IconCircleX } from '@tabler/icons';
import { useStyles } from '../../../styles';
import { useLazyDeleteEmailServerQuery } from '@/entities/admin-app/email/api';
import { MailServerForm } from '@/containers/pages/filials/edit/components/tabs/mail-servers/components/serverForm';
import { useDisclosure } from '@mantine/hooks';
import Domains from './components/domains';
import { useTranslation } from 'react-i18next';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';
import OverflowTip from '@/components/OverflowTip';

interface IMailServerItemProps {
  mailServer: any;
  handleChange?: () => void;
  CustomerId: number;
  index: number;
}
export const MailServerItem = ({
  mailServer,
  handleChange,
  CustomerId,
  index
}: IMailServerItemProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState<boolean>(false);
  const [openDomainSettings, setOpenDomainSettings] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [
    deleteEmailServer,
    { isFetching: deleteServerFetching, isSuccess: deleteServerSuccess, isError }
  ] = useLazyDeleteEmailServerQuery();

  const defaultSaasServer = isSaaS(Settings) && index === 0;

  const onClickDelete = (e: any) => {
    e.preventDefault();
    deleteEmailServer({ Id: mailServer?.Id });
  };

  useEffect(() => {
    if (deleteServerSuccess && !isError) {
      handleCloseModal();
      if (handleChange) {
        handleChange();
      }
    }
  }, [deleteServerSuccess, isError]);

  const handleCloseModal = () => {
    setOpenDeleteItemModal(false);
  };

  const closeDomainSettings = () => {
    setOpenDomainSettings(false);
  };

  const openDomainList = () => {
    setOpenDomainSettings(true);
  };

  return (
    <Flex mt="sm" w="100%" align="center" maw={550}>
      <OverflowTip className={classes.mailText} w="400px" maxWidth="280px">
        {mailServer?.Name}
      </OverflowTip>
      <Box display="flex">
        {!defaultSaasServer && (
          <ActionIcon size={24} mr={20} onClick={() => open()}>
            <EditIcon />
          </ActionIcon>
        )}
        {mailServer?.Integration ? (
          <Tooltip
            sx={{
              background: theme.colors.brandGray[1],
              borderRadius: theme.spacing.xs,
              padding: '8px'
            }}
            color="gray"
            label={t('domains.title')}
            zIndex="1003"
            offset={9}
            c={theme.colors.dark[8]}
          >
            <ActionIcon size={24} mr={20} onClick={() => openDomainList()}>
              <GlobeIcon />
            </ActionIcon>
          </Tooltip>
        ) : null}
        <Box>
          {!defaultSaasServer && (
            <ActionIcon size={24} onClick={() => setOpenDeleteItemModal(true)}>
              <IconCircleX color="#667085" />
            </ActionIcon>
          )}
        </Box>
      </Box>
      {opened && (
        <MailServerForm
          CustomerId={CustomerId}
          opened={opened}
          handleClose={close}
          handleChange={handleChange}
          mailServer={mailServer}
          isEditing={true}
        />
      )}
      {openDomainSettings && (
        <Domains
          isOpened={openDomainSettings}
          onClose={closeDomainSettings}
          serverId={mailServer?.Id}
        />
      )}

      <Modal
        opened={openDeleteItemModal}
        onClose={() => setOpenDeleteItemModal(false)}
        title={
          <Text fw={700} fz="lg">
            {t('warning')}
          </Text>
        }
        zIndex={1002}
      >
        <Box mb="1rem" sx={{ wordBreak: 'break-word' }}>
          <Text mb="1rem" fz="md">
            {t('servers.warning')}: <b>{mailServer?.Name}</b>?
          </Text>
        </Box>

        <SimpleGrid cols={{ base: 1, sm: 2 }} mt="md">
          <UnstyledButton className={classes.modalCancelBtn} onClick={handleCloseModal}>
            {t('cancel')}
          </UnstyledButton>
          <Button color="red" bg="#C23939" onClick={onClickDelete}>
            {t('continue')}
          </Button>
        </SimpleGrid>
        <LoadingOverlay visible={deleteServerFetching} overlayProps={{ blur: 2 }} />
      </Modal>
    </Flex>
  );
};
