import { FC, useEffect } from 'react';
import { Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical } from '@tabler/icons';
import { useStyles } from './styles';
import { StrictModeDroppable } from '../strict-mode-droppable';
import { IColumnData } from '../simple-table/types';
import { Checkbox } from '@mantine/core';
import { useActions } from '@/hooks/redux/action';
import { IndexColumnsType } from '@/containers/pages/users/hooks/useGetColumns';
import { useTranslation } from 'react-i18next';
import { pascalToCamel } from '@/utils/pascalToCamel';

interface IDndListHandleProps {
  data: IColumnData[];
  setColumnsData?: (columns: IColumnData[]) => void;
}

export const DragDrop: FC<IDndListHandleProps> = ({ data, setColumnsData }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [state, handlers] = useListState(data);
  const { setColumnVisibility } = useActions();

  const hideColumn = (id: string) => {
    handlers.apply((item) => ({
      ...item,
      hideColumn: id === item.dataIndex ? !item.hideColumn : item.hideColumn
    }));

    if (setColumnsData) {
      setColumnsData(state);
    }
  };

  useEffect(() => {
    if (setColumnsData) {
      setColumnsData(state);
    }

    const updateColumnVisibility = {} as Record<IndexColumnsType, boolean>;
    state.forEach((item) => {
      const hideColumn = item.hideColumn === undefined ? true : item.hideColumn;
      updateColumnVisibility[item.dataIndex as IndexColumnsType] = hideColumn;
    });

    setColumnVisibility(updateColumnVisibility);
  }, [state]);

  const allChecked = state.every((item) => !item.hideColumn);

  const toggleAllChecked = () => {
    const allItemsChecked = !allChecked;
    const checkAll = state.map((item) => {
      if (
        item.dataIndex === 'Checkbox' ||
        item.dataIndex === 'Name' ||
        item.dataIndex === 'Action'
      ) {
        return item;
      }
      return {
        ...item,
        hideColumn: !allItemsChecked
      };
    });
    handlers.setState(checkAll);
    if (setColumnsData) {
      setColumnsData(checkAll);
    }
  };

  const items = state.map((item, index) => (
    <Draggable key={item.dataIndex} index={index} draggableId={item.dataIndex}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
            [classes.hideColumn]: !item.title
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={classes.container}>
            <Checkbox
              size="sm"
              checked={!item.hideColumn}
              onChange={() => (item.dataIndex !== 'Name' ? hideColumn(item.dataIndex) : null)}
              disabled={item.dataIndex === 'Name'}
              label={
                <Text fz="sm" style={{ cursor: 'pointer' }}>
                  {item.title}
                </Text>
              }
              data-cy={pascalToCamel(item.dataIndex)}
            />
          </div>
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical size={18} stroke={1.5} />
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <div>
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          handlers.reorder({ from: source.index, to: destination?.index || 0 })
        }
      >
        <div className={classes.toggleAll}>
          <Checkbox
            size="sm"
            checked={allChecked}
            onChange={toggleAllChecked}
            label={t('users.selectAll')}
            data-cy="selectAll"
          />
        </div>
        <StrictModeDroppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};
