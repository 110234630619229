import { createApi } from '@reduxjs/toolkit/query/react';
import { IUserData } from '../auth';
import { customBaseQuery } from '@/utils/customBaseQuery';

import { BASE_URL } from '../constants';
import {
  IChangeEmailPassword,
  ICheckLinkedEmail,
  ICreateEmailResponse,
  IDomain,
  IDomainRecord,
  IDomainRecordResponde,
  IDomainsData,
  IGetDomains,
  ILinkRecord,
  ILinkedEmail,
  IQueryEmailSend,
  IRecordDetails,
  ISendEmailItem,
  ISendEmailResponse,
  IServerDomains,
  IUserDomains,
  IUserEmailItem,
  IVerifyEmailServersResponse
} from './types';

import { IFullResponse } from '@/types/api';

export const emailApi = createApi({
  reducerPath: 'api/email',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  endpoints: (build) => ({
    sendMail: build.query<void, IQueryEmailSend>({
      query: (payload?: IQueryEmailSend) => ({
        url: `/v1/Email/Send`,
        body: {
          ...payload
        },
        method: 'post'
      })
    }),
    emailActivation: build.query<IUserData, number[]>({
      query: (payload?: number[]) => ({
        url: `v2/Confirm/EmailConfirm`,
        body: payload,
        method: 'post'
      })
    }),
    getEmailServer: build.query<any, { Id: number }>({
      query: (payload?: { Id: number }) => ({
        params: {
          customerId: payload?.Id
        },
        url: `v1/EmailServer`,
        method: 'get'
      })
    }),
    getUserEmails: build.query<IUserEmailItem[], { Id: number }>({
      query: (payload?: { Id: number }) => ({
        params: {
          userId: payload?.Id
        },
        url: `v1/email/items`,
        method: 'get'
      })
    }),

    sendEmailServer: build.query<ISendEmailResponse, ISendEmailItem>({
      query: (payload?: ISendEmailItem) => ({
        body: payload,
        url: `v1/emailserver/send`,
        method: 'post'
      })
    }),

    verifyEmailServers: build.query<IVerifyEmailServersResponse, number[]>({
      query: (payload?: any) => ({
        body: payload,
        url: `v2/customer/hasemailsettings`,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IVerifyEmailServersResponse>) => ({
        ...response.Response.Data
      })
    }),

    // Проверка почты перед привязкой

    checkExistsEmailForLink: build.query<ILinkedEmail, ICheckLinkedEmail>({
      query: (payload?: ICheckLinkedEmail) => ({
        params: {
          email: payload?.Email,
          customerId: payload?.CustomerId
        },
        url: `v1/email/checkemailbyserver`,
        method: 'get'
      })
    }),

    //Отвязка почты от пользователя
    deleteLinkedUserEmail: build.query<any, number>({
      query: (payload?: number) => ({
        params: {
          emailId: payload
        },
        url: `v1/Email/DeleteUserEmail`,
        method: 'delete'
      })
    }),

    // Смена пароля привязанной почты
    changePasswordLinkedUserEmail: build.query<any, IChangeEmailPassword>({
      query: (payload: IChangeEmailPassword) => ({
        body: payload,
        url: `v1/Email/ChangePassword`,
        method: 'post'
      })
    }),
    // Добавление почты без привязки  к пользователю
    createEmailWithoutLinkedUser: build.query<
      ICreateEmailResponse,
      {
        Email: string;
        Password: string;
        EmailServer: {
          Id: number;
        };
      }
    >({
      query: (payload: {
        Email: string;
        Password: string;
        EmailServer: {
          Id: number;
        };
      }) => ({
        body: payload,
        url: `v1/Email/SaveEmail`,
        method: 'post'
      })
    }),
    // Запрос на список серверов для созднаия почты
    checkEmailIntegratedList: build.query<any, { customerId: number; onlyIntergrated: boolean }>({
      query: (payload: { customerId: number; onlyIntergrated: boolean }) => ({
        params: {
          customerId: payload?.customerId,
          onlyIntergrated: payload.onlyIntergrated
        },
        url: `v1/EmailServer`,
        method: 'get'
      })
    }),
    emailServerDomains: build.query<IServerDomains[], number>({
      query: (id: number) => ({
        url: `v1/emailserver/domains?customerId=${id}`,
        method: 'get'
      }),
      transformResponse: (response: IUserDomains) => response.Domains
    }),

    /////////////////////////////////////////////////////
    updateEmailServer: build.query<any, any>({
      query: (payload?: any) => ({
        url: `v1/EmailServer/Save`,
        body: payload,
        method: 'post'
      })
    }),
    deleteEmailServer: build.query<IUserData, { Id: number }>({
      query: (payload?: { Id: number }) => ({
        params: {
          id: payload?.Id
        },
        url: `v1/EmailServer`,
        method: 'delete'
      })
    }),

    confirmEmailActivation: build.query<any, { Code: string }>({
      query: (payload: { Code: string }) => ({
        url: `v2/confirm/emailbyform`,
        params: {
          code: payload?.Code
        },
        method: 'get'
      })
    }),

    //Domains
    getDomains: build.query<IDomain[], IGetDomains>({
      query: (payload: IGetDomains) => ({
        url: `v1/emaildomain/list?serverid=${payload.ServerId}&customerid=${payload.CustomerId}`,
        method: 'get'
      }),
      transformResponse: (response: IDomainsData) => response.Domains
    }),

    getLinkRecord: build.query<ILinkRecord, { serverid: number }>({
      query: (payload: { serverid: number }) => ({
        params: {
          serverid: payload?.serverid
        },
        url: `v1/emaildomain/linkinfo?`,
        method: 'get'
      })
    }),

    checkDomain: build.query<ILinkRecord, { domain: string; customerId: number; serverId: number }>(
      {
        query: (payload: { domain: string; customerId: number; serverId: number }) => ({
          params: {
            domain: payload?.domain,
            customerid: payload?.customerId,
            serverid: payload?.serverId
          },
          url: `v1/emaildomain/link?isCheck=true`,
          method: 'get'
        })
      }
    ),

    addDomain: build.query<IDomainRecordResponde, IDomainRecord>({
      query: (payload) => ({
        body: payload,
        url: `v1/emaildomain`,
        method: 'post'
      })
    }),

    domainSettings: build.query<IRecordDetails, { id: number }>({
      query: (payload: { id: number }) => ({
        params: {
          id: payload?.id
        },
        url: `v1/emaildomain`,
        method: 'get'
      })
    }),

    deleteDomain: build.query<any, { id: number }>({
      query: (payload: { id: number }) => ({
        params: {
          id: payload?.id
        },
        url: `v1/emaildomain`,
        method: 'delete'
      })
    })
  })
});

export const {
  useLazySendMailQuery,
  useLazyEmailActivationQuery,
  useLazyDeleteEmailServerQuery,
  useLazyUpdateEmailServerQuery,
  useLazySendEmailServerQuery,
  useGetEmailServerQuery,
  useLazyGetEmailServerQuery,
  useGetUserEmailsQuery,
  useLazyCheckExistsEmailForLinkQuery,
  useLazyChangePasswordLinkedUserEmailQuery,
  useLazyDeleteLinkedUserEmailQuery,
  useCheckEmailIntegratedListQuery,
  useLazyCreateEmailWithoutLinkedUserQuery,
  useLazyConfirmEmailActivationQuery,
  useGetDomainsQuery,
  useLazyGetDomainsQuery,
  useGetLinkRecordQuery,
  useLazyGetLinkRecordQuery,
  useLazyCheckDomainQuery,
  useAddDomainQuery,
  useLazyAddDomainQuery,
  useDomainSettingsQuery,
  useLazyDomainSettingsQuery,
  useDeleteDomainQuery,
  useLazyDeleteDomainQuery,
  useLazyVerifyEmailServersQuery,
  useEmailServerDomainsQuery,
  useLazyEmailServerDomainsQuery
} = emailApi;
