import { ActivityIcon, DriveIcon, FileIcon } from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import { useTranslation } from 'react-i18next';

export const useGetNavMenuItems = () => {
  const { t } = useTranslation();
  const statisticsMenuItems: INavbarMenu[] = [
    {
      label: t('statistics.activity'),
      icon: ActivityIcon,
      value: ROUTES.statistics.links.activity
    },
    {
      label: t('statistics.disk_space'),
      icon: DriveIcon,
      value: ROUTES.statistics.links.diskSpace
    },
    { label: t('statistics.audit'), icon: FileIcon, value: ROUTES.statistics.links.audit }
  ];
  return { statisticsMenuItems };
};
