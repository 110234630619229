import { ErrorBoundary } from '@components/error-boundary';
import { Layout } from '@/containers/layout';
import React from 'react';
import { Licenses } from '@/containers/pages/activations/components/licenses';

export const Activations = () => {
  return (
    <ErrorBoundary componentTitle="License page">
      <Layout>
        <Licenses />
      </Layout>
    </ErrorBoundary>
  );
};
