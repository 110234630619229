import { FC } from 'react';
import { ActionIcon } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons';

import { useStyles } from './styles';
import { IColumnData } from '@components/simple-table/types';
import { DropdownMenuDnd } from '@components/dropdown-menu-dnd';

interface IMoreActionProps {
  list: IColumnData[];
  setColumnsData?: (columns: IColumnData[]) => void;
}

export const MoreAction: FC<IMoreActionProps> = ({ list, setColumnsData }) => {
  const { classes } = useStyles();

  return (
    <DropdownMenuDnd
      list={list}
      classNames={{ dropdown: classes.dnd }}
      position="left-start"
      width={288}
      setColumnsData={setColumnsData}
    >
      <ActionIcon mr={0} data-cy="moreActionsBtn">
        <IconDotsVertical color="#667085" size={18} />
      </ActionIcon>
    </DropdownMenuDnd>
  );
};
