export interface IRolesInitialFilters {
  [key: string]: number[];
}
export enum RoleTypesEnum {
  User = 0,
  Admin = 1,
  SuperUser = 2,
  Guest = 3,
  All = 4,
  Support = 5
}

export enum RolesNavbarMenuItemEnum {
  active = 'active',
  department = 'department',
  type = 'type',
  email = 'email'
}

export enum PanelsEnum {
  generalInformation = 'general-information',
  editInformation = 'edit-information'
}
