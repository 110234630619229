import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  action: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    display: 'flex',
    flexDirection: 'row'
  },

  backArrow: {
    display: 'none',
    '@media (max-width: 574px)': {
      display: 'flex'
    }
  }
}));
