import React, { FC, useEffect } from 'react';
import { Box, Button, Flex, SimpleGrid, Text, TextInput } from '@mantine/core';
import { useForm, FormErrors } from '@mantine/form';
import { useAppSelector } from '@hooks/redux/redux';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { useSaveFilial } from '@/containers/pages/filials/hooks/useSaveFilial';
import { IFilialCommonForm } from '@/entities/admin-app/filials';
import { useStyles } from '../../../styles';
import { isSaaS } from '@/lib/utils/access';
import { useTranslation } from 'react-i18next';
import { getValidateErrors } from '@/lib/utils/validation';
import { settingsPageValidateScheme } from '@/containers/pages/filials/schemes/validate';
const SettingsPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);
  const { Settings } = useAppSelector((state) => state.userInfo);

  const saveFilial = useSaveFilial();

  const form = useForm<IFilialCommonForm>({
    initialValues: {
      Title: '',
      Company: '',
      Parent: {
        Id: null
      },
      IsParentSettings: false
    },
    validateInputOnChange: true,
    validate: (values: IFilialCommonForm): FormErrors =>
      getValidateErrors(settingsPageValidateScheme(t), values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors)?.length !== 0 || !isDirtyForm;

  const isMain = filial?.Parent === null && filial?.IsRoot;

  useEffect(() => {
    if (!filial) {
      return;
    }
    const fromData = {
      IsParentSettings: false,
      ...filial,
      Parent:
        isMain || isSaaS(Settings)
          ? null
          : {
              Id: filial.Parent?.Id || null
            }
    };
    form.setValues(fromData);
    form.resetDirty(fromData);
  }, [filial]);

  const handleSubmit = (values: IFilialCommonForm) => {
    saveFilial(filial, values);
  };

  const transformedOptions = form.getTransformedValues();
  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex className={classes.section} align="flex-start" direction="column">
          <Text className={classes.title}>{t('filials.settings.general')}</Text>

          <SimpleGrid cols={{ base: 1, xs: 2 }} className={classes.listRow}>
            <TextInput
              className={classes.textInput}
              placeholder={t('filials.settings.name')}
              label={t('filials.settings.filialName')}
              withAsterisk
              data-value="Title"
              {...form.getInputProps('Title')}
            />

            {isSaaS(Settings) ? null : (
              <>
                <TextInput
                  className={classes.textInput}
                  placeholder={t('filials.settings.filialCode')}
                  label={t('filials.settings.filialCode')}
                  data-value="Company"
                  {...form.getInputProps('Company')}
                />
                {isMain ? null : (
                  <SelectWithPagination
                    form={form}
                    fieldName="Parent.Id"
                    value={isMain ? null : transformedOptions?.Parent?.Id}
                    label={t('filials.settings.filialParent')}
                    placeholder={t('choose')}
                    list={[]}
                    SkipCustomers={filial?.Id ? [filial?.Id] : []}
                  />
                )}
              </>
            )}
          </SimpleGrid>
          <Button
            type="submit"
            size="sm"
            radius={8}
            disabled={isDisabled}
            mt="md"
            style={{ padding: '0 20px' }}
          >
            {t('save')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SettingsPage;
