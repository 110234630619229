import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@supports(-webkit-touch-callout: none)': {
      height: '-webkit-fill-available'
    },
    [utils.smallerThan('xs')]: {
      paddingTop: '0',
      paddingBottom: '0'
    }
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: '40px',

    [utils.smallerThan('sm')]: {
      fontWeight: 600,
      fontSize: 25
    }
  },

  description: {
    fontSize: 16,
    [utils.largerThan('sm')]: {
      fontSize: 18
    }
  },

  control: {
    [utils.smallerThan('sm')]: {
      width: '100%'
    }
  },

  mobileImage: {
    [utils.largerThan('sm')]: {
      display: 'none'
    }
  },

  desktopImage: {
    [utils.smallerThan('sm')]: {
      display: 'none'
    }
  }
}));
