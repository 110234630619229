export const arrayBufferToString = (buf: ArrayBufferLike) => {
  const bytes = new Uint8Array(buf);
  return bytes.reduce((str, byte) => str + String.fromCharCode(byte), '');
};

export const arrayBufferToBase64 = (encryptedBuf: ArrayBufferLike) => {
  return btoa(arrayBufferToString(encryptedBuf));
};

const stringToArrayBuffer = (str: string) => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);

  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

interface Jwk {
  e: string;
  kty: string;
  n: string;
}

const importKey = (jwk: Jwk) => {
  return crypto.subtle.importKey(
    'jwk',
    {
      kty: jwk.kty,
      e: jwk.e,
      n: jwk.n
    },
    {
      name: 'RSA-OAEP',
      hash: { name: 'SHA-256' },
      length: 4096
    },
    false,
    ['encrypt']
  );
};

export const encryptContent = async (jwk: Jwk, content: string) => {
  const pubKey = await importKey(jwk);

  return crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP'
    },
    pubKey,
    stringToArrayBuffer(content)
  );
};
