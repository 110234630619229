import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalContainer: modalTheme(theme).container,
  modalFooter: { ...modalTheme(theme).footer, position: 'sticky', bottom: 0, zIndex: 1 },
  modalCancelBtn: modalTheme(theme).cancelBtn,
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  title: {
    width: '100%'
  },

  description: {
    width: '100%'
  }
}));
