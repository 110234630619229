import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  text: {
    wordBreak: 'break-word',
    span: {
      display: 'block'
    }
  }
}));
