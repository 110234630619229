import React, { useContext, useEffect } from 'react';
import { Button, InputProps, LoadingOverlay, SimpleGrid } from '@mantine/core';
import { Gender } from '@components/fields/gender';
import { DatePicker } from '@components/fields/date-picker';
import { EditUserInfoContext } from '../../../index';
import { Phone } from '@components/fields/phone';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { ValidatePhoneInput } from '@/containers/pages/user/components/validateFields/validatePhoneInput';
import { Address } from '@/components/fields/address';
import { TextFieldInput } from '@/components/fields/text-field-input';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateSchemeEditUserInfo } from '@/containers/pages/user/schemes/validate';
import { IUserCommon } from '@/entities/admin-app/user';
import { useUpdateInfo } from '@hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';

export const GeneralInformation = () => {
  const { t } = useTranslation();
  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);

  const { Common } = userInfoCommon;
  const { saveUpdate, isLoading } = useUpdateInfo();

  const userGeneralInfoForm = useForm<IUserCommon>({
    initialValues: {
      FirstName: '',
      SurName: '',
      MiddleName: '',
      Gender: 0,
      BirthDate: null,
      Address: '',
      Phone: '',
      AdditionalPhone: '',
      LastEntryDate: '',
      DateOfCreate: ''
    },
    validateInputOnChange: true,
    validate: (values: IUserCommon): FormErrors =>
      getValidateErrors(validateSchemeEditUserInfo(t), values)
  });

  const errors = userGeneralInfoForm.errors;
  const isDirtyForm = userGeneralInfoForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;
  const isMobile = useMediaQuery('(max-width: 576px)');

  const fromData = {
    FirstName: Common?.FirstName || '',
    SurName: Common?.SurName || '',
    MiddleName: Common.MiddleName || '',
    Gender: Common.Gender || 0,
    BirthDate: Common.BirthDate || null,
    Address: Common.Address || '',
    Phone: Common.Phone || '',
    AdditionalPhone: Common.AdditionalPhone || '',
    LastEntryDate: Common.LastEntryDate || '',
    DateOfCreate: Common.DateOfCreate || ''
  };

  useEffect(() => {
    userGeneralInfoForm.setValues(fromData);
    userGeneralInfoForm.resetDirty(fromData);
  }, [Common]);

  const handleSubmit = async (values: IUserCommon) => {
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Common,
      Data: { [ProfileSectionEnum.Common]: values, Id: currentId }
    });
    userGeneralInfoForm.setValues(fromData);
    userGeneralInfoForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={userGeneralInfoForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.generalInformation')}>
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
          <TextFieldInput
            fieldName="SurName"
            label={t('profile.surName')}
            form={userGeneralInfoForm}
            isRequired={true}
          />
          <TextFieldInput
            isRequired={true}
            fieldName="FirstName"
            label={t('profile.firstName')}
            form={userGeneralInfoForm}
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
          <TextFieldInput
            fieldName="MiddleName"
            label={t('profile.middleName')}
            form={userGeneralInfoForm}
          />
          <Gender
            fieldName="Gender"
            form={userGeneralInfoForm}
            defaultValue={String(userGeneralInfoForm.values?.Gender)}
            clearable={true}
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
          <DatePicker
            fieldName="BirthDate"
            placeholder={t('profile.chooseBirthDate')}
            label={t('profile.birthDate')}
            form={userGeneralInfoForm}
            clearable={true}
          />
          <Address
            fieldName="Address"
            label={t('profile.address')}
            form={userGeneralInfoForm}
            defaultValue={
              userGeneralInfoForm.values.Address ? userGeneralInfoForm.values.Address : ''
            }
          />
        </SimpleGrid>

        {/*<SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">*/}
        {/*  <TextFieldInput fieldName="InternetAddress" label="Адрес в интернет" form={form} />*/}
        {/*  <TextFieldInput fieldName="Country" label="Адрес (страна)" form={form} />*/}
        {/*</SimpleGrid>*/}

        {/*<SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">*/}
        {/*  <Address*/}
        {/*    fieldName="FullAddress"*/}
        {/*    label="Адрес (город, улица, дом)"*/}
        {/*    form={form}*/}
        {/*    defaultValue={AdditionalInformation?.FullAddress?.Name}*/}
        {/*  />*/}
        {/*</SimpleGrid>*/}

        <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
          {/*<PostIndex*/}
          {/*  label="Адрес (индекс)"*/}
          {/*  error={form.getInputProps('PostIndex').error}*/}
          {/*  inputProps={form.getInputProps(`${AdditionalInformationFormKey}PostIndex`) as InputProps}*/}
          {/*/>*/}

          <ValidatePhoneInput
            form={userGeneralInfoForm}
            mask="+7 (999) 999-99-99"
            label={t('profile.phone')}
            fieldName="Phone"
          />

          <Phone
            label={t('profile.additionalPhone')}
            inputProps={userGeneralInfoForm.getInputProps(`AdditionalPhone`) as InputProps}
            form={userGeneralInfoForm}
            fieldName="AdditionalPhone"
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
          <DatePicker
            fieldName="DateOfCreate"
            label={t('profile.dateOfCreateProfile')}
            form={userGeneralInfoForm}
            disabled
          />

          <DatePicker
            fieldName="LastEntryDate"
            label={t('profile.lastEntryDate')}
            form={userGeneralInfoForm}
            disabled
          />
        </SimpleGrid>
        <Button
          type="submit"
          size="sm"
          radius={8}
          disabled={isDisabled}
          mt="md"
          style={{ padding: '0 20px' }}
        >
          {t('profile.save')}
        </Button>
        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};
