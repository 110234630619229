export interface IFilialsInitialFilters {
  [key: string]: number[];
}

export enum FilialInfoPanelsEnum {
  ipSecurityUsers = 'ip-security-users',
  ipSecurityAdm = 'ip-security-adm',
  sessionDuration = 'session-duration',
  passwordLimit = 'password-limit',
  passwordSecurity = 'password-security'
}

export enum ModulesEnum {
  Mail = 1,
  Calendar = 2,
  Draw = 5,
  None = 0,
  Disk = 3,
  Admin = 4,
  Payments = 7,
  Contacts = 8,
  Team = 9,
  Projects = 10
}

export enum TagEnum {
  emailRoles = 'EmailRoles',
  calendarRoles = 'CalendarRoles',
  emailUsers = 'EmailUsers',
  calendarUsers = 'CalendarUsers'
}
export const tagTypes = Object.values(TagEnum);
