import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: theme.fontSizes.sm,
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    borderRadius: '8px',
    width: '100%',
    cursor: 'pointer',

    '& input, label': {
      cursor: 'pointer'
    },

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[7],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[2],
        color: theme.black
      }
    },

    '& .mantine-Radio-root, .mantine-Radio-body, .mantine-Radio-labelWrapper': {
      width: '100%'
    }
  },

  counter: {
    fontSize: '12px',
    [utils.dark]: {
      color: theme.white
    },
    [utils.light]: {
      color: theme.black
    }
  },

  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },

  checkbox: {
    cursor: 'pointer',
    wordWrap: 'break-word',
    maxWidth: '230px',
    fontSize: theme.fontSizes.sm
  },

  selectedGroup: {
    [utils.dark]: {
      backgroundColor: theme.colors.dark[7],
      color: theme.white
    },
    [utils.light]: {
      backgroundColor: theme.colors.gray[2],
      color: theme.black
    }
  },

  radio: {
    '& .mantine-Radio-inner': {
      alignSelf: 'center'
    },
    '& .mantine-Radio-label': {
      fontSize: '14px'
    }
  }
}));
