import { MantineThemeOverride } from '@mantine/core';
import { themeColors } from './colors';

export const theme: MantineThemeOverride = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  headings: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    sizes: {
      h1: {
        fontSize: '34px',
        fontWeight: '600',
        lineHeight: '17px'
      },
      h2: {
        fontSize: '32px',
        fontWeight: '600',
        lineHeight: '32px'
      },
      h3: {
        fontSize: '22px',
        fontWeight: '500',
        lineHeight: '22px'
      },
      h4: {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '18px'
      },
      h5: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px'
      },
      h6: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '14px'
      }
    }
  },
  primaryColor: 'main',
  spacing: { xs: '8px', sm: '16px', md: '24px', lg: '32px', xl: '40px' },
  colors: themeColors,
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)'
  },

  components: {
    ActionIcon: {
      defaultProps: {
        variant: 'subtle',
        color: 'gray'
      }
    },
    Button: {
      defaultProps: {
        radius: '8px'
      }
    },
    Select: {
      defaultProps: {
        withCheckIcon: false
      },
      styles: {
        option: {
          '&[data-checked]': {
            backgroundColor: 'var(--mantine-color-blue-5)',
            color: '#FFF',
            '&:hover': {
              backgroundColor: 'var(--mantine-color-blue-6)'
            }
          }
        }
      }
    }
  }
};
