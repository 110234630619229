import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  headerPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  title: {
    marginLeft: theme.spacing.sm,
    fontWeight: 600,
    fontSize: '14px'
  }
}));
