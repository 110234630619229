import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
// import { useActions } from '../../../../hooks/redux/action';
import { formatDateTime } from '@/lib/utils/date';
import { numbersToActionType } from '@/lib/utils';
import { AuthActionsDropdown } from '@/containers/pages/statistics/components/tabs/audit/components/table/action';
import { useTranslation } from 'react-i18next';

export const useGetColumns = () => {
  const { t } = useTranslation();
  // const { setSelectedGroupsIds } = useActions();

  const columnsData: IColumnData[] = [
    {
      width: 'auto',
      dataIndex: 'Name',
      title: t('statistics.user'),
      hideColumn: false,
      render: (item: IRowData) => item?.Name
    },
    {
      width: 'auto',
      dataIndex: 'Date',
      title: t('statistics.date_time'),
      hideColumn: false,
      render: (item: IRowData) => formatDateTime(item?.Date)
    },
    {
      width: 'auto',
      dataIndex: 'ActionType',
      title: t('statistics.action'),
      hideColumn: false,
      render: (item: IRowData) => numbersToActionType({ value: item?.ActionType, t })
    },
    {
      width: 'auto',
      dataIndex: '',
      title: '',
      hideColumn: false,
      render: (item: IRowData) => <AuthActionsDropdown item={item} />
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
