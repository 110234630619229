import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { formatNumber, formatDataSizeUnits } from '@/lib/utils/format';
import { useTranslation } from 'react-i18next';

export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnsData: IColumnData[] = [
    {
      width: 'auto',
      dataIndex: 'Name',
      title: t('statistics.user'),
      hasSort: true,
      render: (item: IRowData) => (item?.Name !== null ? item?.Name : t('statistics.total'))
    },
    {
      width: 'auto',
      dataIndex: 'Space',
      title: t('statistics.space_used'),
      hasSort: true,
      render: (item: IRowData) => formatDataSizeUnits(item?.Space)
    },
    {
      width: 'auto',
      dataIndex: 'DocsCount',
      title: t('statistics.documents_count'),
      hasSort: true,
      render: (item: IRowData) => formatNumber(item?.DocsCount)
    },
    {
      width: 'auto',
      dataIndex: 'FilesCount',
      title: t('statistics.files_count'),
      hasSort: true,
      render: (item: IRowData) => formatNumber(item?.FilesCount)
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
