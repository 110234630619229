import { ValidateFieldEnum } from '@/entities/admin-app/auth';
import { ROUTES } from '@/containers/routes/constants';
import { ITransformedRoute } from '@/types';

export const getNameFromDottedString = (value: string | undefined): ValidateFieldEnum => {
  const splitArray = value?.split(/\.(?=[^.]+$)/) || [];
  const fieldName = splitArray[splitArray.length - 1];
  return fieldName === 'Email'
    ? ValidateFieldEnum.Email
    : fieldName === 'Phone'
    ? ValidateFieldEnum.Phone
    : ValidateFieldEnum.Login;
};

export const copyToClipboard = (value: string | undefined) => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = value || '';
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const getUserEditRoutes = (): ITransformedRoute[] => {
  const { fullPath, pages } = ROUTES.profile;
  return Object.values(pages).map((value) => {
    const path = `${fullPath}/${value ? `${value}` : ''}`;
    return { path, chunk: value };
  });
};
