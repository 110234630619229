import { useState, useEffect } from 'react';

import { useGetDefaultUserFiltersQuery } from '@/entities/admin-app/users/api';

import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { useTranslation } from 'react-i18next';

export const useGetDiskSpaceFilters = () => {
  const { t } = useTranslation();
  // FIXME
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultUserFiltersQuery(10);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (!(defaultFiltersData && defaultFiltersSuccess)) {
      return;
    }
    const diskSpaceFilter = defaultFiltersData.filter(
      (el) => el.Type === IFiltersEnumType.FilialFilter
    );
    if (!diskSpaceFilter) {
      return;
    }

    const convertDefaultFilters: IFilterMenu[] = diskSpaceFilter
      .map((item) => ({
        ...item,
        label:
          item.Type in IFiltersEnumType
            ? t(`filters.${filtersLabelsKeys[item.Type]}`)
            : t('statistics.filial'),
        Items: item.Items?.map((filterItem) => ({
          ...filterItem,
          // Count: undefined,
          checked: false
        })),
        hideSearch: false
      }))
      .sort((a, b) => a.Type - b.Type);

    setFiltersMenuList(convertDefaultFilters);
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
