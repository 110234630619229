import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  truncate: {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  },

  dropdown: {
    padding: 0,
    marginTop: '5px'
  },

  menuItem: {
    padding: '6px 0',
    alignItems: 'center'
  },

  itemLabel: {
    fontSize: '16px',
    alignItems: 'center'
  },

  itemIcon: {
    marginRight: '11px'
  }
}));
