import React, { Dispatch, ElementType, FC, SetStateAction } from 'react';
import { ActionIcon, Box, Flex, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons';

import { IRowData } from '@components/simple-table/types';
import { IAuditItem } from '@/types';

import { authActionsMenu } from './dropdownList';

import { useStyles } from './styles';

interface IAuthActionsDropdownProps {
  item?: IRowData;
  setRowData?: Dispatch<SetStateAction<IRowData[]>>;
}

export const AuthActionsDropdown: FC<IAuthActionsDropdownProps> = ({ item }) => {
  const [opened, { close, open }] = useDisclosure(false);
  const { classes } = useStyles();

  const handleContextMenu = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    close();
  };

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.nativeEvent.stopPropagation();
    opened ? close() : open();
  };

  return (
    <Popover withinPortal opened={opened} width={292} position="left-start" onClose={close}>
      <Popover.Target>
        <ActionIcon className="action-icon" onClick={handleClick} onContextMenu={handleContextMenu}>
          <IconChevronDown color="#667085" data-action="action" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown className={classes.popover}>
        {authActionsMenu(item).map((item: IAuditItem, index: number) => {
          const Icon = item.icon as ElementType;
          return (
            <Box key={index} data-value={item.value} className={classes.itemMenu}>
              <Flex align="center" gap="5px">
                <Icon color="#667085" stroke={1.5} />
                <Text>{item?.label}</Text>
              </Flex>
            </Box>
          );
        })}
      </Popover.Dropdown>
    </Popover>
  );
};
