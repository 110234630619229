import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing.sm,
    backgroundColor: theme.white,
    borderRadius: theme.spacing.xs,
    padding: theme.spacing.md,
    position: 'relative'
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },

  title: {
    fontSize: '16px',
    marginBottom: theme.spacing.md,
    fontWeight: 600
  },

  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  textField: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  },

  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing.md
  }
}));
