import { Modal } from '@/components/modal';
import { useVerifyUsersEmail } from '@/containers/pages/filials/hooks/useVerifyUsersEmail';
import { useAppSelector } from '@/hooks/redux/redux';
import { Button, Text, Box, LoadingOverlay, UnstyledButton } from '@mantine/core';
import { Dispatch, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersListByVerifiedFilials } from './filial-list';
import { SendMailModal } from './send-modal';
import { useStyles } from './styles';

interface IVerifyEmailUsers {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  selectedUsers: number[];
}

export const VerifyEmailUsers: FC<IVerifyEmailUsers> = ({ open, setOpen, selectedUsers }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { users } = useAppSelector((state) => state.users);
  const [sendMail, setSendMail] = useState(false);
  const { isLoading, sortedList, handleClickUser, verifiedIds, isError } = useVerifyUsersEmail({
    selectedUsers,
    users
  });

  const handleContinue = () => {
    setSendMail(true);
  };

  const handleBack = () => {
    setSendMail(false);
  };

  return (
    <Modal
      size="500px"
      zIndex={1400}
      opened={open}
      onClose={() => setOpen(false)}
      title={sendMail ? t('sendLetter.newLetter') : t('sendLetter.writeLetter')}
      onBackClick={sendMail && handleBack}
    >
      {sendMail ? (
        <SendMailModal selection={verifiedIds} setOpenModal={setOpen} />
      ) : (
        <>
          <Box className={classes.modalContainer}>
            {!isError ? (
              <Box sx={{ width: '100%' }}>
                <Text mb="25px">{t('sendLetter.sendWarning')}</Text>
                <UsersListByVerifiedFilials
                  isLoading={isLoading}
                  sortedList={sortedList}
                  handleClickUser={handleClickUser}
                />
              </Box>
            ) : (
              <Text fz="lg">{t('requestsErrors.unknownError')}</Text>
            )}
          </Box>
          <Box className={classes.modalFooter}>
            <Button
              w="49%"
              disabled={!verifiedIds.length}
              onClick={() => {
                handleContinue();
              }}
            >
              {t('continue')}
            </Button>
            <UnstyledButton
              w="49%"
              className={classes.modalCancelBtn}
              onClick={() => setOpen(false)}
            >
              {t('cancel')}
            </UnstyledButton>
          </Box>
        </>
      )}
      <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} />
    </Modal>
  );
};
