import { alpha } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  tr: {
    padding: '0 !important',
    height: '72px',
    position: 'relative',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:first-of-type td': {
      borderTop: 'none !important'
    },

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[6]
      },
      [utils.light]: {
        backgroundColor: theme.colors.backgroundGray[6]
      },

      '& .action-icon': {
        opacity: 1
      }
    },

    '& .action-icon': {
      opacity: 0
    }
  },

  td: {
    padding: '17px 24px !important',
    borderTop: `1px solid ${theme.colors.backgroundGray[6]} !important`,
    '& p': {
      fontSize: '14px'
    }
  },

  rowSelected: {
    [utils.dark]: {
      backgroundColor: alpha(theme.colors[theme.primaryColor][7], 0.2)
    },
    [utils.light]: {
      backgroundColor: theme.colors.mainBlue[0]
    }
  },

  pinnedRow: {
    backgroundColor: theme.colors.backgroundGray[6],
    fontWeight: 600
  },

  withCheckbox: {
    padding: '17px 16px !important'
  },

  hideColumn: {
    display: 'none'
  },

  pinnedCOlumn: {
    fontWeight: 600
  }
}));
