import { useCallback } from 'react';
import { useLdapSettingsSyncMutation } from '@/entities/admin-app/filials/api';
import { universalNotification } from '@/lib/utils/notification';
import { useTranslation } from 'react-i18next';

export const ldapSettingsSync = () => {
  const { t } = useTranslation();
  const [ldapSync, { isLoading: isLdapSync }] = useLdapSettingsSyncMutation();

  const SyncSettingsLdap = useCallback(async (id: number) => {
    try {
      const res = await ldapSync({
        id: id
      });
      if ('error' in res) {
        universalNotification({
          error: res.error,
          isError: true,
          isSuccess: false
        });
      } else {
        universalNotification({
          isSuccess: true,
          error: null,
          isError: false,
          successTitle: t('filials.authorization.syncStarted')
        });
      }
    } catch (err: any) {
      console.warn(t('filials.errors.syncError'), err);
    }
  }, []);

  return { SyncSettingsLdap, isLdapSync };
};
