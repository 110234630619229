import { useLazyLoginAsQuery } from '@/entities/admin-app/auth/api';
import { useCallback } from 'react';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { universalNotification } from '@/lib/utils/notification';

interface ILoginAsProps {
  Login: string;
  LoginAs: string;
  Password: string;
  handleUpdate?: () => void;
}
export const useLoginAs = () => {
  const { t } = useTranslation();
  const [loginAsRequest, { isFetching: isFetchingLoginAs, isSuccess }] = useLazyLoginAsQuery();

  const handleLoginAs = useCallback(
    async ({ Login, LoginAs, Password, handleUpdate }: ILoginAsProps) => {
      try {
        const res = await loginAsRequest({
          Login,
          LoginAs,
          Password
        });
        if ('error' in res) {
          const codeError = extractErrorCode(res.error);
          universalNotification({
            error: res.error,
            errorTitle:
              res.error && 'status' in res.error && res.error.status === 401
                ? t('login.errors.wrongPassword')
                : generateErrorMessage(codeError),
            isError: true,
            isSuccess: false
          });
        } else {
          window.location.href = ROUTES.dashboard.fullPath;
          if (handleUpdate) {
            handleUpdate();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  return { handleLoginAs, isFetchingLoginAs, isSuccess };
};
