import React, { FC } from 'react';
import { Avatar, Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IRolesInfoCommon } from '@/entities/admin-app/roles';
import { RolesPlaceholder } from '@assets/icons';
import OverflowTip from '@components/OverflowTip';
interface IPhotoInfoProps {
  rolesInfoCommon?: IRolesInfoCommon;
}

export const PhotoInfo: FC<IPhotoInfoProps> = ({ rolesInfoCommon }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.photo}>
      <Avatar color="blue" size={124} radius={62}>
        <RolesPlaceholder size={60} />
      </Avatar>
      <Box className={classes.container}>
        <Box maw="300px">
          <OverflowTip className={classes.name}>{rolesInfoCommon?.Name || ''}</OverflowTip>
        </Box>

        <Text className={classes.email}>ID:{rolesInfoCommon?.Id}</Text>
      </Box>
    </Box>
  );
};
