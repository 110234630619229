import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  itemBox: {
    backgroundColor: theme.white,
    borderRadius: '8px'
  },

  item: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: theme.fontSizes.sm,
    backgroundColor: theme.white,
    borderRadius: '8px',
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    width: '100%',
    cursor: 'pointer'
  },

  itemActive: {
    fontWeight: 600
  }
}));
