import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';
import { useLazySyncTeamSettingsQuery } from '@/entities/admin-app/commonSettings/api';

export const teamSettingsSync = () => {
  const [teamSync, { isLoading: isTeamSync }] = useLazySyncTeamSettingsQuery();
  const SyncTeamSettings = useCallback(async () => {
    try {
      const res = await teamSync();
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        showNotification({
          title: `Сихронизация запущена!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
      }
    } catch (err: any) {
      console.warn('Ошибка синхронезации', err);
    }
  }, []);

  return { SyncTeamSettings, isTeamSync };
};
