import { z } from 'zod';
import { emailRegExp, phoneRegExp } from '@/lib/utils/validation';
import { IAdditionalEmailCredentials } from '@/entities/admin-app/user';
import { TFunction } from 'i18next';

export const validateSchemeStatus = (t: TFunction) =>
  z.number({
    invalid_type_error: t('validation.selectUserStatus'),
    required_error: t('validation.statusRequired')
  });

export const validateSchemeCustomer = (t: TFunction) =>
  z.object({
    Id: z
      .number({
        invalid_type_error: t('validation.selectDepartment'),
        required_error: t('validation.divisionRequired')
      })
      .positive({
        message: t('validation.selectDepartment')
      })
  });

export const validateSchemeTimeZone = (t: TFunction) =>
  z.object({
    Id: z.string({
      invalid_type_error: t('validation.selectTimeZone')
    })
  });
export const validateLanguageSettings = (t: TFunction) =>
  z.object({
    Language: z.string({
      invalid_type_error: t('validation.selectLanguage'),
      required_error: t('validation.selectLanguage')
    })
  });

export const validateSchemePositions = (t: TFunction) =>
  z
    .array(
      z.object({
        Position: z.object({
          Name: z
            .string()
            .max(64, {
              message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 })
            })
            .refine((data) => /\S/.test(data), {
              message: t('validation.enterPosition')
            })
        })
      })
    )
    .nullable();

export const validateSchemeDataStorageQuota = (t: TFunction) =>
  z.object({
    Id: z
      .number({
        invalid_type_error: t('validation.selectQuota'),
        required_error: t('validation.quotaMandatory')
      })
      .optional()
      .nullable()
  });

export const validateEmailScheme = (t: TFunction) =>
  z.object({
    Email: z
      .string()
      .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
      .or(z.literal(''))
      .optional()
      .nullable()
  });

function validateAdditionalEmailNotEqual(data?: IAdditionalEmailCredentials) {
  if (!data?.Email || !data?.AdditionalEmail) {
    return true;
  }
  return data?.AdditionalEmail !== data?.Email;
}
export const validateAdditionalEmailScheme = (t: TFunction) =>
  z
    .object({
      AdditionalEmail: z
        .string()
        .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
        .or(z.literal(''))
        .optional()
        .nullable(),
      Email: z.string().or(z.literal('')).optional().nullable()
    })
    .refine(validateAdditionalEmailNotEqual, {
      message: t('validation.emailsShouldNotBeTheSame'),
      path: ['AdditionalEmail']
    });
export const validateLoginScheme = (t: TFunction) =>
  z.object({
    Login: z
      .string()
      .min(1, { message: t('validation.enterLogin') })
      .max(150, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 150 }) })
      .refine((data) => !/\s/.test(data), {
        message: t('validation.loginWithoutSpaces')
      })
  });

export const isSaasScheme = (t: TFunction) =>
  z.object({
    isSaaS: z.literal(true),
    Email: z
      .string()
      .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
      .min(1, { message: 'Введите e-mail' })
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) })
  });

export const noSaasScheme = (t: TFunction) =>
  z.object({
    isSaaS: z.literal(false),
    Email: z
      .string()
      .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) })
      .or(z.literal(''))
      .optional()
      .nullable()
  });

export const validateCredentialsNoSaas = (t: TFunction) =>
  z.object({
    isSaas: z.literal(false),
    Email: z
      .string()
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) })
      .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
      .or(z.literal(''))
      .optional()
      .nullable(),
    Login: z
      .string()
      .min(1, { message: t('validation.enterLogin') })
      .max(150, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 150 }) })
      .refine((data) => !/\s/.test(data), {
        message: t('validation.loginWithoutSpaces')
      })
  });

export const validateCredentialsSaas = (t: TFunction) =>
  z.object({
    isSaas: z.literal(true),
    Email: z
      .string()
      .regex(emailRegExp, { message: t('validation.wrongEmailFormat') })
      .min(1, { message: 'Введите e-mail' })
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) }),
    Login: z.string().optional().nullable()
  });

export const validateSchemeCredentials = (t: TFunction) =>
  z.union([validateCredentialsNoSaas(t), validateCredentialsSaas(t)]);

export const validateSchemeEditUserInfo = (t: TFunction) =>
  z.object({
    SurName: z
      .string()
      .max(85, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
      .refine((data) => /\S/.test(data), {
        message: t('validation.enterLastName')
      }),
    FirstName: z
      .string()
      .max(85, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
      .refine((data) => /\S/.test(data), {
        message: t('validation.enterFirstName')
      }),
    MiddleName: z
      .string()
      .max(85, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
      .optional()
      .or(z.literal(''))
      .nullable(),
    Gender: z
      .number()
      .min(1, { message: t('validation.chooseGender') })
      .or(z.number().default(0))
      .optional(),
    BirthDate: z
      .preprocess(
        (arg) => (typeof arg === 'string' ? new Date(arg) : ' '),
        z
          .date({ invalid_type_error: t('validation.indicateBirthday') })
          .min(new Date('1900-01-01'), { message: t('validation.enteredDateSmall') })
          .max(new Date(), { message: t('validation.dateLessCurrent') })
          .or(z.string().optional())
          .nullable()
      )
      .nullable(),
    Address: z
      .string()
      .max(255, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) })
      .optional()
      .nullable(),
    Phone: z
      .string()
      .regex(phoneRegExp, { message: t('validation.wrongMobilePhoneFormat') })
      .or(z.literal(''))
      .optional()
      .nullable(),
    AdditionalPhone: z
      .string()
      .regex(/^[0-9]*$/, { message: t('validation.extensionPhoneContainNumbers') })
      .or(z.literal(''))
      .optional()
      .nullable()
  });
