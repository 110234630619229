import { FC, useEffect, useMemo, useState } from 'react';
import { Flex, Select, ComboboxItem, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useGetUserEmailsQuery } from '@/entities/admin-app/email/api';
import { IUserEmailItem } from '@/entities/admin-app/email';
import { IconChevronDown } from '@tabler/icons';
import { useStyles } from '../../../../styles';
import { useTranslation } from 'react-i18next';

interface IEmailsSelectProps {
  defaultValue?: number | null;
  form?: UseFormReturnType<any>;
  handleChange?: (query: string | null) => void;
  fieldName: string;
  value?: number | null;
  userId: number;
}

export const EmailsSelect: FC<IEmailsSelectProps> = ({
  defaultValue,
  form,
  handleChange,
  fieldName,
  value,
  userId
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data, isSuccess } = useGetUserEmailsQuery({
    Id: userId
  });

  const [emails, setEmails] = useState<IUserEmailItem[]>([]);

  useEffect(() => {
    if (data) {
      setEmails(data);
    }
  }, [data]);

  const list = useMemo(
    () =>
      Array.isArray(emails)
        ? emails?.map(
            (Item) => ({ label: String(Item.Email), value: Item.Id.toString() } as ComboboxItem)
          )
        : [],
    [emails]
  );

  const error = form ? form.errors[`${fieldName}.Id`] : '';

  const handleChangeEmail = (query: string | null) => {
    if (form) {
      const selected = emails.find((email) => email.Id === Number(query));
      form?.setFieldValue(`${fieldName}`, { Name: selected?.Email, Id: selected?.Id });
    } else {
      handleChange && handleChange(query);
    }
  };

  return (
    <Flex direction="column" sx={{ width: '100%' }}>
      <Select
        className={classes.textFieldInput}
        required
        data={list}
        placeholder={t('filials.mailSetup.emailPlaceholder')}
        label={t('filials.mailSetup.emailLabel')}
        rightSectionWidth={30}
        rightSection={<IconChevronDown size="1rem" color="#98A2B3" />}
        defaultValue={String(defaultValue) ?? ''}
        value={`${value || ''}`}
        sx={{ width: '100%' }}
        onChange={handleChangeEmail}
        error={error}
      />
      {!emails?.length && isSuccess ? (
        <Text fz="xs" color="red">
          {t('filials.mailSetup.noEmail')}
        </Text>
      ) : null}
    </Flex>
  );
};
