import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';

import { IQueryTable } from '@/types/api';
import { IGroupsInitialFilters } from '@/types/enums/groups';
import { BASE_URL } from '../constants';
import { IGroupsData, IQueryBulkAddUsers, IQueryChangeParent } from './types';

const tagTypes = ['Groups'];

export const groupsApi = createApi({
  reducerPath: 'api/groups',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getGroups: build.query<IGroupsData, IQueryTable<IGroupsInitialFilters>>({
      query: (payload: IQueryTable<IGroupsInitialFilters>) => ({
        url: `/v1/Role/FilteredList`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IGroupsData) => response,
      providesTags: ['Groups']
    }),
    bulkAddUsers: build.mutation<void, IQueryBulkAddUsers>({
      query: (payload: IQueryBulkAddUsers) => ({
        url: `v1/User/BulkAddRoles`,
        body: payload,
        method: 'post'
      })
    }),
    deleteGroup: build.mutation<void, number[]>({
      query: (payload: number[]) => ({
        url: `v1/Role/Delete`,
        body: payload,
        method: 'post'
      })
    }),
    changeParent: build.mutation<void, IQueryChangeParent>({
      query: (payload: IQueryChangeParent) => ({
        url: `v1/Role/ChangeParent`,
        body: payload,
        method: 'post'
      })
    })
  })
});

export const {
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useBulkAddUsersMutation,
  useDeleteGroupMutation,
  useChangeParentMutation
} = groupsApi;
