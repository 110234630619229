import { createApi } from '@reduxjs/toolkit/query/react';
import { IFullResponse } from '@/types/api';
import { BASE_URL } from '../constants';
import { customBaseQuery } from '@/utils/customBaseQuery';
import {
  IBulkForgot,
  ICheckExistFieldRequest,
  ICheckExistFieldResponse,
  IQueryLogin,
  IResetPasswordRequest,
  IResetPasswordResponse,
  IUpdatePasswordWithCodeRequest,
  IUpdatePasswordWithCodeResponse,
  ICheckExistCodeRequest,
  ICheckExistCodeResponse,
  IUserData,
  ICheckExistRegisterCodeResponse,
  IUserExistsResponse,
  IUserExistsQuery,
  ICanCreateUserResponse
} from './types';
import {
  ICreateInvitationLinkProps,
  IInvitationListResponse,
  IUpdateInvitationLinkProps
} from '@/entities/admin-app/filials';
import { generateUUID } from '@utils/uuid';
import { getCookieByName } from '@/entities/helpers';

enum cookiesEnum {
  DeviceId = '_DeviceUid'
}
interface ICrypt {
  PublicKeyBase64: string;
  Jwk: {
    e: string;
    n: string;
    alg: null;
    kty: string;
  };
}

export const authApi = createApi({
  reducerPath: 'api/auth',
  baseQuery: customBaseQuery(`${BASE_URL}`),
  refetchOnFocus: false,
  endpoints: (build) => ({
    login: build.query<IUserData, IQueryLogin>({
      query: (payload?: IQueryLogin) => ({
        url: `/v2/Auth/login`,
        body: {
          DeviceId: getCookieByName(cookiesEnum.DeviceId) || generateUUID(),
          Login: payload?.Login,
          Password: payload?.Password,
          CustomerCode: payload?.CustomerCode,
          CryptedPassword: payload?.CryptedPassword,
          SystemName: 'admin-app'
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IUserData>) => ({
        ...response.Response.Data
      })
    }),
    crypt: build.query<ICrypt, void>({
      query: () => ({
        url: 'v1/crypt'
      })
    }),
    loginAs: build.query<any, IQueryLogin>({
      query: (payload?: IQueryLogin) => ({
        url: `/v2/Auth/login`,
        body: {
          DeviceId: getCookieByName(cookiesEnum.DeviceId) || generateUUID(),
          Login: payload?.Login,
          LoginAs: payload?.LoginAs,
          Password: payload?.Password,
          SystemName: 'admin-app'
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<any>) => ({
        ...response.Response.Data
      })
    }),

    bulkForgot: build.query<IBulkForgot, number[]>({
      query: (payload?: number[]) => ({
        url: `/v2/Auth/BulkForgot`,
        body: {
          ids: payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IBulkForgot>) => ({
        ...response.Response.Data
      })
    }),
    checkUserExistField: build.query<ICheckExistFieldResponse, ICheckExistFieldRequest>({
      query: (payload: ICheckExistFieldRequest) => ({
        params: {
          ...payload
        },
        url: `/v2/Auth/userexists`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<ICheckExistFieldResponse>) => ({
        ...response.Response.Data
      })
    }),
    resetPassword: build.query<IResetPasswordResponse, IResetPasswordRequest>({
      query: (payload: IResetPasswordRequest) => ({
        url: `/v2/Auth/resetpassword`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IResetPasswordResponse>) => ({
        ...response.Response.Data
      })
    }),
    updatePasswordWithCode: build.query<
      IUpdatePasswordWithCodeResponse,
      IUpdatePasswordWithCodeRequest
    >({
      query: (payload: IUpdatePasswordWithCodeRequest) => ({
        url: `/v2/Auth/checkcodewithsetpasswordbyuser`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IUpdatePasswordWithCodeResponse>) => ({
        ...response.Response.Data
      })
    }),
    checkUserWithCode: build.query<ICheckExistCodeResponse, ICheckExistCodeRequest>({
      query: (payload: ICheckExistCodeRequest) => ({
        url: `/v2/Auth/CheckSetPasswordCode?code=${payload.Code}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<ICheckExistCodeResponse>) => ({
        ...response.Response.Data
      })
    }),
    checkInvitationLinkCode: build.query<ICheckExistRegisterCodeResponse, ICheckExistCodeRequest>({
      query: (payload: ICheckExistCodeRequest) => ({
        params: {
          code: payload.Code
        },
        url: `/v2/invitation/validate`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<ICheckExistRegisterCodeResponse>) => ({
        ...response.Response.Data
      })
    }),
    createInvitationLink: build.query<IInvitationListResponse, ICreateInvitationLinkProps>({
      query: (payload: ICreateInvitationLinkProps) => ({
        body: payload,
        url: `/v2/invitation/link`,
        method: 'POST'
      }),
      transformResponse: (response: IFullResponse<IInvitationListResponse>) => ({
        ...response.Response.Data
      })
    }),
    updateInvitationLink: build.query<IInvitationListResponse, IUpdateInvitationLinkProps>({
      query: (payload: IUpdateInvitationLinkProps) => ({
        url: `/v2/invitation`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IInvitationListResponse>) => ({
        ...response.Response.Data
      })
    }),
    deleteInvitationLink: build.mutation<void, string>({
      query: (code: string) => ({
        params: {
          code
        },
        url: `/v2/invitation`,
        method: 'delete'
      })
    }),
    getInvitationLinksList: build.query<IInvitationListResponse, number>({
      query: (customerId: number) => ({
        params: {
          customerId
        },
        url: `/v2/invitation`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<IInvitationListResponse>) => ({
        ...response.Response.Data
      })
    }),
    checkEmailExists: build.query<IUserExistsResponse, IUserExistsQuery>({
      query: (payload?: IUserExistsQuery) => ({
        url: `/v2/Auth/emailexists?email=${payload?.email}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<IUserExistsResponse>) => ({
        ...response.Response.Data
      })
    }),
    checkCanCreateUser: build.query<ICanCreateUserResponse, { Customerid: number }>({
      query: (payload?: { Customerid: number }) => ({
        params: {
          Customerid: payload?.Customerid
        },
        url: `/v2/customer/iscanadduser`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<ICanCreateUserResponse>) => ({
        ...response.Response.Data
      })
    })
  })
});

export const {
  useLazyLoginQuery,
  useLazyCryptQuery,
  useLazyBulkForgotQuery,
  useLazyCheckUserExistFieldQuery,
  useLazyResetPasswordQuery,
  useLazyUpdatePasswordWithCodeQuery,
  useLazyCheckUserWithCodeQuery,
  useLazyLoginAsQuery,
  useGetInvitationLinksListQuery,
  useLazyGetInvitationLinksListQuery,
  useDeleteInvitationLinkMutation,
  useLazyCreateInvitationLinkQuery,
  useLazyUpdateInvitationLinkQuery,
  useLazyCheckInvitationLinkCodeQuery,
  useLazyCheckEmailExistsQuery,
  useLazyCheckCanCreateUserQuery
} = authApi;
