import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  dropdown: {
    padding: '8px',
    marginTop: '10px'
  },

  menuItem: {
    padding: '6px 0',
    alignItems: 'center'
  },

  itemLabel: {
    fontSize: '16px',
    alignItems: 'center'
  },

  itemIcon: {
    marginRight: '11px'
  }
}));
