import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Flex, Radio, SimpleGrid, Text, TextInput } from '@mantine/core';
import { useStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux/redux';
import { z } from 'zod';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { ICommonSettingsEnum, ITeamSettings } from '@/entities/admin-app/commonSettings';
import { useSaveCommonSettings } from '@/containers/pages/commonSettings/hooks/useSaveCommonSettings';
import { CommonSectionTemplate } from '@components/section-template';
import { IconCopy, IconRefresh } from '@tabler/icons';
import { teamSettingsSync } from '@/containers/pages/commonSettings/hooks/TeamSettingsSync';
import { copyToClipboard } from '@/containers/pages/user/utils';
import { universalNotification } from '@/lib/utils/notification';
import { useMediaQuery } from '@mantine/hooks';

const IntegrationsPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { TeamSettings } = useAppSelector((state) => state.commonSettings);

  const updateSettings = useSaveCommonSettings();
  const { isTeamSync, SyncTeamSettings } = teamSettingsSync();

  const enabledScheme = z.object({
    IsEnabled: z.literal(true),
    Url: z.string().min(1, { message: 'Введите ссылку' })
  });
  const disabledScheme = z.object({
    IsEnabled: z.literal(false),
    Url: z.string().nullable()
  });

  const validateScheme = z.union([enabledScheme, disabledScheme]);

  const form = useForm<ITeamSettings>({
    initialValues: {
      IsEnabled: false,
      Url: '',
      ClientId: '',
      ClientSecret: '',
      RabbitData: null
    },
    validateInputOnChange: true,
    validate: (values: ITeamSettings): FormErrors => getValidateErrors(validateScheme, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;
  const isMobile = useMediaQuery('(max-width: 576px)');

  const [copiedField, setCopiedField] = useState<string | null>(null);

  useEffect(() => {
    if (TeamSettings) {
      const formData = {
        Url: TeamSettings?.Url || '',
        IsEnabled: TeamSettings?.IsEnabled || false,
        ClientId: TeamSettings?.ClientId || '',
        ClientSecret: TeamSettings?.ClientSecret || '',
        RabbitData: TeamSettings?.RabbitData || null
      };

      form.setValues(formData);
      form.resetDirty(formData);
    }
  }, [TeamSettings]);

  const handleSubmit = async (values: ITeamSettings) => {
    await updateSettings(ICommonSettingsEnum.Commands, {
      TeamSettings: {
        IsEnabled: values.IsEnabled,
        Url: values.IsEnabled ? values.Url : TeamSettings.Url,
        ClientId: values.IsEnabled ? values.ClientId : TeamSettings.ClientId,
        ClientSecret: values.IsEnabled ? values.ClientSecret : TeamSettings.ClientSecret,
        RabbitData: values.IsEnabled ? values.RabbitData : TeamSettings.RabbitData
      }
    });
  };

  const copyText = async (textToCopy: string | null, field: string) => {
    if (textToCopy) {
      try {
        await copyToClipboard(textToCopy);
        setCopiedField(field);
        universalNotification({
          isError: false,
          error: null,
          isSuccess: true,
          successTitle: t('copied')
        });
      } catch (e) {
        setCopiedField(null);
      }
    }
  };

  const getColor = (field: string) => (copiedField === field ? 'teal' : 'blue');

  const handleChange = () => {
    setCopiedField(null);
    if (!form.values.Url?.length) {
      form.setValues({ IsEnabled: false, Url: '', ClientId: '', ClientSecret: '' });
    } else {
      form.setValues({ IsEnabled: false });
    }
  };

  const handleRefresh = async () => {
    if (TeamSettings) {
      await SyncTeamSettings();
    }
  };

  return (
    <CommonSectionTemplate title={t('team')}>
      <Box sx={{ width: '100%' }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio
            className={classes.radio}
            size="xs"
            checked={!form?.values?.IsEnabled}
            onChange={() => handleChange()}
            label={t('disable')}
          />
          <Radio
            className={classes.radio}
            size="xs"
            mb="sm"
            checked={form?.values?.IsEnabled}
            onChange={() => form.setValues({ IsEnabled: true })}
            label={t('enable')}
          />

          {form?.values?.IsEnabled ? (
            <>
              <SimpleGrid cols={isMobile ? 1 : 2} w="100%" className={classes.clientContainer}>
                <TextInput
                  className={classes.textFieldInput}
                  label={t('link')}
                  withAsterisk
                  {...form.getInputProps(`Url`)}
                />
              </SimpleGrid>
              <SimpleGrid cols={isMobile ? 1 : 2} w="100%" className={classes.clientContainer}>
                <TextInput
                  label="ClientId"
                  className={classes.textFieldInput}
                  classNames={{
                    input: classes.copyInput
                  }}
                  rightSection={
                    <IconCopy
                      cursor="pointer"
                      color={getColor('ClientId')}
                      onClick={() => copyText(form.values.ClientId, 'ClientId')}
                    />
                  }
                  readOnly
                  data-cy="clientId"
                  {...form.getInputProps(`ClientId`)}
                />
                <TextInput
                  label="ClientSecret"
                  className={classes.textFieldInput}
                  classNames={{
                    input: classes.copyInput
                  }}
                  rightSection={
                    <IconCopy
                      cursor="pointer"
                      color={getColor('ClientSecret')}
                      onClick={() => copyText(form.values.ClientSecret, 'ClientSecret')}
                    />
                  }
                  readOnly
                  data-cy="clientSecret"
                  {...form.getInputProps(`ClientSecret`)}
                />
              </SimpleGrid>
            </>
          ) : null}
          {form?.values?.IsEnabled && form.values?.RabbitData !== null ? (
            <SimpleGrid cols={isMobile ? 1 : 2} w="100%" className={classes.clientContainer}>
              <TextInput
                label={t('teams.rabbitUser')}
                className={classes.textFieldInput}
                classNames={{
                  input: classes.copyInput
                }}
                rightSection={
                  <IconCopy
                    cursor="pointer"
                    color={getColor('RabbitData.Name')}
                    onClick={() => copyText(form.values?.RabbitData?.Name ?? '', 'RabbitData.Name')}
                  />
                }
                readOnly
                data-cy="rabbitUser"
                {...form.getInputProps(`RabbitData.Name`)}
              />
              <TextInput
                label={t('teams.rabbitPassword')}
                className={classes.textFieldInput}
                classNames={{
                  input: classes.copyInput
                }}
                rightSection={
                  <IconCopy
                    cursor="pointer"
                    color={getColor('RabbitData.Password')}
                    onClick={() =>
                      copyText(form.values?.RabbitData?.Password ?? '', 'RabbitData.Password')
                    }
                  />
                }
                readOnly
                data-cy="rabbitPassword"
                {...form.getInputProps(`RabbitData.Password`)}
              />
            </SimpleGrid>
          ) : (
            <Text size="sm" c="#667085" mt="sm">
              {t('teams.rabbitInfo')}
            </Text>
          )}

          <Flex className={classes.sectionControls} w="100%">
            <Button
              disabled={isDisabled}
              className={classes.submitButton}
              size="sm"
              radius={8}
              type="submit"
              mt="md"
            >
              {t('save')}
            </Button>

            {TeamSettings?.IsEnabled ? (
              <Button
                size="sm"
                radius={8}
                color="green"
                mt="md"
                rightSection={
                  <IconRefresh size={24} className={isTeamSync ? classes.rotate : ''} />
                }
                onClick={() => handleRefresh()}
                disabled={isTeamSync}
              >
                {t('sync')}
              </Button>
            ) : null}
          </Flex>
        </form>
      </Box>
    </CommonSectionTemplate>
  );
};

export default IntegrationsPage;
