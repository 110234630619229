import { Global } from '@mantine/emotion';
import React from 'react';

export const CustomFonts: React.FC = () => {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            src: `url('/fonts/subset-Roboto-Medium.woff2') format('woff2'), url('/fonts/subset-Roboto-Medium.woff') format('woff'), url('/fonts/subset-Roboto-Medium.ttf') format('truetype')`,
            fontDisplay: 'swap'
          }
        },
        {
          '@font-face': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            src: `url('/fonts/subset-Roboto-Bold.woff2') format('woff2'), url('/fonts/subset-Roboto-Bold.woff') format('woff'), url('/fonts/subset-Roboto-Bold.ttf') format('truetype')`,
            fontDisplay: 'swap'
          }
        },
        {
          '@font-face': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            src: `url('/fonts/subset-Roboto-Regular.woff2') format('woff2'), url('/fonts/subset-Roboto-Regular.woff') format('woff'), url('/fonts/subset-Roboto-Regular.ttf') format('truetype')`,
            fontDisplay: 'swap'
          }
        }
      ]}
    />
  );
};
