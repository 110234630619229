import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { Drawer } from '@mantine/core';

import { IRowData } from '@components/simple-table/types';
import { Panels } from './panels';

import { useAppSelector } from '@hooks/redux/redux';
import { useActions } from '@hooks/redux/action';

import { useStyles } from './styles';

interface IFilialsInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const FilialsInfoMain: FC<IFilialsInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const { setSelectedFilialsId } = useActions();
  const { selectedFilialsId } = useAppSelector((state) => state.filials);

  const handleClose = useCallback(() => {
    setSelectedFilialsId(0);
  }, []);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <Drawer
      data-cy="filialsInfoDrawer"
      zIndex={1002}
      opened={Boolean(selectedFilialsId)}
      trapFocus={false}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {Boolean(selectedFilialsId) && (
        <Panels selectedFilialsId={selectedFilialsId} setRowData={setRowData} />
      )}
    </Drawer>
  );
};
