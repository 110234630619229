import React, { FC, useState } from 'react';
import { Box, Button, Flex, Loader, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { EmailInfoPanelsEnum } from '@/types/enums/modules';
import { ModulesEnum } from '@/types/enums/filials';
import { ErrorBoundary } from '@components/error-boundary';
import { SelectUserModal } from '@components/modal/select-user';
import { SelectGroupSimpleModal } from '@components/modal/select-group-simple';
import { Badges } from '../../badges';
import {
  useGetModuleRolesQuery,
  useGetModuleUsersQuery,
  useAddModuleRoleMutation,
  useAddModuleUserMutation,
  useDeleteModuleRoleMutation,
  useDeleteModuleUserMutation
} from '@/entities/admin-app/modules/api';
import { IUserTableData } from '@/entities/admin-app/users';
import { themeColors } from '@/theme/colors';
import { useStyles } from '@/containers/pages/filials/edit/styles';
import { useTranslation } from 'react-i18next';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';

interface IModuleProps {
  currentModule: ModulesEnum;
  filial?: IFilialsInfoCommon | null;
}
const CommonModuleContainer: FC<IModuleProps> = ({ currentModule, filial }) => {
  const { t } = useTranslation();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<IUserTableData | null>(null);

  const { data: rolesData, /*error,*/ isLoading: areRolesLoading } = useGetModuleRolesQuery({
    module: currentModule,
    id: filial?.Id
  });

  const { data: usersData, /*error,*/ isLoading: areUsersLoading } = useGetModuleUsersQuery({
    module: currentModule,
    id: filial?.Id
  });

  const [addRole] = useAddModuleRoleMutation();
  const [addUser] = useAddModuleUserMutation();
  const [deleteRole] = useDeleteModuleRoleMutation();
  const [deleteUser] = useDeleteModuleUserMutation();

  const { classes } = useStyles();

  const closeModals = () => {
    setIsUserModalOpen(false);
    setIsRoleModalOpen(false);
  };

  const handleConfirmSelectUserModal = async () => {
    if (!selectedUser) {
      return;
    }
    await addUser({
      module: currentModule,
      userId: selectedUser.Id
    });
    closeModals();
  };

  return (
    <ErrorBoundary componentTitle="Modules page">
      <Flex className={classes.container} direction="column">
        <Box className={classes.section} data-section={EmailInfoPanelsEnum.byRoles}>
          <Text className={classes.title}>{t('filials.modules.rolesAccess')}</Text>

          <Text className={classes.description} fz="sm" mt="xs">
            {t('filials.modules.rolesHint')}
          </Text>

          <Button
            className={classes.addButton}
            size="sm"
            mt="sm"
            variant="outline"
            rightSection={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() => setIsRoleModalOpen(true)}
          >
            {t('filials.modules.addRole')}
          </Button>

          <Box mt={16}>
            {areRolesLoading ? (
              <Loader size="sm" />
            ) : (
              <Badges
                data={rolesData || []}
                onRemove={async (roleId) => {
                  await deleteRole({
                    module: currentModule,
                    roleId
                  });
                }}
              />
            )}
          </Box>
        </Box>

        <Box className={classes.section} data-section={EmailInfoPanelsEnum.byUsers}>
          <Text className={classes.title}>{t('filials.modules.userAccess')}</Text>

          <Text className={classes.description} fz="sm" mt="xs">
            {t('filials.modules.userHint')}
          </Text>

          <Button
            className={classes.addButton}
            size="sm"
            mt="sm"
            variant="outline"
            rightSection={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() => setIsUserModalOpen(true)}
          >
            {t('filials.modules.addUser')}
          </Button>

          <Box mt={16}>
            {areUsersLoading ? (
              <Loader size="sm" />
            ) : (
              <Badges
                data={usersData || []}
                onRemove={async (userId) => {
                  deleteUser({
                    module: currentModule,
                    userId
                  });
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>

      <SelectGroupSimpleModal
        onClose={closeModals}
        isOpened={isRoleModalOpen}
        title={t('filials.modules.addRole')}
        setGroupId={async (id: number) => {
          await addRole({
            module: currentModule,
            roleId: id
          });
          closeModals();
        }}
        filial={filial}
      />

      <SelectUserModal
        isOpened={isUserModalOpen}
        title={t('filials.modules.addUserInGroup')}
        onClose={closeModals}
        setSelectedUsers={setSelectedUser}
        multipleUsers={false}
        userId={selectedUser?.Id}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: t('add'),
            fn: handleConfirmSelectUserModal
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: closeModals
          }
        }}
        filial={filial}
      />
    </ErrorBoundary>
  );
};

export default CommonModuleContainer;
