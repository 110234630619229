import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  dnd: {
    padding: 0,
    boxShadow: '0px 16px 64px 0px rgba(0, 0, 0, 0.12)',
    maxHeight: '65vh',
    overflowY: 'auto'
  }
}));
