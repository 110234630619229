import React, { FC } from 'react';
import { Box, Indicator } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { UserStatusEnum } from '@/types/enums/user';
import { IPosition } from '@/entities/admin-app/user';
import OverflowTip from '@components/OverflowTip';
import { UserAvatar } from '@/components/user-avatar';
import { IconX } from '@tabler/icons';

interface IItemUserProps {
  item?: IRowData;
}

const ItemUser: FC<IItemUserProps> = ({ item }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.user}>
      <Indicator
        label={item?.Status === UserStatusEnum.Ban ? <IconX size="8" stroke="4" /> : ''}
        size={item?.Status === UserStatusEnum.Ban ? 15 : 0}
        offset={6}
        position="bottom-start"
        color="red"
      >
        <UserAvatar user={item} size={40} src={item?.Avatar} />
      </Indicator>
      <Box className={classes.content} maw="200px">
        <Box maw="200px">
          <OverflowTip size="sm" fw={600} color="#101828">
            {item?.Name}
          </OverflowTip>
        </Box>
        {item?.Positions?.length
          ? item?.Positions?.slice(0, 2).map((position: IPosition, index: number) => (
              <Box key={index} maw="200px">
                <OverflowTip size="xs" fw={400}>
                  {position?.Position}
                </OverflowTip>
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default React.memo(ItemUser);
