import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: theme.spacing.xl
  },
  logoContainer: {
    marginBottom: theme.spacing.xl,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  descriptionTitle: {
    fontSize: '32px',
    lineHeight: '100%',
    fontWeight: 500,
    marginBottom: theme.spacing.sm
  },
  descriptionText: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[6],
    marginBottom: theme.spacing.xl
  },

  buttonText: {
    margin: theme.spacing.xl,
    padding: theme.spacing.xl
  },
  closeButton: {
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  }
}));
