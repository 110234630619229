import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from './constants';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    borderBottom: '1px solid var(--mantine-color-gray-2)',
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1001,
    backgroundColor: theme.white
  },

  dashboard: {
    backgroundColor: '#F5F6F8',
    borderBottom: 'none'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  inner: {
    height: HEADER_HEIGHT,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm
  },

  burger: {
    [utils.largerThan('lg')]: {
      display: 'none'
    }
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '& img': {
      display: 'block'
    },
    [utils.smallerThan('xs')]: {
      width: '190px',
      maxWidth: '190px',

      img: {
        maxWidth: '100%'
      }
    }
  },

  links: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `calc(100% - 400px)`,
    [utils.smallerThan('lg')]: {
      display: 'none'
    }
  },
  link: {
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    display: 'block',
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,

    [utils.dark]: {
      color: theme.colors.dark[1]
    },
    [utils.light]: {
      color: theme.colors.gray[6]
    },

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[6],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[0],
        color: theme.black
      },
      textDecoration: 'none'
    },
    [utils.smallerThan('lg')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: 0,
      padding: theme.spacing.md
    },

    '@media (hover: hover)': {
      '&:hover': {
        [utils.dark]: {
          backgroundColor: theme.colors.dark[6]
        },
        [utils.light]: {
          backgroundColor: theme.colors.gray[0]
        }
      }
    },
    '@media (hover: none)': {
      '&:active': {
        [utils.dark]: {
          backgroundColor: theme.colors.dark[6]
        },
        [utils.light]: {
          backgroundColor: theme.colors.gray[0]
        }
      }
    }
  },

  linkActive: {
    [utils.dark]: {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[6]
    },
    [utils.light]: {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[6]
    }

    // '&, &:hover': {
    //   backgroundColor: theme.fn.variant({
    //     variant: 'light',
    //     color: theme.primaryColor
    //   }).background,
    //   color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
    // }
  },
  linksGroup: {
    flexWrap: 'nowrap'
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [utils.largerThan('lg')]: {
      display: 'none'
    }
  }
}));
