import { createContext, Dispatch, FC, SetStateAction } from 'react';

import { BaseInfo, DrawerHeader, PhotoInfo } from '../components';

import { IRowData } from '@components/simple-table/types';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';

import { useGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';

export interface IFilialsInfoMainContext {
  selectedFilialsId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  filialsInfoCommon?: IFilialsInfoCommon;
}

export const FilialsInfoMainContext = createContext<IFilialsInfoMainContext>(
  {} as IFilialsInfoMainContext
);

export interface IPanelsProps {
  selectedFilialsId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const Panels: FC<IPanelsProps> = ({ selectedFilialsId, setRowData }) => {
  const { data } = useGetFilialsInfoCommonQuery({
    id: selectedFilialsId
  });

  const initialValues: IFilialsInfoMainContext = {
    selectedFilialsId,
    setRowData,
    filialsInfoCommon: data
  };

  return (
    <FilialsInfoMainContext.Provider value={initialValues}>
      <DrawerHeader />
      <PhotoInfo filialsInfoCommon={data} />
      <BaseInfo />
    </FilialsInfoMainContext.Provider>
  );
};
