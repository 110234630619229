import React, { FC } from 'react';
import { Box } from '@mantine/core';
import { useStyles } from './styles';
import { IRole } from '@/entities/admin-app/directories';
import OverflowTip from '@/components/OverflowTip';

interface IRolesListProps {
  roles: IRole[] | null | undefined;
}

export const RolesList: FC<IRolesListProps> = ({ roles }) => {
  const { classes } = useStyles();

  if (!Array.isArray(roles)) {
    return null;
  }

  return (
    <Box className={classes.listGroup}>
      {roles?.slice(0, 5).map((role, index) => {
        return (
          <Box key={index} maw={200} mr="xs">
            <OverflowTip data-text={role?.Name} className={classes.rounded}>
              {role?.Name}
            </OverflowTip>
          </Box>
        );
      })}
    </Box>
  );
};
