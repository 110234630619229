import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  changeBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '4px',
    border: '0.8px solid #DEE2E6    ',
    padding: `8px ${theme.spacing.sm}`,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    }
  }
}));
