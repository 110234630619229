import { FC } from 'react';
import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconChevronDown } from '@tabler/icons';
import { ConnectionTypeOptions } from '@/containers/pages/filials/edit/utiils';

export interface ISelectProps {
  label?: string;
  placeholder: string;
  value?: number | null;
  form?: UseFormReturnType<any>;
  fieldName: string;
  disabled?: boolean;
}

export const ConnectionsSelect: FC<ISelectProps> = ({
  label,
  placeholder,
  value,
  form,
  fieldName,
  disabled
}) => {
  const handleChangeConnection = (query: string | null) => {
    form?.setFieldValue(fieldName, query ? Number(query) : null);
  };

  return (
    <Select
      data={ConnectionTypeOptions}
      required
      allowDeselect={false}
      disabled={disabled}
      rightSectionWidth={30}
      rightSection={<IconChevronDown size="1rem" color="#98A2B3" />}
      label={label}
      value={value?.toString() || ''}
      placeholder={placeholder}
      onChange={handleChangeConnection}
      error={form?.getInputProps(fieldName).error}
      w="100%"
      comboboxProps={{ zIndex: 1300 }}
      styles={() => ({
        rightSection: { pointerEvents: 'none' }
      })}
    />
  );
};
