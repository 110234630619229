import React, { FC, useEffect } from 'react';
import { Button, Flex, LoadingOverlay, Radio, SimpleGrid } from '@mantine/core';
import { useStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { IProductNotification } from '@/entities/admin-app/commonSettings';
import { CommonSectionTemplate } from '@components/section-template';
import { useGetProductNotificationQuery } from '@/entities/admin-app/commonSettings/api';
import { validateSchemeProductNotification } from '@/containers/pages/commonSettings/schemes/validate';
import { useSaveProductNotification } from '@/containers/pages/commonSettings/hooks/useSaveProductNotification';
import { DatePicker } from '@components/fields/date-picker';
import { useMediaQuery } from '@mantine/hooks';
import { TextFieldInput } from '@components/fields/text-field-input';
import { universalNotification } from '@/lib/utils/notification';

const ProductNotificationsPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    data: productNotificationData,
    isFetching,
    isError,
    error
  } = useGetProductNotificationQuery();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const updateProductNotification = useSaveProductNotification();

  const form = useForm<IProductNotification>({
    initialValues: {
      UpdateId: '',
      Version: '',
      ReleaseDate: '',
      UpdateDate: '',
      ImageUrl: '',
      Topic: '',
      Text: '',
      UpdateUrl: '',
      IsEnabled: false
    },
    validateInputOnChange: true,
    validate: (values: IProductNotification): FormErrors =>
      getValidateErrors(validateSchemeProductNotification, values)
  });
  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (productNotificationData) {
      const formData = {
        UpdateId: productNotificationData?.UpdateId || '',
        Version: productNotificationData?.Version || '',
        ReleaseDate: productNotificationData?.ReleaseDate || '',
        UpdateDate: productNotificationData?.UpdateDate || '',
        ImageUrl: productNotificationData?.ImageUrl || '',
        Topic: productNotificationData?.Topic || '',
        Text: productNotificationData?.Text || '',
        UpdateUrl: productNotificationData?.UpdateUrl || '',
        IsEnabled: productNotificationData?.IsEnabled || false
      };

      form.setValues(formData);
      form.resetDirty(formData);
    }
  }, [productNotificationData]);

  useEffect(() => {
    if (isError && error && !isFetching) {
      universalNotification({
        error: error,
        isError: isError,
        isSuccess: false
      });
    }
  }, [error, isError, isFetching]);
  const handleSubmit = async (values: IProductNotification) => {
    form.validate();
    if (!form.isValid()) return;
    await updateProductNotification({ UpdateInfo: values });
  };

  const handleChange = () => {
    form.setValues({ IsEnabled: false });
  };

  return (
    <CommonSectionTemplate title={t('commonSettings.systemNotification')}>
      <SimpleGrid cols={1} w="100%">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Flex>
            <Radio
              className={classes.radio}
              size="xs"
              mr="md"
              checked={!form?.values?.IsEnabled}
              onChange={() => handleChange()}
              label={t('disable')}
            />
            <Radio
              className={classes.radio}
              size="xs"
              mb="sm"
              checked={form?.values?.IsEnabled}
              onChange={() => form.setValues({ IsEnabled: true })}
              label={t('enable')}
            />
          </Flex>

          {form?.values?.IsEnabled ? (
            <>
              <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
                <TextFieldInput
                  fieldName="Topic"
                  label={t('commonSettings.title')}
                  form={form}
                  withAsterisk={true}
                />
                <TextFieldInput
                  fieldName="Text"
                  label={t('commonSettings.textMessage')}
                  form={form}
                  withAsterisk={true}
                />
              </SimpleGrid>
              <SimpleGrid cols={isMobile ? 1 : 2} w="100%" mt={16}>
                <TextFieldInput
                  fieldName="Version"
                  label={t('commonSettings.version')}
                  form={form}
                  withAsterisk={true}
                />
                <TextFieldInput
                  fieldName="UpdateId"
                  label={t('commonSettings.updateId')}
                  form={form}
                  withAsterisk={true}
                />
              </SimpleGrid>
              <SimpleGrid cols={isMobile ? 1 : 2} w="100%" mt={16}>
                <TextFieldInput
                  fieldName="UpdateUrl"
                  label={t('commonSettings.updateUrl')}
                  form={form}
                  withAsterisk={true}
                />
                <TextFieldInput
                  fieldName="ImageUrl"
                  label={t('commonSettings.imageUrl')}
                  form={form}
                  withAsterisk={true}
                />
              </SimpleGrid>
              <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
                <DatePicker
                  fieldName="ReleaseDate"
                  label={t('commonSettings.releaseDate')}
                  form={form}
                  withAsterisk={true}
                />

                <DatePicker
                  fieldName="UpdateDate"
                  label={t('commonSettings.updateDate')}
                  form={form}
                  withAsterisk={true}
                />
              </SimpleGrid>
            </>
          ) : null}

          <Flex className={classes.sectionControls} w="100%">
            <Button
              disabled={isDisabled}
              className={classes.submitButton}
              size="sm"
              radius={8}
              type="submit"
              mt="md"
            >
              {t('save')}
            </Button>
          </Flex>
        </form>
      </SimpleGrid>
      <LoadingOverlay visible={isFetching} />
    </CommonSectionTemplate>
  );
};

export default ProductNotificationsPage;
