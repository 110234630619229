import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    backgroundColor: theme.colors.white,
    marginLeft: theme.spacing.md,
    marginBottom: theme.spacing.md
  },
  filterRow: {
    display: 'flex',
    position: 'absolute',
    right: '16px',
    top: '-15px',
    width: '20%',
    minWidth: '100px',
    margin: '10px 0 20px 0',
    alignSelf: 'flex-end'
  },

  group: {
    marginTop: theme.spacing.xs,
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },

  groupTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    // paddingLeft: '24px',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'left'
  },

  groupOtherTitle: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    // paddingLeft: '24px',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'left'
  },

  cardsContainer: {
    alignSelf: 'flex-start',
    gap: '0'
  },

  card: {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: '16px',
    marginTop: '16px',
    border: '1px solid #F0F0F0',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '16px'
  },

  firstColumn: {
    marginLeft: '8px',
    width: '40px'
  },

  secColumn: {
    width: '248px',
    marginLeft: '16px'
  },

  deviceIcon: {},

  hash: {
    marginLeft: '6px',
    marginTop: '4px',
    cursor: 'pointer'
  },

  greyText: {
    fontSize: '14px',
    color: '#868E96'
  },

  productText: {
    color: '#343A40',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left'
  },

  deactivateButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    fontWeight: 400,
    color: '#C92A2A',
    '&:hover': {
      backgroundColor: 'transparent'
    },

    '& svg': {
      stroke: '1.5',
      transform: 'rotate(90deg)',
      width: '20px',
      height: '20px',
      marginLeft: '-11px'
    }
  },

  activateButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: 'transparent'
    },

    '& svg': {
      stroke: '1.5',
      width: '20px',
      height: '20px',
      marginLeft: '-11px',
      transform: 'none'
    }
  },

  paginate: {
    // backgroundColor: theme.white,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'nowrap',
    '& .mantine-Pagination-control': {
      border: 'none',
      borderRadius: '0px',
      padding: '23px 28px',
      '&[data-active]': {
        borderBottom: '2px solid #101828',
        backgroundColor: 'transparent',
        color: theme.black,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '&[data-active]:not([data-disabled]):hover': {
        backgroundColor: 'transparent'
      }
    },
    padding: '23px 28px'
  },
  searchWrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0 20px 0'
  },
  search: {
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      // paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    }
  }
}));
