import { IStatisticsInitialFilters } from '@/types/enums/statistics';

export const listSizes = [25, 50, 100] as const;

export const initialStatisticFilter: IStatisticsInitialFilters = {
  Offset: 0,
  SortOrder: 1,
  SortField: 0,
  FilterConditions: [],
  Query: '',
  Count: listSizes[0]
};
