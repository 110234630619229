import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  pageHeight: {
    '@supports(-webkit-touch-callout: none)': {
      height: '-webkit-fill-available'
    }
  },
  containerBox: {
    margin: '50px 0'
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    marginBottom: '24px',
    img: {
      maxWidth: '100%'
    }
  },
  logo: {},
  box: {
    padding: '40px 32px 32px',
    [utils.smallerThan('xs')]: {
      padding: '26px'
    }
  },
  title: {
    color: theme.colors.gray[8],
    marginBottom: '5px'
  }
}));
