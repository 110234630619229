import { useCallback } from 'react';
import { useLazyEmailActivationQuery } from '@/entities/admin-app/email/api';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { useTranslation } from 'react-i18next';
import { showNotification } from '@mantine/notifications';

export const useEmailActivation = () => {
  const { t } = useTranslation();
  const [fetchEmailActivation, { isLoading }] = useLazyEmailActivationQuery();

  const sendEmailActivation = useCallback(async (selection: number[]) => {
    const numbersSelection = selection?.map((item) => Number(item)) ?? [];

    try {
      const { isSuccess, isError, error } = await fetchEmailActivation(numbersSelection);

      if (error && isError) {
        // ToDo error object has different structure depends on request
        const codeError = extractErrorCode(error);
        showNotification({
          title: t('sendLetter.errors.notSent'),
          message: generateErrorMessage(codeError),
          autoClose: true,
          color: 'red'
        });
      }

      if (isSuccess) {
        showNotification({
          title: t(
            `sendLetter.${
              numbersSelection?.length === 1
                ? 'oneEmailActivationSuccessMessage'
                : 'multipleEmailsActivationSuccessMessage'
            }`
          ),
          message: '',
          autoClose: true,
          color: 'green'
        });
      }
      // universalNotification({
      //   isSuccess: isSuccess,
      //   isError: isError,
      //   error: codeError,
      //   errorMessage: generateErrorMessage(codeError),
      //   errorTitle: t('sendLetter.errors.notSent'),
      //   successMessage: t(
      //     `'sendLetter.'${
      //       numbersSelection?.length === 1
      //         ? 'oneEmailActivationSuccessMessage'
      //         : 'multipleEmailsActivationSuccessMessage'
      //     }`
      //   )
      // });
    } catch (err: any) {
      console.warn('fetchEmailActivation ERROR', err);
    }
  }, []);

  return { sendEmailActivation, isLoading };
};
