import { Button, ButtonProps, Box, BoxProps, Text, ActionIcon } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { ConfirmLabels } from '@mantine/modals/lib/context';
import { FC, ReactNode } from 'react';
import { CloseIcon } from '../../../assets/icons';
import { useStyles } from './styles';
import { defaultButtonSx } from './utils';

type DeleteModalTypeProps = ContextModalProps<{
  modalBody?: ReactNode;
  modalBodyProps?: BoxProps;
  labelBody: string;
  confirmProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
  cancelProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
  labels: ConfirmLabels;
  modalFooterProps?: BoxProps;
  title?: string;
  onCancel?: () => Promise<void>;
  onConfirm?: () => Promise<void>;
}>;

export const ContextModal: FC<DeleteModalTypeProps> = ({ context, id, innerProps }) => {
  const { classes, cx } = useStyles();
  const { closeModal } = context;
  const {
    onCancel,
    title,
    modalBodyProps,
    modalBody,
    labels,
    confirmProps,
    cancelProps,
    modalFooterProps,
    onConfirm
  } = innerProps;

  const handleClose = async () => {
    if (onCancel) {
      await onCancel();
    }

    closeModal(id);
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }

    closeModal(id);
  };

  const { sx: cancelPropsSx } = { ...cancelProps };
  const { sx: confirmPropsSx } = { ...confirmProps };

  const styleConfirmBtn = {
    ...confirmPropsSx,
    ...defaultButtonSx(confirmPropsSx)
  };

  const styleCancelBtn = {
    ...cancelPropsSx,
    ...defaultButtonSx(cancelPropsSx)
  };

  return (
    <Box {...modalBodyProps} className={cx(classes.root, modalBodyProps?.className)}>
      <Box className={classes.header}>
        <Text className={classes.headerText}>{title}</Text>
        <ActionIcon variant="transparent" onClick={handleClose}>
          <CloseIcon fill="#667085" />
        </ActionIcon>
      </Box>
      {modalBody}
      <Box className={cx(classes.footer, modalFooterProps?.className)}>
        {labels.cancel && (
          <Button {...cancelProps} sx={styleCancelBtn} onClick={handleClose}>
            {labels.cancel}
          </Button>
        )}
        {labels.confirm && (
          <Button {...confirmProps} sx={styleConfirmBtn} onClick={handleConfirm}>
            {labels.confirm}
          </Button>
        )}
      </Box>
    </Box>
  );
};
