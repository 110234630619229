import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    marginBottom: theme.spacing.sm,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%'
  },

  leftSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '80%',
    [utils.largerThan('xs')]: {
      justifyContent: 'space-between'
    }
  },

  group: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '40%',
    [utils.largerThan('xs')]: {
      width: '20%'
    }
  },

  title: {
    fontSize: '14px'
  },

  description: {
    fontSize: '12px',
    color: theme.colors.brandGray[0]
  },

  activeConnection: {
    backgroundColor: 'transparent'
  }
}));
