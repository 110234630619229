import { ITokens, IUser } from '@/entities/admin-app/auth';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { BASE_URL } from '@/entities/admin-app/constants';
import { userInfoActions } from '@/entities/admin-app/user';
import { objectToString } from '@/lib/utils';
import { store } from '@/store';

const axiosInstance = axios.create({
  baseURL: BASE_URL
});

axiosInstance.interceptors.request.use((config) => {
  const token = store.getState().userInfo.Tokens?.AuthToken;
  config.headers.authorization = token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const {
        statusText,
        status,
        data: { Response }
      } = error.response;

      switch (status) {
        case 400:
          showNotification({
            title: `${statusText} ${status}`,
            message: objectToString(Response),
            autoClose: true,
            color: 'red'
          });
          break;
        case 401:
          store.dispatch(userInfoActions.setUserTokens({} as ITokens));
          store.dispatch(userInfoActions.setUserInfo({} as IUser));

          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
          break;
        case 502:
          showNotification({
            title: 'Ошибка',
            message: 'Технические работы на сервере',
            autoClose: true,
            color: 'red'
          });
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
