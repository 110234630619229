import { IUserTableData } from '@/entities/admin-app/users';
import { Box, Flex } from '@mantine/core';
import { FC, SyntheticEvent } from 'react';
import { useStyles } from './styles';
import { themeColors } from '@/theme/colors';
import { IconTrash } from '@tabler/icons';
import OverflowTip from '@/components/OverflowTip';
import { UserAvatar } from '@/components/user-avatar';

interface IUserItemProps {
  item: IUserTableData;
  userId?: number;
  handleClick: (event: SyntheticEvent<HTMLDivElement>) => void;
  roleType: number | undefined;
  showDeleteItemIcon?: boolean;
  showUserHover: () => boolean;
}

export const UserItem: FC<IUserItemProps> = ({
  item,
  handleClick,
  showDeleteItemIcon,
  showUserHover
}) => {
  const { classes, cx } = useStyles();

  return (
    <Flex
      className={cx(classes.container, {
        [classes.hoverItem]: showUserHover()
      })}
    >
      <Box className={classes.content}>
        <UserAvatar size={40} src={item.Avatar} user={item} notClickable />
        <Box className={classes.info}>
          <OverflowTip size="sm" fw={500}>
            {`${item.Surname} ${item.FirstName}`}
          </OverflowTip>
          <OverflowTip color="dimmed" size="xs">
            {item.Email}
          </OverflowTip>
        </Box>
      </Box>
      {showDeleteItemIcon && (
        <Flex className={classes.deleteIcon} onClick={handleClick}>
          <IconTrash
            size="1.5rem"
            color={themeColors.brandGray[0]}
            stroke={1.5}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      )}
    </Flex>
  );
};
