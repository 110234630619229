import React, { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import OverflowTip from '@components/OverflowTip';

interface ITextListFieldProps {
  title: string;
  items?: string[];
  rounded?: boolean;
}

export const TextListField: FC<ITextListFieldProps> = ({ title, items, rounded }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Text className={classes.title}>{title}</Text>
      <Box
        className={cx(classes.listGroup, {
          [classes.group]: rounded
        })}
        maw="100%"
      >
        {items?.length ? (
          items?.map((item, index) => {
            return (
              <Box maw="350px" key={index} mr="xs">
                <OverflowTip
                  key={index}
                  className={cx(classes.description, {
                    [classes.rounded]: item && rounded
                  })}
                >
                  {item}
                </OverflowTip>
              </Box>
            );
          })
        ) : (
          <Text>{t('noInformation')}</Text>
        )}
      </Box>
    </Box>
  );
};
