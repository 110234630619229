import { useEffect, Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Drawer } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@/components/simple-table/types';
import { Panels } from './panels';
import { PanelsEnum } from '@/types/enums/roles';
import { useAppSelector } from '@/hooks/redux/redux';
import { useActions } from '@/hooks/redux/action';

interface IRolesInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const RolesInfoMain: FC<IRolesInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const { setSelectedRolesId } = useActions();
  const { selectedRolesId } = useAppSelector((state) => state.roles);
  const { setSelectedPanel } = useActions();

  const handleClose = useCallback(() => {
    setSelectedRolesId(0);
    setSelectedPanel(PanelsEnum.generalInformation);
  }, []);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <Drawer
      zIndex={1100}
      opened={Boolean(selectedRolesId)}
      trapFocus={false}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {Boolean(selectedRolesId) && (
        <Panels selectedRolesId={selectedRolesId} setRowData={setRowData} />
      )}
    </Drawer>
  );
};
