import { Box, Flex, Text, Divider } from '@mantine/core';
import { capitalizeFirstLetter } from '../../utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface IValidPaymentProps {
  name?: string;
  remainingDays: number;
}

export const ValidPayment: FC<IValidPaymentProps> = ({ name, remainingDays }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const borderColor = remainingDays > 5 ? '#74C0FC' : '#FCC419';

  console.log(remainingDays, 'remainingDays');

  return (
    <Box
      sx={{
        border: `4px solid ${borderColor}`
      }}
      className={classes.boxContainer}
    >
      <Flex align="center" justify={remainingDays >= 30 ? 'center' : 'space-between'}>
        <Text
          fz="sm"
          fw="500"
          w={remainingDays >= 30 ? '100%' : '60%'}
          sx={{ textAlign: 'center' }}
        >
          {t('filials.diskSpace.plan')} «{capitalizeFirstLetter(name)}»
        </Text>
        {remainingDays < 30 && (
          <>
            <Divider orientation="vertical" size="xs" className={classes.divider} />
            <Flex align="center" direction="column" gap="8px" w="40%">
              <Text
                fz="10px"
                fw="500"
                c="#848E98"
                sx={{ lineHeight: '10px', transform: 'uppercase' }}
              >
                {t('filials.diskSpace.remainingDays')}
              </Text>
              <Text fz="56px" fw="500" className={classes.dateBox}>
                {remainingDays}
              </Text>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};
