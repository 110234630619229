import { base64ToBlob } from '@/lib/files';
import { IFileAccessModifier, IUploadedFileResponse } from '@/entities/admin-app/commonSettings';
import { uploadFile } from '@/api/files';
import { showNotification } from '@mantine/notifications';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useImageUpload = () => {
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadLogoFile = useCallback(
    async (image: string): Promise<IUploadedFileResponse | undefined> => {
      try {
        setIsUploading(true);
        const file = await base64ToBlob(image);
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('FileAccessModifier', IFileAccessModifier.Public.toString());
          const response = await uploadFile(formData);
          if (response && response.data) {
            setIsUploading(false);
            return response.data as IUploadedFileResponse;
          }
        } else {
          setIsUploading(false);
        }
      } catch (error) {
        showNotification({
          title: t('requestsErrors.unknownError'),
          message: '',
          autoClose: true,
          color: 'red'
        });
        setIsUploading(false);
        console.error(error);
      }
    },
    []
  );
  return { uploadLogoFile, isUploading };
};
