import { FC } from 'react';
import { useGetNavMenuItems } from './useGetNavMenuItems';
import { SidebarMenu } from '@/components/SidebarMenu';
import { useTranslation } from 'react-i18next';
import { getStatisticsRoutes } from '../utils';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { statisticsMenuItems } = useGetNavMenuItems();

  const linksBlocks = [
    {
      label: t('navigation.statistics'),
      pages: statisticsMenuItems
    }
  ];

  return (
    <SidebarMenu routes={getStatisticsRoutes()} setIsOpen={setIsOpen} linksBlocks={linksBlocks} />
  );
};
