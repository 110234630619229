import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { Type } from '../components/Table/type';
import { ItemTitle as ItemTitle } from '../components/Table/item-title';
import { Action } from '../components/Table/action';
import { formatDateTime } from '@/lib/utils/date';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import OverflowTip from '@components/OverflowTip';
export const useGetColumns = () => {
  const { t } = useTranslation();
  const columnsData: IColumnData[] = [
    {
      width: '200px',
      dataIndex: 'Id',
      title: t('servers.name'),
      hideColumn: false,
      render: (item: IRowData) => <ItemTitle item={item} />
    },
    {
      width: '100px',
      dataIndex: 'Type',
      title: t('roles.roleType'),
      hideColumn: false,
      render: (item: IRowData) => <Type item={item} />
    },
    {
      width: '146px',
      dataIndex: 'owner',
      title: t('owner'),
      hideColumn: false,
      render: (item: IRowData) => (
        <Box maw="146px">
          <OverflowTip>{item?.Owner?.Name}</OverflowTip>
        </Box>
      )
    },
    {
      width: '116px',
      dataIndex: 'Counter',
      title: t('participants'),
      hideColumn: false,
      render: (item: IRowData) => item?.Counter
    },
    {
      width: '116px',
      dataIndex: 'filial',
      title: t('unit'),
      hideColumn: false,
      render: (item: IRowData) => (
        <Box maw="116px">
          <OverflowTip>{item?.Customer?.Name}</OverflowTip>
        </Box>
      )
    },
    {
      width: '146px',
      dataIndex: 'parentRole',
      title: t('roles.parentalRole'),
      hideColumn: false,
      render: (item: IRowData) => (
        <Box maw="146px">
          <OverflowTip>{item?.Parent?.Name}</OverflowTip>
        </Box>
      )
    },
    {
      width: '126px',
      dataIndex: 'dateOfCreation',
      title: t('dateOfCreation'),
      hideColumn: false,
      render: (item: IRowData) => formatDateTime(item?.CreateOfDate)
    },
    {
      width: '80px',
      dataIndex: 'Action',
      title: '',
      hideColumn: false,
      render: (item: IRowData) => <Action item={item} />
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
