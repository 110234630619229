import { Menu, ActionIcon, Box } from '@mantine/core';
import { IconGridDots } from '@tabler/icons';
import { useAppSelector } from '@/hooks/redux/redux';

import { getMenu } from './utils';
import { ModuleSettings } from '@/entities/admin-app/auth';
import { useStyles } from './styles';

import { NoImage } from '@/assets/icons';
import { themeColors } from '@/theme/colors';

export const AppsMenu = () => {
  const { classes } = useStyles();

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings
  ) as ModuleSettings;
  const menu = modules ? getMenu(modules) : [];

  return menu.length > 0 ? (
    <Menu
      position="bottom-end"
      shadow="sm"
      zIndex={1001}
      radius={8}
      classNames={{
        dropdown: classes.dropdown,
        item: classes.menuItem,
        itemLabel: classes.itemLabel,
        itemSection: classes.itemIcon
      }}
    >
      <Menu.Target>
        <ActionIcon size={26}>
          <IconGridDots size={26} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown w={260}>
        {menu.map(
          (item, index) =>
            item.url &&
            item.label && (
              <Menu.Item
                key={index}
                component="a"
                href={item.url}
                fz={16}
                px="sm"
                my="4px"
                target="_blank"
                leftSection={
                  item.iconSrc ? (
                    <Box component="img" src={item.iconSrc} height={24} />
                  ) : (
                    <NoImage stroke={themeColors.brandGray[0]} />
                  )
                }
              >
                {item.label}
              </Menu.Item>
            )
        )}
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
