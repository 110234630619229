import { MantineTheme } from '@mantine/core';
import { createStyles, CSSObject } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  header: {
    margin: 0,
    padding: '24px 24px 16px',
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#F9FAFB'
  },
  title: {
    fontSize: theme.fontSizes.md
  },
  close: {
    svg: {
      width: 24,
      height: 24
    }
  },
  body: {
    padding: 0
  }
}));

export const modalTheme = (theme: MantineTheme): Record<string, CSSObject> => {
  return {
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      padding: `${theme.spacing?.sm} ${theme.spacing?.md}`
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: '#F9FAFB',
      height: '63px',
      padding: `0px ${theme.spacing?.md}`,
      borderTop: '1px solid #F2F4F7'
    },
    cancelBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '36px',
      borderRadius: '8px',
      padding: `8px ${theme.spacing?.sm}`,
      backgroundColor: theme.colors?.brandGray ? theme.colors?.brandGray[1] : '',
      fontSize: '14px',
      color: '#101828',
      fontWeight: 600,
      lineHeight: '17px',

      '&:hover': {
        backgroundColor: '#eff0f4'
      },

      '&:active': {
        transform: `translateY(1px)`
      }
    }
  };
};
