import { Table, Text } from '@mantine/core';
import { IColumnData } from '@components/simple-table/types';

interface ITableHeadProps {
  columns: IColumnData[];
}

export const TableHeadList = ({ columns }: ITableHeadProps) => {
  return (
    <Table.Tr>
      {columns.map(({ dataIndex, title, width }) => {
        return (
          <Table.Th
            key={dataIndex + title}
            style={{
              width,
              minWidth: width
            }}
          >
            <Text size="sm" c="gray.6">
              {title}
            </Text>
          </Table.Th>
        );
      })}
    </Table.Tr>
  );
};
