import { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IPosition } from '@/entities/admin-app/user';
import { IRowData } from '@components/simple-table/types';

interface IParentUserNames {
  item: IRowData;
}

export const ParentUserNames: FC<IParentUserNames> = ({ item }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.content}>
      {item?.some((position: IPosition) => position?.ParentUserName)
        ? item.slice(0, 3).map((position: IPosition, index: number) => (
            <Box key={index} maw="200px">
              <Text size="xs" fw={400} className={classes.text}>
                <span>{position?.ParentUserName}</span>
              </Text>
            </Box>
          ))
        : null}
    </Box>
  );
};
