import React, { useState, useEffect } from 'react';

import { SearchIcon } from '@assets/icons';
import { Search } from '@components/search/search-input';

import { useDebounce } from '@hooks/useDebounce';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';

export const UsersSearch = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setUsersTableFilters } = useActions();
  const { filters: usersFilter } = useAppSelector((state) => state.users);

  const [text, setText] = useState(usersFilter.Query || '');

  const debounced = useDebounce(text, 500);

  useEffect(() => {
    setUsersTableFilters({
      ...usersFilter,
      Offset: 0,
      Query: debounced
    });
  }, [debounced]);

  return (
    <Search
      data-cy="searchUserInput"
      icon={<SearchIcon />}
      setSearch={setText}
      className={classes.search}
      fullSize
      value={text}
      placeholder={t('users.search')}
    />
  );
};
