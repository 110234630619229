import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${theme.spacing.sm} ${theme.spacing.sm} 0px ${theme.spacing.md}`,
    borderRight: '1px solid #F2F4F7'
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 600,
    width: '100%'
  },

  control: {
    width: '100%',
    marginTop: theme.spacing.sm,
    fontSize: '12px',
    fontWeight: 600
  },

  search: {
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.md,
    '& input': {
      border: `1px solid ${theme.colors.brandGray[1]}`
    }
  },

  selectAll: {
    // color: theme.colors.mainBlue[9],
    fontSize: '14px',
    marginBottom: theme.spacing.sm,
    cursor: 'pointer'
  }
}));
