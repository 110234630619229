import { FC, SyntheticEvent, useCallback } from 'react';
import { ActionIcon } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { ActionQuotaMenuEnum } from './utils';
import { IFilialQuota } from '@/entities/admin-app/quotas';
import { PopoverMenu } from '@components/popover-menu';
import { useAppSelector } from '@hooks/redux/redux';
import { ModuleUrl } from '@/entities/admin-app/auth';
import { useTranslation } from 'react-i18next';
import { IListItem } from '@/types';

interface IActionProps {
  quota?: IFilialQuota;
  openFilialQuotaModal: () => void;
  isSaaS: boolean;
}

export const FilialQuotaAction: FC<IActionProps> = ({ quota, openFilialQuotaModal, isSaaS }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings?.ModuleUrls
  ) as ModuleUrl[];

  const paymentModule = modules.find((module) => module.Name === 'Payments');
  const PaymentLink = paymentModule?.Url || '/';

  if (!quota) {
    return null;
  }

  const [opened, { close, open }] = useDisclosure(false);
  const onClickItem = useCallback(
    async (event: SyntheticEvent<HTMLDivElement>) => {
      close();

      event.stopPropagation();
      const { value } = event.currentTarget.dataset;

      switch (value) {
        case ActionQuotaMenuEnum.edit:
          openFilialQuotaModal();
          break;
        case ActionQuotaMenuEnum.changeTariff:
          if (PaymentLink) {
            window.location.href = PaymentLink;
          }
          break;
        default:
          break;
      }
    },
    [quota]
  );

  const handleContextMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    close();
  };

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.nativeEvent.stopPropagation();
    open();
  };

  const listMenu: IListItem[] = isSaaS
    ? [
        {
          label: t('filials.diskSpace.changeTariff'),
          value: ActionQuotaMenuEnum.changeTariff
        }
      ]
    : [
        {
          label: t('filials.diskSpace.edit'),
          value: ActionQuotaMenuEnum.edit
        }
      ];

  return (
    <PopoverMenu
      opened={opened}
      onClickItem={onClickItem}
      width={292}
      list={listMenu}
      position={isMobile ? 'bottom-start' : 'left-start'}
      onClose={close}
    >
      <ActionIcon className="action-icon" onClick={handleClick} onContextMenu={handleContextMenu}>
        <IconDotsVertical color="#667085" data-action="action" />
      </ActionIcon>
    </PopoverMenu>
  );
};
