import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@/components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  homeContainer: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#f6f7f9',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    overflow: 'scroll',
    padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`
  },

  flexContainerDefault: {
    flexWrap: 'wrap',
    [utils.largerThan('xl')]: {
      maxHeight: '500px',
      maxWidth: '1384px'
    },
    [utils.smallerThan('xl')]: {
      maxHeight: '530px'
    },
    [utils.smallerThan('lg')]: {
      maxHeight: '680px'
    },
    [utils.smallerThan('md')]: {
      maxHeight: '850px'
    },
    [utils.smallerThan('xs')]: {
      flexWrap: 'nowrap',
      maxHeight: 'none'
    }
  },

  flexContainerSaaS: {
    flexWrap: 'wrap',
    [utils.largerThan('xl')]: {
      maxHeight: '500px',
      maxWidth: '1384px'
    },
    [utils.smallerThan('xl')]: {
      maxHeight: '560px'
    },
    [utils.smallerThan('lg')]: {
      maxHeight: '750px'
    },
    [utils.smallerThan('md')]: {
      maxHeight: '1000px'
    },
    [utils.smallerThan('xs')]: {
      flexWrap: 'nowrap',
      maxHeight: 'none'
    }
  }
}));
