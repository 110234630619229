import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  badge: {
    fontSize: '14px',
    fontWeight: 400,
    border: 'none',
    borderRadius: '16px',
    backgroundColor: theme.colors.backgroundGray[5],
    marginTop: theme.spacing.xs,
    padding: '4px 12px',
    cursor: 'pointer',
    maxWidth: '290px',
    width: 'fit-content',
    alignItems: 'center'
  }
}));
