import { z } from 'zod';
import { limitsLengthFilialFields } from '@/containers/pages/filials/utils';
import { TFunction } from 'i18next';
export const settingsPageValidateScheme = (t: TFunction) =>
  z.object({
    Title: z
      .string()
      .max(limitsLengthFilialFields.Title, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Title
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('validation.enterFilialName')
      }),
    Company: z
      .string()
      .max(limitsLengthFilialFields.Company, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Company
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('quota.errors.required')
      })
      .or(z.literal('')),
    Parent: z
      .object({
        Id: z.number({ invalid_type_error: t('filials.settings.chooseParent') })
      })
      .nullable(),
    IsParentSettings: z.boolean()
  });

export const createFilialSchema = (t: TFunction) =>
  z.object({
    Company: z
      .string()
      .max(limitsLengthFilialFields.Company, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Company
        })
      })
      .nullable(),
    Title: z
      .string()
      .min(1, { message: t('filials.isRequiredField') })
      .max(limitsLengthFilialFields.Title, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Title
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      }),
    Parent: z.object({
      Id: z.number({ invalid_type_error: t('filials.isRequiredField') })
    })
  });
