import { showNotification } from '@mantine/notifications';
import { useLazyVerifyEmailServersQuery } from '@/entities/admin-app/email/api';
import { useTranslation } from 'react-i18next';

export const VerifyEmailAndOpenModal = () => {
  const { t } = useTranslation();
  const [getVerifiedList] = useLazyVerifyEmailServersQuery();
  const handleCheckCustomerServerSettings = async (
    CustomerId: number,
    handleOpenModal: () => void
  ) => {
    try {
      if (CustomerId) {
        const { isSuccess, data } = await getVerifiedList([CustomerId]);
        if (isSuccess) {
          const currentCustomerSettings = data?.Customers.find(
            (customer) => customer.CustomerId === CustomerId
          );
          if (currentCustomerSettings && currentCustomerSettings.HasSettings && handleOpenModal) {
            handleOpenModal();
          } else {
            showNotification({
              title: t('requestsErrors.notConfiguredCustomersEmail'),
              message: '',
              autoClose: true,
              color: 'orange'
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handleCheckCustomerServerSettings };
};
