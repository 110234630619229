import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole, IDepartment } from './types';

interface IDirectories {
  roles: IRole[];
  departments: IDepartment[];
}

const initialState: IDirectories = {
  roles: [],
  departments: []
};

export const directoriesSlice = createSlice({
  name: 'directories',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<IRole[]>) {
      state.roles = action.payload;
    },
    setDepartments(state, action: PayloadAction<IDepartment[]>) {
      state.departments = action.payload;
    }
  }
});

export const directoriesActions = directoriesSlice.actions;
export const directoriesReducer = directoriesSlice.reducer;
