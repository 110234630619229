import React, { useState, FC } from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/modal';
import { ImageUploadModal } from '@/containers/pages/commonSettings/components/image-upload';
import { IUploadedFileResponse } from '@/entities/admin-app/commonSettings';

interface ILogoField {
  dimensions: { width: number; height: number };
  allowedMimeType: string[];
  logoUrl: string;
  handleUpdateLogo: (data: IUploadedFileResponse) => void;
}
const LogoField: FC<ILogoField> = ({ dimensions, handleUpdateLogo, logoUrl, allowedMimeType }) => {
  const { classes } = useStyles({ dimensions });
  const { t } = useTranslation();
  const [openUploadLogo, setOpenUploadLogo] = useState<boolean>(false);

  const handleCloseModalEditAvatar = () => {
    setOpenUploadLogo(false);
  };

  return (
    <Box mt={32}>
      <Text className={classes.description}>{t('commonSettings.mainLogo')}</Text>
      <Flex className={classes.imageContainer}>
        <img
          src={logoUrl}
          style={{
            width: 'auto',
            height: dimensions.height,
            maxWidth: dimensions.width,
            aspectRatio: 'auto'
          }}
          alt="Main page logo"
          loading="lazy"
        />
        <Flex className={classes.imageOverlay} onClick={() => setOpenUploadLogo(true)}>
          <Text color="white">{t('avatar.uploadPhoto')}</Text>
        </Flex>
      </Flex>
      <Modal
        size="450px"
        opened={openUploadLogo}
        title={t('commonSettings.mainLogo')}
        onClose={handleCloseModalEditAvatar}
      >
        <ImageUploadModal
          onClose={handleCloseModalEditAvatar}
          handleUpdateLogo={handleUpdateLogo}
          dimensions={dimensions}
          allowedMimeType={allowedMimeType}
        />
      </Modal>
    </Box>
  );
};

export default React.memo(LogoField);
